<template>
  <iui-content-box>
    <iui-container type="css-flex" flex-direction="row">
      <!--<i-col>
        <i-row height="170px">
          <iui-content-box :loading="neLoading">
            <div class="dashbaord_title">NE</div>

            <iui-container type="table" class="dashboard">
              <i-row class="header">
                <i-col-header align="center">구분</i-col-header>
                <i-col-header class="header1" align="center">송부대상</i-col-header>
                <i-col-header class="header2" align="center">송부</i-col-header>
                <i-col-header class="header1" align="center">서명</i-col-header>
                <i-col-header class="header2" align="center">반송</i-col-header>
                <i-col-header class="header1" align="center">회수</i-col-header>
              </i-row>
              <i-row>
                <i-col-header>견적</i-col-header>
                <i-col align="center">{{ neEtSummary.ready }}</i-col>
                <i-col align="center">{{ neEtSummary.send }}</i-col>
                <i-col align="center">-</i-col>
                <i-col align="center">-</i-col>
                <i-col align="center">-</i-col>
              </i-row>
              <i-row>
                <i-col-header>계약</i-col-header>
                <i-col align="center">{{ neCtSummary.ready }}</i-col>
                <i-col align="center">{{ neCtSummary.send }}</i-col>
                <i-col align="center">{{ neCtSummary.sign }}</i-col>
                <i-col align="center">{{ neCtSummary.reject }}</i-col>
                <i-col align="center">{{ neCtSummary.recovery }}</i-col>
              </i-row>
              <i-row>
                <i-col-header>금액확정통보</i-col-header>
                <i-col align="center">{{ neCfSummary.ready }}</i-col>
                <i-col align="center">{{ neCfSummary.send }}</i-col>
                <i-col align="center">{{ neCfSummary.sign }}</i-col>
                <i-col align="center">{{ neCfSummary.reject }}</i-col>
                <i-col align="center">{{ neCfSummary.recovery }}</i-col>
              </i-row>
            </iui-container>
          </iui-content-box>
        </i-row>
        <i-spacer />
        <i-row height="170px">
          <iui-content-box :loading="modLoading">
            <div class="dashbaord_title">MOD</div>

            <iui-container type="table" class="dashboard">
              <i-row class="header">
                <i-col-header align="center">구분</i-col-header>
                <i-col-header class="header1" align="center">송부대상</i-col-header>
                <i-col-header class="header2" align="center">송부</i-col-header>
                <i-col-header class="header1" align="center">서명</i-col-header>
                <i-col-header class="header2" align="center">반송</i-col-header>
                <i-col-header class="header1" align="center">회수</i-col-header>
              </i-row>
              <i-row>
                <i-col-header>견적</i-col-header>
                <i-col align="center">{{ modEtSummary.ready }}</i-col>
                <i-col align="center">{{ modEtSummary.send }}</i-col>
                <i-col align="center">-</i-col>
                <i-col align="center">-</i-col>
                <i-col align="center">-</i-col>
              </i-row>
              <i-row>
                <i-col-header>계약</i-col-header>
                <i-col align="center">{{ modCtSummary.ready }}</i-col>
                <i-col align="center">{{ modCtSummary.send }}</i-col>
                <i-col align="center">{{ modCtSummary.sign }}</i-col>
                <i-col align="center">{{ modCtSummary.reject }}</i-col>
                <i-col align="center">{{ modCtSummary.recovery }}</i-col>
              </i-row>
              <i-row>
                <i-col-header>금액확정통보</i-col-header>
                <i-col align="center">{{ modCfSummary.ready }}</i-col>
                <i-col align="center">{{ modCfSummary.send }}</i-col>
                <i-col align="center">{{ modCfSummary.sign }}</i-col>
                <i-col align="center">{{ modCfSummary.reject }}</i-col>
                <i-col align="center">{{ modCfSummary.recovery }}</i-col>
              </i-row>
            </iui-container>
          </iui-content-box>
        </i-row>
        <i-spacer />
        <i-row>
          <iui-content-box :loading="fodLoading">
            <div class="dashbaord_title">FOD</div>

            <iui-container type="table" class="dashboard">
              <i-row class="header">
                <i-col-header align="center">구분</i-col-header>
                <i-col-header class="header1" align="center">송부대상</i-col-header>
                <i-col-header class="header2" align="center">송부</i-col-header>
                <i-col-header class="header1" align="center">서명</i-col-header>
                <i-col-header class="header2" align="center">반송</i-col-header>
                <i-col-header class="header1" align="center">회수</i-col-header>
              </i-row>
              <i-row>
                <i-col-header>구성원 변경</i-col-header>
                <i-col align="center">{{ fodCt1Summary.ready }}</i-col>
                <i-col align="center">{{ fodCt1Summary.send }}</i-col>
                <i-col align="center">{{ fodCt1Summary.sign }}</i-col>
                <i-col align="center">{{ fodCt1Summary.reject }}</i-col>
                <i-col align="center">{{ fodCt1Summary.recovery }}</i-col>
              </i-row>
              <i-row>
                <i-col-header>금액 변경</i-col-header>
                <i-col align="center">{{ fodCt2Summary.ready }}</i-col>
                <i-col align="center">{{ fodCt2Summary.send }}</i-col>
                <i-col align="center">-</i-col>
                <i-col align="center">{{ fodCt2Summary.reject }}</i-col>
                <i-col align="center">{{ fodCt2Summary.recovery }}</i-col>
              </i-row>
              <i-row>
                <i-col-header>돌관</i-col-header>
                <i-col align="center">{{ fodCt3Summary.ready }}</i-col>
                <i-col align="center">{{ fodCt3Summary.send }}</i-col>
                <i-col align="center">-</i-col>
                <i-col align="center">{{ fodCt3Summary.reject }}</i-col>
                <i-col align="center">{{ fodCt3Summary.recovery }}</i-col>
              </i-row>
              <i-row>
                <i-col-header>비계</i-col-header>
                <i-col align="center">{{ fodCt4Summary.ready }}</i-col>
                <i-col align="center">{{ fodCt4Summary.send }}</i-col>
                <i-col align="center">-</i-col>
                <i-col align="center">{{ fodCt4Summary.reject }}</i-col>
                <i-col align="center">{{ fodCt4Summary.recovery }}</i-col>
              </i-row>
              <i-row>
                <i-col-header>양중</i-col-header>
                <i-col align="center">{{ fodCt5Summary.ready }}</i-col>
                <i-col align="center">{{ fodCt5Summary.send }}</i-col>
                <i-col align="center">-</i-col>
                <i-col align="center">{{ fodCt5Summary.reject }}</i-col>
                <i-col align="center">{{ fodCt5Summary.recovery }}</i-col>
              </i-row>
              <i-row>
                <i-col-header>외주</i-col-header>
                <i-col align="center">{{ fodCt6Summary.ready }}</i-col>
                <i-col align="center">{{ fodCt6Summary.send }}</i-col>
                <i-col align="center">-</i-col>
                <i-col align="center">{{ fodCt6Summary.reject }}</i-col>
                <i-col align="center">{{ fodCt6Summary.recovery }}</i-col>
              </i-row>
              <i-row>
                <i-col-header>정산합의</i-col-header>
                <i-col align="center">{{ fodPySummary.ready }}</i-col>
                <i-col align="center">{{ fodPySummary.send }}</i-col>
                <i-col align="center">-</i-col>
                <i-col align="center">{{ fodPySummary.reject }}</i-col>
                <i-col align="center">{{ fodPySummary.recovery }}</i-col>
              </i-row>
              <i-row>
                <i-col-header>인수증</i-col-header>
                <i-col align="center">{{ fodRcSummary.ready }}</i-col>
                <i-col align="center">{{ fodRcSummary.send }}</i-col>
                <i-col align="center">-</i-col>
                <i-col align="center">{{ fodRcSummary.reject }}</i-col>
                <i-col align="center">{{ fodRcSummary.recovery }}</i-col>
              </i-row>
            </iui-container>
          </iui-content-box>
        </i-row>
      </i-col>
      <i-spacer />
      -->
      <i-col>
        <i-row height="170px">
          <iui-content-box :loading="contentBoxLoading">
            <div style="display: flex; justify-content: space-between;">
              <div class="dashbaord_title">SVC 점검계약</div>
              <div style="display: inline-flex; margin-top: 15px;">
                <iui-datepicker-old
                  name="yyyyMm"
                  type="month"
                  :value="searchParam.yyyyMm"
                  :enable="true"
                  @change="onSetValue($event, 'yyyyMm')"
                  required
                />
                <iui-button @click="onSearch">조회</iui-button>
              </div>
            </div>

            <iui-container type="table" class="dashboard" style="height: 121px;">
              <i-row class="header">
                <i-col-header align="center">구분</i-col-header>
                <i-col-header class="header1" align="center">송부합계</i-col-header>
                <i-col-header class="header2" align="center">송부</i-col-header>
                <i-col-header class="header1" align="center">구성원<br />서명중</i-col-header>
                <i-col-header class="header2" align="center">체결완료</i-col-header>
                <i-col-header class="header1" align="center">구성원<br />반송</i-col-header>
                <i-col-header class="header2" align="center">고객<br />반송</i-col-header>
                <i-col-header class="header1" align="center">회수</i-col-header>
                <i-col-header class="header2" align="center">전월까지<br />누적 미진행</i-col-header>
              </i-row>
              <i-row>
                <i-col-header>계약서</i-col-header>
                <i-col align="center">{{ summary.oContractSumCnt }}</i-col>
                <i-col align="center"
                  ><span class="count" @click="goMenu_oContract('progress', '10')">{{
                    summary.oContractSendCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_oContract('progress', '20')">{{
                    summary.oContractSingingCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_oContract('complete', '')">{{
                    summary.oContractCompleteCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_oContract('progress', '11')">{{
                    summary.oContractRejectCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_oContract('progress', '14')">{{
                    summary.oContractRejectCustCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_oContract('progress', '12')">{{
                    summary.oContractRecoveryCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_oContract('progress', '')">{{
                    summary.oContractPrevSumCnt
                  }}</span></i-col
                >
              </i-row>
              <i-row>
                <i-col-header>자동 연장</i-col-header>
                <i-col colspan="8" align="center">
                  <ul id="contents_dash_t" class="type">
                    <li class="head">공문 응답 유형</li>
                    <li @click="goMenu_oContract_officialDocument('20')" style="cursor:pointer; margin-left:2px;">
                      동의 <span>{{ summary.officialDocumentAgreeCnt }}</span>
                    </li>
                    <li @click="goMenu_oContract_officialDocument('30')" style="cursor:pointer; margin-left:2px;">
                      미동의 <span>{{ summary.officialDocumentNotAgreeCnt }}</span>
                    </li>
                    <li @click="goMenu_oContract_officialDocument('10')" style="cursor:pointer; margin-left:2px;">
                      응답없음 <span>{{ summary.officialDocumentNotAckCnt }}</span>
                    </li>
                    <li @click="goMenu_oContract_officialDocument('10', 'Y')" style="cursor:pointer; margin-left:2px;">
                      2차 발송 <span>{{ summary.officialDocumentSecondSendCnt }}</span>
                    </li>
                  </ul>
                </i-col>
              </i-row>
            </iui-container>
          </iui-content-box>
        </i-row>
        <i-spacer />
      
        <i-row height="170px">
          <iui-content-box :loading="contentBoxLoading">
            <div class="dashbaord_title">SVC 수리공사계약</div>

            <iui-container type="table" class="dashboard">
              <i-row class="header">
                <i-col-header align="center">구분</i-col-header>
                <i-col-header class="header1" align="center">송부합계</i-col-header>
                <i-col-header class="header2" align="center">송부</i-col-header>
                <i-col-header class="header1" align="center">구성원<br />서명중</i-col-header>
                <i-col-header class="header2" align="center">체결완료</i-col-header>
                <i-col-header class="header1" align="center">구성원<br />반송</i-col-header>
                <i-col-header class="header2" align="center">고객<br />반송</i-col-header>
                <i-col-header class="header1" align="center">회수</i-col-header>
                <i-col-header class="header2" align="center">전월까지<br />누적 미진행</i-col-header>
              </i-row>
              <i-row>
                <i-col-header>계약서</i-col-header>
                <i-col align="center">{{ summary.tContractSumCnt }}</i-col>
                <i-col align="center"
                  ><span class="count" @click="goMenu_tContract(false, 'progress', '10')">{{
                    summary.tContractSendCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_tContract(false, 'progress', '20')">{{
                    summary.tContractSingingCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_tContract(false, 'complete', '')">{{
                    summary.tContractCompleteCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_tContract(false, 'progress', '11')">{{
                    summary.tContractRejectCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_tContract(false, 'progress', '14')">{{
                    summary.tContractRejectCustCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_tContract(false, 'progress', '12')">{{
                    summary.tContractRecoveryCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_tContract(false, 'progress', '')">{{
                    summary.tContractPrevSumCnt
                  }}</span></i-col
                >
              </i-row>
              <i-row>
                <i-col-header>변경합의</i-col-header>
                <i-col align="center">{{ summary.tChangeContractSumCnt }}</i-col>
                <i-col align="center"
                  ><span class="count" @click="goMenu_tContract(true, 'progress', '10')">{{
                    summary.tChangeContractSendCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_tContract(true, 'progress', '20')">{{
                    summary.tChangeContractSingingCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_tContract(true, 'complete', '')">{{
                    summary.tChangeContractCompleteCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_tContract(true, 'progress', '11')">{{
                    summary.tChangeContractRejectCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_tContract(true, 'progress', '14')">{{
                    summary.tChangeContractRejectCustCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_tContract(true, 'progress', '12')">{{
                    summary.tChangeContractRecoveryCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_tContract(true, 'progress', '')">{{
                    summary.tChangeContractPrevSumCnt
                  }}</span></i-col
                >
              </i-row>
              <i-row>
                <i-col-header>견적서</i-col-header>
                <i-col align="center">{{ summary.estimateSumCnt }}</i-col>
                <i-col align="center"
                  ><span class="count" @click="goMenu_tEstimate('progress', '10')">{{
                    summary.estimateSendCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_tEstimate('progress', '20')">{{
                    summary.estimateSingingCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_tEstimate('complete', '')">{{
                    summary.estimateCompleteCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_tEstimate('progress', '11')">{{
                    summary.estimateRejectCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_tEstimate('progress', '14')">{{
                    summary.estimateRejectCustCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_tEstimate('progress', '12')">{{
                    summary.estimateRecoveryCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_tEstimate('progress', '')">{{
                    summary.estimatePrevSumCnt
                  }}</span></i-col
                >
              </i-row>
            </iui-container>
          </iui-content-box>
        </i-row>
        <i-spacer />
        <i-row valign="top">
          <iui-content-box :loading="contentBoxLoading">
            <div class="dashbaord_title">SVC 확인서</div>

            <iui-container type="table" class="dashboard" style="height: 246px;">
              <i-row class="header">
                <i-col-header align="center">구분</i-col-header>
                <i-col-header align="center">상세</i-col-header>
                <i-col-header class="header1" align="center">작성중</i-col-header>
                <i-col-header class="header2" align="center">회수</i-col-header>
                <i-col-header class="header1" align="center">서명요청</i-col-header>
                <i-col-header class="header2" align="center">완료</i-col-header>
              </i-row>
              <i-row>
                <i-col-header rowspan="3">부품</i-col-header>
                <i-col-header>무상</i-col-header>
                <i-col align="center"
                  ><span class="count" @click="goMenu_Parts('무상', '10')">{{
                    summary.selectPartFreeWriteCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_Parts('무상', '30')">{{
                    summary.selectPartFreeRecoveryCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_Parts('무상', '20')">{{
                    summary.selectPartFreeReqSignCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_Parts('무상', '50')">{{
                    summary.selectPartFreeCompleteCnt
                  }}</span></i-col
                >
              </i-row>
              <i-row>
                <i-col-header>유상</i-col-header>
                <i-col align="center"
                  ><span class="count" @click="goMenu_Parts('유상', '10')">{{
                    summary.selectPartPaidWriteCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_Parts('유상', '30')">{{
                    summary.selectPartPaidRecoveryCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_Parts('유상', '20')">{{
                    summary.selectPartPaidReqSignCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_Parts('유상', '50')">{{
                    summary.selectPartPaidCompleteCnt
                  }}</span></i-col
                >
              </i-row>
              <i-row>
                <i-col-header>클래임</i-col-header>
                <i-col align="center"
                  ><span class="count" @click="goMenu_Parts('CLAIM', '10')">{{
                    summary.selectPartClaimWriteCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_Parts('CLAIM', '30')">{{
                    summary.selectPartClaimRecoveryCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_Parts('CLAIM', '20')">{{
                    summary.selectPartClaimReqSignCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_Parts('CLAIM', '50')">{{
                    summary.selectPartClaimCompleteCnt
                  }}</span></i-col
                >
              </i-row>
              <i-row>
                <i-col-header rowspan="3">공사</i-col-header>
                <i-col-header>무상</i-col-header>
                <i-col align="center"
                  ><span class="count" @click="goMenu_ConstCmpl('무상', '10')">{{
                    summary.selectConstCmplFreeWriteCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_ConstCmpl('무상', '10')">{{
                    summary.selectConstCmplFreeRecoveryCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_ConstCmpl('무상', '10')">{{
                    summary.selectConstCmplFreeReqSignCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_ConstCmpl('무상', '10')">{{
                    summary.selectConstCmplFreeCompleteCnt
                  }}</span></i-col
                >
              </i-row>
              <i-row>
                <i-col-header>유상</i-col-header>
                <i-col align="center"
                  ><span class="count" @click="goMenu_ConstCmpl('유상', '10')">{{
                    summary.selectConstCmplPaidWriteCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_ConstCmpl('유상', '10')">{{
                    summary.selectConstCmplPaidRecoveryCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_ConstCmpl('유상', '10')">{{
                    summary.selectConstCmplPaidReqSignCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_ConstCmpl('유상', '10')">{{
                    summary.selectConstCmplPaidCompleteCnt
                  }}</span></i-col
                >
              </i-row>
              <i-row>
                <i-col-header>클래임</i-col-header>
                <i-col align="center"
                  ><span class="count" @click="goMenu_ConstCmpl('CLAIM', '10')">{{
                    summary.selectConstCmplPaidWriteCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_ConstCmpl('CLAIM', '10')">{{
                    summary.selectConstCmplPaidRecoveryCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_ConstCmpl('CLAIM', '10')">{{
                    summary.selectConstCmplPaidReqSignCnt
                  }}</span></i-col
                >
                <i-col align="center"
                  ><span class="count" @click="goMenu_ConstCmpl('CLAIM', '10')">{{
                    summary.selectConstCmplPaidCompleteCnt
                  }}</span></i-col
                >
              </i-row>
            </iui-container>
          </iui-content-box>
        </i-row>
      </i-col>
    </iui-container>

    <iui-container type="css-flex" theme="bullet">
      <i-row>
        <i-col>
          <div id="contents_dash_t">
            <div class="bottom_group">
              <div class="b_group_r" style="width: 100% !important;">
                <a
                  href="javascript://"
                  class="notice_tit on"
                  style="height: 34px;"
                  id="boardTab1"
                  @click="boardTabChange(1)"
                  >공지사항</a
                ><a
                  href="javascript://"
                  class="notice_tit"
                  style="height: 34px;"
                  id="boardTab2"
                  @click="boardTabChange(2)"
                  >자료실</a
                >
                <div id="boardTabContent1">
                  <ul class="notice_list">
                    <li v-if="notiBoardList.length == 0"><a href="#">공지사항에 등록된 데이터가 없습니다.</a></li>
                    <li v-else v-for="(item, index) in notiBoardList" :key="index">
                      <a @click="onBoardView('NOTI', item.natNo)" style="cursor:pointer">{{ item.tl }}</a
                      ><span>{{ item.rgsDttm }}</span>
                    </li>
                  </ul>
                </div>
                <div id="boardTabContent2" style="display:none;">
                  <ul class="notice_list">
                    <li v-if="fileBoardList.length == 0"><a href="#">자료실에 등록된 데이터가 없습니다.</a></li>
                    <li v-else v-for="(item, index) in fileBoardList" :key="index">
                      <a @click="onBoardView('NOTI', item.natNo)" style="cursor:pointer">{{ item.tl }}</a
                      ><span>{{ item.rgsDttm }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- b_group_r end -->
            </div>
            <!-- bottom_group end -->
          </div>
        </i-col>
      </i-row>
      <!-- contents_dash_t end -->

      <iui-modal-old :name="boardViewModalId" :title="'게시판 조회'" :btns="modalBtns1" width="800px" height="600px">
        <BoardView :natDs="board.natDs" :natNo="board.natNo" />
      </iui-modal-old>
    </iui-container>
  </iui-content-box>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import BoardView from '../common/board/components/BoardViewModal.vue';

const MAX_URLS_LENGTH = 10;

export default {
  components: {
    BoardView,
  },
  data() {
    return {
      listBoxLoading: false,

      menus: [],

      searchParam: {
        yyyyMm: '',
        otisPage: false,
        customerPage: false,
      },

      boardTabIndex: 1,

      summary: {
        oContractSumCnt: 0,
        oContractSendCnt: 0,
        oContractSingingCnt: 0,
        oContractCompleteCnt: 0,
        oContractRejectCnt: 0,
        oContractRejectCustCnt: 0,
        oContractRecoveryCnt: 0,
        oContractPrevSumCnt: 0,
        officialDocumentSumCnt: 0,
        officialDocumentAgreeCnt: 0,
        officialDocumentNotAgreeCnt: 0,
        officialDocumentNotAckCnt: 0,
        officialDocumentSecondSendCnt: 0,
        tContractSumCnt: 0,
        tContractSendCnt: 0,
        tContractSingingCnt: 0,
        tContractCompleteCnt: 0,
        tContractRejectCnt: 0,
        tContractRejectCustCnt: 0,
        tContractRecoveryCnt: 0,
        tContractPrevSumCnt: 0,
        tChangeContractSumCnt: 0,
        tChangeContractSendCnt: 0,
        tChangeContractSingingCnt: 0,
        tChangeContractCompleteCnt: 0,
        tChangeContractRejectCnt: 0,
        tChangeContractRejectCustCnt: 0,
        tChangeContractRecoveryCnt: 0,
        tChangeContractPrevSumCnt: 0,
        estimateSumCnt: 0,
        estimateSendCnt: 0,
        estimateSingingCnt: 0,
        estimateCompleteCnt: 0,
        estimateRejectCnt: 0,
        estimateRejectCustCnt: 0,
        estimateRecoveryCnt: 0,
        estimatePrevSumCnt: 0,
        selectCmsSumCnt: 0,
        selectCmsNewCnt: 0,
        selectCmsChangeCnt: 0,
        selectViewSumCnt: 0,
        selectViewNewCnt: 0,
        selectViewChangeCnt: 0,
        selectEliteSumCnt: 0,
        selectEliteNewCnt: 0,
        selectEliteChangeCnt: 0,
        selectPartFreeWriteCnt: 0,
        selectPartFreeReqSignCnt: 0,
        selectPartFreeRecoveryCnt: 0,
        selectPartFreeSignedCnt: 0,
        selectPartFreeCompleteCnt: 0,
        selectPartPaidWriteCnt: 0,
        selectPartPaidReqSignCnt: 0,
        selectPartPaidRecoveryCnt: 0,
        selectPartPaidSignedCnt: 0,
        selectPartPaidCompleteCnt: 0,
        selectPartClaimWriteCnt: 0,
        selectPartClaimReqSignCnt: 0,
        selectPartClaimRecoveryCnt: 0,
        selectPartClaimSignedCnt: 0,
        selectPartClaimCompleteCnt: 0,
        selectConstCmplFreeWriteCnt: 0,
        selectConstCmplFreeReqSignCnt: 0,
        selectConstCmplFreeRecoveryCnt: 0,
        selectConstCmplFreeSignedCnt: 0,
        selectConstCmplFreeCompleteCnt: 0,
        selectConstCmplPaidWriteCnt: 0,
        selectConstCmplPaidReqSignCnt: 0,
        selectConstCmplPaidRecoveryCnt: 0,
        selectConstCmplPaidSignedCnt: 0,
        selectConstCmplPaidCompleteCnt: 0,

        ne: [],
        mod: [],
        fod: [],
      },

      boardViewModalId: 'boardViewModal' + this._uid,
      modalBtns1: [],
      board: {
        natDs: '',
        natNo: 0,
      },
      notiBoardList: [],
      fileBoardList: [],
      contentBoxLoading: false, //로딩바 추가

      neLoading: false,
      modLoading: false,
      fodLoading: false,
    };
  },
  computed: {
    ...mapGetters('menu', {
      Items: 'Items',
      selectedMenuNm: 'selectedMenuNm',
    }),
    ...mapGetters('mdi', {
      urls: 'urls',
    }),
    isOtisPage() {
      return this.userInfo.userType == 'U';
    },
    isCustomerPage() {
      return this.userInfo.userType != 'U';
    },
    neEtSummary() {
      const summary = this.summary.ne.find(item => item.divCd === 'ET');

      if (summary === undefined || summary.length === 0) {
        return {
          ready: 0,
          send: 0,
        };
      }

      return summary;
    },
    neCtSummary() {
      const summary = this.summary.ne.find(item => item.divCd === 'CT');

      if (summary === undefined || summary.length === 0) {
        return {
          ready: 0,
          send: 0,
          sign: 0,
          reject: 0,
          recovery: 0,
        };
      }

      return summary;
    },
    neCfSummary() {
      const summary = this.summary.ne.find(item => item.divCd === 'CF');

      if (summary === undefined || summary.length === 0) {
        return {
          ready: 0,
          send: 0,
          sign: 0,
          reject: 0,
          recovery: 0,
        };
      }

      return summary;
    },
    modEtSummary() {
      const summary = this.summary.mod.find(item => item.divCd === 'ET');

      if (summary === undefined || summary.length === 0) {
        return {
          ready: 0,
          send: 0,
        };
      }

      return summary;
    },
    modCtSummary() {
      const summary = this.summary.mod.find(item => item.divCd === 'CT');

      if (summary === undefined || summary.length === 0) {
        return {
          ready: 0,
          send: 0,
          sign: 0,
          reject: 0,
          recovery: 0,
        };
      }

      return summary;
    },
    modCfSummary() {
      const summary = this.summary.mod.find(item => item.divCd === 'CF');

      if (summary === undefined || summary.length === 0) {
        return {
          ready: 0,
          send: 0,
          sign: 0,
          reject: 0,
          recovery: 0,
        };
      }

      return summary;
    },
    fodCt1Summary() {
      const summary = this.summary.fod.find(item => item !== null && item.divCd === 'CT1');

      if (summary === undefined || summary.length === 0) {
        return {
          ready: 0,
          send: 0,
          sign: 0,
          reject: 0,
          recovery: 0,
        };
      }

      return summary;
    },
    fodCt2Summary() {
      const summary = this.summary.fod.find(item => item !== null && item.divCd === 'CT2');

      if (summary === undefined || summary.length === 0) {
        return {
          ready: 0,
          send: 0,
          sign: 0,
          reject: 0,
          recovery: 0,
        };
      }

      return summary;
    },
    fodCt3Summary() {
      const summary = this.summary.fod.find(item => item !== null && item.divCd === 'CT3');

      if (summary === undefined || summary.length === 0) {
        return {
          ready: 0,
          send: 0,
          sign: 0,
          reject: 0,
          recovery: 0,
        };
      }

      return summary;
    },
    fodCt4Summary() {
      const summary = this.summary.fod.find(item => item !== null && item.divCd === 'CT4');

      if (summary === undefined || summary.length === 0) {
        return {
          ready: 0,
          send: 0,
          sign: 0,
          reject: 0,
          recovery: 0,
        };
      }

      return summary;
    },
    fodCt5Summary() {
      const summary = this.summary.fod.find(item => item !== null && item.divCd === 'CT5');

      if (summary === undefined || summary.length === 0) {
        return {
          ready: 0,
          send: 0,
          sign: 0,
          reject: 0,
          recovery: 0,
        };
      }

      return summary;
    },
    fodCt6Summary() {
      const summary = this.summary.fod.find(item => item !== null && item.divCd === 'CT6');

      if (summary === undefined || summary.length === 0) {
        return {
          ready: 0,
          send: 0,
          sign: 0,
          reject: 0,
          recovery: 0,
        };
      }

      return summary;
    },
    fodPySummary() {
      const summary = this.summary.fod.find(item => item !== null &&  item.divCd === 'PY');

      if (summary === undefined || summary.length === 0) {
        return {
          ready: 0,
          send: 0,
          sign: 0,
          reject: 0,
          recovery: 0,
        };
      }

      return summary;
    },
    fodRcSummary() {
      const summary = this.summary.fod.find(item => item !== null &&  item.divCd === 'RC');

      if (summary === undefined || summary.length === 0) {
        return {
          ready: 0,
          send: 0,
          sign: 0,
          reject: 0,
          recovery: 0,
        };
      }

      return summary;
    },
  },
  created() {},
  async mounted() {
    const vm = this;
    this.menus = await this.getMenu();
    this.searchParam.yyyyMm = $_getCurrentDate().substring(0, 6);

    vm.neLoading = true;
    vm.modLoading = true;
    vm.fodLoading = true;

    /*
    this.getSummary('NE').then(() => {
      vm.neLoading = false;
    });
    this.getSummary('MOD').then(() => {
      vm.modLoading = false;
    });
    this.getSummary('FOD').then(() => {
      vm.fodLoading = false;
    });
    */
    this.selectSummaryCnt();
    this.onSearchNotiBoardList();
    this.onSearchFileBoardList();
  },
  methods: {
    ...mapActions('mdi', ['addMDI']),
    ...mapActions('layout', ['sideToggle']),
    ...mapActions('menu', ['onSelectItems']),
    async getMenu() {
      let param = {pgmCd: this.userInfo.pgmCd};

      let response = await axios
        .post('/scdUser/selectMenuWithAuthList', param)
        .catch(e => console.error('ERR!!! : ', e));
      var tdata = response.data;

      // isSelected 값 설정 + 루트메뉴 구하기
      var finder = {};
      var row;
      var i = 0;
      for (i = 0; i < tdata.length; i++) {
        row = tdata[i];

        // 버튼 권한 넣기
        // start

        // eslint-disable-next-line
        var buttonId = row.buttonId + 'NNNNNNNNN';
        // eslint-disable-next-line
        var ynC = row.buttonId.charAt(1) == 'Y' ? 'Y' : 'N';
        var ynR = row.buttonId.charAt(2) == 'Y' ? 'Y' : 'N';
        var ynU = row.buttonId.charAt(3) == 'Y' ? 'Y' : 'N';
        var ynD = row.buttonId.charAt(4) == 'Y' ? 'Y' : 'N';
        var ynP = row.buttonId.charAt(5) == 'Y' ? 'Y' : 'N';
        var ynX = row.buttonId.charAt(6) == 'Y' ? 'Y' : 'N';

        row.vwYn = ynR;
        row.userVwYn = ynR;

        row.svYn = ynU;
        row.userSvYn = ynU;

        row.delYn = ynD;
        row.userDelYn = ynD;

        row.prnYn = ynP;
        row.userPrnYn = ynP;

        row.excelYn = ynX;
        row.userExcelYn = ynX;

        // end

        row.isSelected = false;
        var menuId = row.menuId;
        var parentMenuId = row.parentMenuId;
        finder[menuId] = {parentMenuId: parentMenuId, row: row};
      }
      for (i = 0; i < tdata.length; i++) {
        row = tdata[i];
        var frow = row;
        var whileVal = 1;
        do {
          if (frow == null) {
            row.rootMenuId = '';
            break;
          }
          if (frow.parentMenuId == 'MNU00000') {
            row.rootMenuId = frow.menuId;
            break;
          }
          // console.log("frow.parentMenuId",frow.parentMenuId);
          if(typeof frow.parentMenuId == "undefined"){
            whileVal = 0; break;
          }
          frow = finder[frow.parentMenuId].row;
        } while (whileVal == 1);
      }
      return Array.from(tdata);
    },
    boardTabChange(n) {
      if (this.boardTabIndex == n) return;

      document.getElementById('boardTab' + this.boardTabIndex).classList.remove('on');
      document.getElementById('boardTab' + n).classList.add('on');

      document.getElementById('boardTabContent' + this.boardTabIndex).style.display = 'none';
      document.getElementById('boardTabContent' + n).style.display = 'block';

      this.boardTabIndex = n;
    },
    async goMenu_oContract(page, statusCode) {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf(`/view/contract/oContract/firstContract/${page}`) > -1
      )[0];

      var params = {};
      params.statusCode = statusCode;
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'OContractList_Search'});
    },
    async goMenu_oContract_officialDocument(agreSttusCd, scdSendng) {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf('/view/contract/oContract/offcialDocument/progress') > -1
      )[0];

      var params = {};
      params.statusCode = '';
      params.agreSttusCd = agreSttusCd;
      if (scdSendng !== undefined) {
        params.scdSendng = 'Y';
      }
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'OfficialDocumentList_Search'});
    },
    async goMenu_tContract(change, page, statusCode) {
      var menuData = this.menus.filter(
        data =>
          data.webUrl.indexOf(`/view/contract/tContract/${change ? 'changeContract' : 'firstContract'}/${page}`) > -1
      )[0];

      var params = {};
      params.statusCode = statusCode;
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'TContractList_Search'});
    },
    async goMenu_tEstimate(page, statusCode) {
      var menuData = this.menus.filter(
        data => data.webUrl.indexOf(`/view/contract/tContract/estimate/${page}`) > -1
      )[0];

      var params = {};
      params.statusCode = statusCode;
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'EstimateList_Search'});
    },

    /*한개 펑션으로 통일*/
    async goMenu_Parts(pT, sC) {
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/part/rindex.vue') > -1)[0];

      var params = {};
      params.paymentType = pT;
      params.statusCode = sC;
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;
      this.mnu_click(menuData);
      //this.callEvent({name: 'PartMainList_PreSearch'});
      this.callEvent({name: 'PartRSubList_PreSearch'});
    },
    async goMenu_ConstCmpl(pT, sC) {
      var menuData = this.menus.filter(data => data.webUrl.indexOf('/view/confdoc/constcmpl') > -1)[0];

      var params = {};
      params.paymentType = pT;
      params.statusCode = sC;
      params.summaryYm = this.searchParam.yyyyMm;
      params.empNo = this.userInfo.empNo;
      menuData.params = params;

      this.mnu_click(menuData);
      this.callEvent({name: 'ConstCmplMainList_PreSearch'});
    },
    async mnu_click(menuData) {
      if (this.urls.filter(e => e.url === menuData.url).length == 0 && this.urls.length >= MAX_URLS_LENGTH) {
        await this.$alert(
          `화면은 최대 ${MAX_URLS_LENGTH}개까지 열 수 있습니다.<br /><br />다른 화면을 닫은 후 다시 열어 주십시오.`
        );
        return;
      } else {
        this.addMDI(menuData, false);
      }
    },
    onSetValue(obj, name) {
      // 변경값 SET
      let key = '';
      let value = '';
      if (typeof obj == 'string') {
        key = name;
        value = obj;
      } else {
        key = obj.target.name;
        value = obj.target.type == 'checkbox' ? (obj.target.checked ? 'Y' : '') : obj.target.value;
      }
      this.searchParam[key] = value;
    },
    async onSearch() {
      if (!this.listBoxLoading) {
        this.selectSummaryCnt();
      } else {
        this.$alert('먼저 요청한 정보를 조회 중 입니다.');
      }
    },
    async selectSummaryCnt() {
      console.log('this.userInfo', this.userInfo);
      this.searchParam.otisPage = this.userInfo.userType == 'U';
      this.searchParam.customerPage = this.userInfo.userType != 'U';
      console.log(this.searchParam);

      let param = {
        ...this.searchParam,
      };

      try {
        this.listBoxLoading = true;
        this.contentBoxLoading = true;
        let response = await axios.post('/summary/summaryInfoSp', param);
        this.listBoxLoading = false;
        console.log('response.data.length', response.data.length);
        console.log(response.data);

        var nvl0 = function(v) {
          return !v || v == '' ? 0 : v;
        };
        var right = function(str, chr) {
          return str.slice(str.length - chr, str.length);
        };
        var $this = this;
        for (const [key, value] of Object.entries(response.data)) {
          if (right(key, 3) == 'Cnt') {
            $this.summary[key] = nvl0(value);
            console.log('this.summary[key]', $this.summary[key]);
          }
        }
        this.contentBoxLoading = false;
        /*

        this.summary.oContractSumCnt			          = nvl0( response.data.oContractSumCnt );
        this.summary.oContractSendCnt		            = nvl0( response.data.oContractSendCnt );
        this.summary.oContractSingingCnt		        = nvl0( response.data.oContractSingingCnt );
        this.summary.oContractCompleteCnt		        = nvl0( response.data.oContractCompleteCnt );
        this.summary.oContractRejectCnt			        = nvl0( response.data.oContractRejectCnt );
        this.summary.oContractRejectCustCnt         = nvl0( response.data.oContractRejectCustCnt );
        this.summary.oContractRecoveryCnt           = nvl0( response.data.oContractRecoveryCnt );
        this.summary.oContractPrevSumCnt		        = nvl0( response.data.oContractPrevSumCnt );
        this.summary.officialDocumentSumCnt		      = nvl0( response.data.officialDocumentSumCnt );
        this.summary.officialDocumentAgreeCnt		    = nvl0( response.data.officialDocumentAgreeCnt );
        this.summary.officialDocumentNotAgreeCnt	  = nvl0( response.data.officialDocumentNotAgreeCnt );
        this.summary.officialDocumentNotAckCnt		  = nvl0( response.data.officialDocumentNotAckCnt );
        this.summary.officialDocumentSecondSendCnt	= nvl0( response.data.officialDocumentSecondSendCnt );
        this.summary.tContractSumCnt			          = nvl0( response.data.tContractSumCnt );
        this.summary.tContractSendCnt		            = nvl0( response.data.tContractSendCnt );
        this.summary.tContractSingingCnt		        = nvl0( response.data.tContractSingingCnt );
        this.summary.tContractCompleteCnt		        = nvl0( response.data.tContractCompleteCnt );
        this.summary.tContractRejectCnt			        = nvl0( response.data.tContractRejectCnt );
        this.summary.tContractRejectCustCnt         = nvl0( response.data.tContractRejectCustCnt );
        this.summary.tContractRecoveryCnt           = nvl0( response.data.tContractRecoveryCnt );
        this.summary.tContractPrevSumCnt		        = nvl0( response.data.tContractPrevSumCnt );
        this.summary.tChangeContractSumCnt		      = nvl0( response.data.tChangeContractSumCnt );
        this.summary.tChangeContractSendCnt		      = nvl0( response.data.tChangeContractSendCnt );
        this.summary.tChangeContractSingingCnt		  = nvl0( response.data.tChangeContractSingingCnt );
        this.summary.tChangeContractCompleteCnt		  = nvl0( response.data.tChangeContractCompleteCnt );
        this.summary.tChangeContractRejectCnt		    = nvl0( response.data.tChangeContractRejectCnt );
        this.summary.tChangeContractRejectCustCnt   = nvl0( response.data.tChangeContractRejectCustCnt );
        this.summary.tChangeContractRecoveryCnt     = nvl0( response.data.tChangeContractRecoveryCnt );
        this.summary.tChangeContractPrevSumCnt		  = nvl0( response.data.tChangeContractPrevSumCnt );
        this.summary.estimateSumCnt			            = nvl0( response.data.estimateSumCnt );
        this.summary.estimateSendCnt		            = nvl0( response.data.estimateSendCnt );
        this.summary.estimateSingingCnt			        = nvl0( response.data.estimateSingingCnt );
        this.summary.estimateCompleteCnt		        = nvl0( response.data.estimateCompleteCnt );
        this.summary.estimateRejectCnt			        = nvl0( response.data.estimateRejectCnt );
        this.summary.estimateRejectCustCnt          = nvl0( response.data.estimateRejectCustCnt );
        this.summary.estimateRecoveryCnt            = nvl0( response.data.estimateRecoveryCnt );
        this.summary.estimatePrevSumCnt			        = nvl0( response.data.estimatePrevSumCnt );
        this.summary.selectCmsSumCnt			          = nvl0( response.data.selectCmsSumCnt );
        this.summary.selectCmsNewCnt			          = nvl0( response.data.selectCmsNewCnt );
        this.summary.selectCmsChangeCnt			        = nvl0( response.data.selectCmsChangeCnt );
        this.summary.selectViewSumCnt			          = nvl0( response.data.selectViewSumCnt );
        this.summary.selectViewNewCnt			          = nvl0( response.data.selectViewNewCnt );
        this.summary.selectViewChangeCnt            = nvl0( response.data.selectViewChangeCnt );
        this.summary.selectEliteSumCnt			        = nvl0( response.data.selectEliteSumCnt );
        this.summary.selectEliteNewCnt			        = nvl0( response.data.selectEliteNewCnt );
        this.summary.selectEliteChangeCnt		        = nvl0( response.data.selectEliteChangeCnt );

        this.summary.selectPartFreeWriteCnt	= nvl0( response.data.selectPartFreeWriteCnt );
        this.summary.selectPartFreeReqSignCnt	= nvl0( response.data.selectPartFreeReqSignCnt );
        this.summary.selectPartFreeRecoveryCnt	= nvl0( response.data.selectPartFreeRecoveryCnt );
        this.summary.selectPartFreeSignedCnt	= nvl0( response.data.selectPartFreeSignedCnt );
        this.summary.selectPartFreeCompleteCnt	= nvl0( response.data.selectPartFreeCompleteCnt );
        this.summary.selectPartPaidWriteCnt	= nvl0( response.data.selectPartPaidWriteCnt );
        this.summary.selectPartPaidReqSignCnt	= nvl0( response.data.selectPartPaidReqSignCnt );
        this.summary.selectPartPaidRecoveryCnt	= nvl0( response.data.selectPartPaidRecoveryCnt );
        this.summary.selectPartPaidSignedCnt	= nvl0( response.data.selectPartPaidSignedCnt );
        this.summary.selectPartPaidCompleteCnt	= nvl0( response.data.selectPartPaidCompleteCnt );
        this.summary.selectPartClaimWriteCnt	= nvl0( response.data.selectPartClaimWriteCnt );
        this.summary.selectPartClaimReqSignCnt	= nvl0( response.data.selectPartClaimReqSignCnt );
        this.summary.selectPartClaimRecoveryCnt	= nvl0( response.data.selectPartClaimRecoveryCnt );
        this.summary.selectPartClaimSignedCnt	= nvl0( response.data.selectPartClaimSignedCnt );
        this.summary.selectPartClaimCompleteCnt	= nvl0( response.data.selectPartClaimCompleteCnt );
        this.summary.selectConstCmplFreeWriteCnt	= nvl0( response.data.selectConstCmplFreeWriteCnt );
        this.summary.selectConstCmplFreeReqSignCnt	= nvl0( response.data.selectConstCmplFreeReqSignCnt );
        this.summary.selectConstCmplFreeRecoveryCnt	= nvl0( response.data.selectConstCmplFreeRecoveryCnt );
        this.summary.selectConstCmplFreeSignedCnt	= nvl0( response.data.selectConstCmplFreeSignedCnt );
        this.summary.selectConstCmplFreeCompleteCnt	= nvl0( response.data.selectConstCmplFreeCompleteCnt );
        this.summary.selectConstCmplPaidWriteCnt	= nvl0( response.data.selectConstCmplPaidWriteCnt );
        this.summary.selectConstCmplPaidReqSignCnt	= nvl0( response.data.selectConstCmplPaidReqSignCnt );
        this.summary.selectConstCmplPaidRecoveryCnt	= nvl0( response.data.selectConstCmplPaidRecoveryCnt );
        this.summary.selectConstCmplPaidSignedCnt	= nvl0( response.data.selectConstCmplPaidSignedCnt );
        this.summary.selectConstCmplPaidCompleteCnt	= nvl0( response.data.selectConstCmplPaidCompleteCnt );
        */
      } catch (ex) {
        this.listBoxLoading = false;
        this.contentBoxLoading = false;
      }
    },
    async onSearchNotiBoardList() {
      let response = await axios.post('/board/selectBoardSummaryList', {
        pgmCd: '201900',
        natDs: 'NOTI',
        frmNm: '',
      });
      this.notiBoardList = response.data;
    },
    async onSearchFileBoardList() {
      let response = await axios.post('/board/selectBoardSummaryList', {
        pgmCd: '201900',
        natDs: 'FILE',
        frmNm: '',
      });
      this.fileBoardList = response.data;
    },
    async onBoardView(natDs, natNo) {
      this.board.natDs = natDs;
      this.board.natNo = natNo;
      this.$modal.show(this.boardViewModalId);
    },

    async getSummary(workType) {
      const data = {
        workType: workType,
      };

      try {
        const response = await axios.post('/summary/getSummary', data);

        this.summary[workType.toLowerCase()] = response.data;
      } catch (ex) {
        console.log(ex);
      }
    },
  },
};
</script>

<style scoped>
#ne_table {
  font-size: 16px;
  font-weight: bold;
}

.dashbaord_title {
  display: block;
  margin-top: 15px;
  padding-left: 20px;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  font-weight: bold;
  background: url('../../assets/img/bu_dash_title02.png') no-repeat left center;
  background-size: 14px 14px;
}

table.dashboard tr > th {
  width: 40px !important;
  min-width: 40px !important;
}

table.dashboard tr.header {
  height: 45px;
}

table.dashboard tr > th.header1 {
  background-color: #9e978e;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}

table.dashboard tr > th.header2 {
  background-color: #cba052;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}

table.dashboard tr > td > div > span.count {
  flex: 1 !important;
  cursor: pointer;
}
</style>
