<template>
  <pmis-content-box :loading="listBoxLoading">
    <iui-container type="table" :headerWidth="width100">
      <i-row>
        <i-col-header>회수 및<br />OKSS 전송<br />사유</i-col-header>
        <i-col>
          <iui-text
            type="multi"
            name="resnCn"
            :value="resnCn"
            :enable="true"
            height="100px"
            required
            @change="resnCn = $event.target.value"
          />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-content-box>
</template>

<script>
export default {
  props: {
    wrtcnsNo: {
      type: String,
      default: undefined,
    },
  },
  created() {
    this.addEvent({name: 'Reject_SendToOkss', func: this.onSendToOkss});
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width120: '120px',
      width170: '170px',
      width200: '200px',

      listBoxLoading: false,

      resnCn: '',

      retrnResn: {
        signSn: '',
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        retrnResnCn: '',
        accesIp: '',
        accesEnvrnCn: '',
        entryEmpId: '',
        entryDate: '',
        chngEmpId: '',
        chngDate: '',
        tradeName: '',
        entryDateStr: '',
      },

      style: {
        'white-space': 'pre-wrap',
        'word-wrap': 'break-word',
      },
    };
  },
  mounted() {
    console.log('Reject');
    this.onSearch();
  },
  methods: {
    async onSearch() {

    },
    async onSendToOkss(callback) {
      if (!this.resnCn.trim()) {
        this.$alert('OKSS 전송 사유를 입력해야합니다.');
        return;
      }

      var msg = 'OKSS로 전송 하시겠습니까?<br/>';
      msg += '전송된 문서는 전자계약시스템에서 조회되지 않습니다.';

      if (await this.$confirm(msg)) {
        let param = {
          wrtcnsNo: this.wrtcnsNo,
          reason: this.resnCn,
          chngPgmId: this.$store.getters['mdi/currentUrls'].url,
        };

        const me = this;
        try {
          me.listBoxLoading = true;
          await axios.post('/wrtcns/recoveryAndSendToOkss', param);
          me.listBoxLoading = false;
          me.$alert('OKSS로 전송이 완료되었습니다.');
          callback({action: 'onSendToOkss'});
        } catch (ex) {
          me.listBoxLoading = false;
          me.$alert('OKSS로 전송에 실패하였습니다.' + e);
        }
      }
    },
  },
  computed: {

  },
};
</script>

<style></style>
