import {numToKr} from 'vue-number-to-kor';

const state = () => ({
    searchInfo: {
      pgmCd: '',
      signType: '',
      ctrtNo: '',
      tradeNo: '',
      mainName: '',
      suriName: '',
      customerName: '',
      empId: '',
      empName: '',
      dateStart: '',
      dateEnd: '',
      statusCode: '',
      otisPage: '',
      customerPage: '',
      teamTreeEmpNo: '',
      wrtcnsNo: '',
    },
    wrtcnsList: [],
    wrtcnsInfo: {
      wrtcnsNo: '',
      ceckSn: '',
      onlineYn: '',
      sendngDt: '',
      orginlCn: '',
      orginlHashcd: '',
      orginlFileNo: 0,
      wrtcnsSttusCd: '',
      linkSn: 0,
      signSn: 0,
      rtrvlResn: '',
      rtrvlDt: '',
      tmstmpOrginlCn: '',
      tmstmpTknCn: '',
      tmstmpIssuDt: '',
      signFileNo: 0,
      dwldUrl: '',
      entryEmpId: '',
      entryDate: '',
      chngEmpId: '',
      chngDate: '',
      chngPgmId: '',
      draftNo: '',
      mainName: '',
      suriName: '',
      custTradeFullName: '',
      custBusinessNo: '',
      custAddr: '',
      custRepntName: '',
      custMphone: '',
      custEmail: '',
      buyagntPartcpnt: '',
      buyagntPosi: '',
      buyagntNm: '',
      buyagntMphone: '',
      buyagntEmail: '',
      ccpyTradeFullName1: '',
      ccpyBusinessNo1: '',
      ccpyAddr1: '',
      ccpyRepntName1: '',
      ccpyLicenseNo1: '',
      ccpyCnstrcNo1: '',
      ccpyBillRate1: '',
      ccpyTradeFullName2: '',
      ccpyBusinessNo2: '',
      ccpyAddr2: '',
      ccpyRepntName2: '',
      ccpyLicenseNo2: '',
      ccpyCnstrcNo2: '',
      ccpyBillRate2: '',
      otisBillRate: '',
      agreeDateYy: '',
      agreeDateMm: '',
      agreeDateDd: '',
      reportEmpId: '',
      empName: '',
      handphoneNo: '',
      mailId: '',
      signDt: '',
      signMthCd: '',
      signerSeCd: '',
      signData1: '',
      signData2: '',
      tradeNo: '',
      tradeSuffix: '',
      cpTradeNo: '',
      personId: '',
    },
    memberList: [],
    historyList: [],

  });
  const getters = {
    searchInfo: state => state.searchInfo,
    wrtcnsList: state => state.wrtcnsList,
    wrtcnsInfo: state => state.wrtcnsInfo,    
    memberList: state => state.memberList,
    historyList: state => state.historyList,
    isEmp : state => (state.wrtcnsInfo.reportEmpId == JSON.parse(sessionStorage.getItem('userInfo')).empNo),
  };
  const mutations = {
    setSearchInfo(state, payload) {
      for (let key in state.searchInfo) {
        if (payload && payload.hasOwnProperty(key)) {
          state.searchInfo[key] = payload[key];
        }
      }
    },
    setWrtcnsList(state, payload) {
      state.wrtcnsList = payload;
    },
    setWrtcnsInfo(state, payload) {
      for (let key in state.wrtcnsInfo) {
        if (payload && payload.hasOwnProperty(key)) {
          state.wrtcnsInfo[key] = payload[key];
        }
      }
    },
    setMemberList(state, payload){
        state.memberList = payload;
      },
    setHistoryList(state, payload){
      state.historyList = payload;
    },
    resetAllValues(state) {
      state.wrtcnsInfo.wrtcnsNo = '',
      state.wrtcnsInfo.ceckSn = '',
      state.wrtcnsInfo.onlineYn = '',
      state.wrtcnsInfo.sendngDt = '',
      state.wrtcnsInfo.orginlCn = '',
      state.wrtcnsInfo.orginlHashcd = '',
      state.wrtcnsInfo.orginlFileNo = 0,
      state.wrtcnsInfo.wrtcnsSttusCd = '',
      state.wrtcnsInfo.linkSn = 0,
      state.wrtcnsInfo.signSn = 0,
      state.wrtcnsInfo.rtrvlResn = '',
      state.wrtcnsInfo.rtrvlDt = '',
      state.wrtcnsInfo.dwldUrl = '',
      state.wrtcnsInfo.entryEmpId = '',
      state.wrtcnsInfo.entryDate = '',
      state.wrtcnsInfo.chngEmpId = '',
      state.wrtcnsInfo.chngDate = '',
      state.wrtcnsInfo.chngPgmId = '',
      state.wrtcnsInfo.draftNo = '',
      state.wrtcnsInfo.mainName = '',
      state.wrtcnsInfo.suriName = '',
      state.wrtcnsInfo.custTradeFullName = '',
      state.wrtcnsInfo.custBusinessNo = '',
      state.wrtcnsInfo.custAddr = '',
      state.wrtcnsInfo.custRepntName = '',
      state.wrtcnsInfo.custMphone = '',
      state.wrtcnsInfo.custEmail = '',
      state.wrtcnsInfo.buyagntPartcpnt = '',
      state.wrtcnsInfo.buyagntPosi = '',
      state.wrtcnsInfo.buyagntNm = '',
      state.wrtcnsInfo.buyagntMphone = '',
      state.wrtcnsInfo.buyagntEmail = '',
      state.wrtcnsInfo.ccpyTradeFullName1 = '',
      state.wrtcnsInfo.ccpyBusinessNo1 = '',
      state.wrtcnsInfo.ccpyAddr1 = '',
      state.wrtcnsInfo.ccpyRepntName1 = '',
      state.wrtcnsInfo.ccpyLicenseNo1 = '',
      state.wrtcnsInfo.ccpyCnstrcNo1 = '',
      state.wrtcnsInfo.ccpyBillRate1 = '',
      state.wrtcnsInfo.ccpyTradeFullName2 = '',
      state.wrtcnsInfo.ccpyBusinessNo2 = '',
      state.wrtcnsInfo.ccpyAddr2 = '',
      state.wrtcnsInfo.ccpyRepntName2 = '',
      state.wrtcnsInfo.ccpyLicenseNo2 = '',
      state.wrtcnsInfo.ccpyCnstrcNo2 = '',
      state.wrtcnsInfo.ccpyBillRate2 = '',
      state.wrtcnsInfo.otisBillRate = '',
      state.wrtcnsInfo.agreeDateYy = '',
      state.wrtcnsInfo.agreeDateMm = '',
      state.wrtcnsInfo.agreeDateDd = '',
      state.wrtcnsInfo.reportEmpId = '',
      state.wrtcnsInfo.empName = '',
      state.wrtcnsInfo.handphoneNo = '',
      state.wrtcnsInfo.mailId = '',
      state.wrtcnsInfo.signDt = '',
      state.wrtcnsInfo.signMthCd = '',
      state.wrtcnsInfo.signerSeCd = '',
      state.wrtcnsInfo.signData1 = '',
      state.wrtcnsInfo.signData2 = '',
      state.wrtcnsInfo.tradeNo = '',
      state.wrtcnsInfo.tradeSuffix = '',
      state.wrtcnsInfo.cpTradeNo = '',
      state.wrtcnsInfo.personId = '',
      
      state.memberList = [];
      state.historyList = [];
    }
  };
  const actions = {};
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
  };
  