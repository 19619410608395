<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text
        type="text"
        name="rptNm"
        label="계약서명"
        :value="searchInfo.rptNm"
        @change="onChange"
        @enter="onSearch"
      ></iui-text>
    </template>
    <template v-slot:header-right>
      <iui-button value="검색" class="btn" @click="onBeforeSearch" />
    </template>
    <iui-container type="jqgrid" :id="sheetId" style="width:99.9%" />
  </pmis-content-box>
</template>

<script>
// eslint-disable-next-line
import {mapGetters, mapMutations} from 'vuex';
export default { 
  beforeCreate() {
    $mapGetters(this, ['reportMain']);
    $mapMutations(this, ['setReportMain' ]);
  },
  created() {
    this.addEvent([
      {name: 'main_search', func: this.onBeforeSearch},
      {name: 'main_add', func: this.onAdd},
      {name: 'main_save', func: this.onSave},
      {name: 'main_delete', func: this.onDelete},
    ]);
  },
  mounted() {
    this.$emit('emitReportMain');
    this.initSheet();
  },
  data() {
    return {
      //sheetId: 'sheet' + this._uid,
      sheetId: 'sheet' + this.$store.getters['mdi/currentUrls'].menuId + '_Main',
      searchInfo: {
        rptNm : '',
      },
      lastRowId : '', //마지막선택한 RowId 저장변수.
      upperFocusKeyCode: '',
      newData:{}
    };
  },
  methods: {
    ...mapMutations('store', {
      setReportMain: 'setReportMain'
    }),

    initSheet() {
      const OPT = {
        Cols: [
          {
            label: '상태',
            name: '_rowStatusName',
            editable: false,
            align: 'center',
            width: 45,
          },
          {
            label: 'No',
            name: 'no',
            editable: false,
            align: 'center',
            width: 50,
          },          
          {
            label: '관리번호',
            name: 'manageCd',
            edittype: 'text',
            editrules:{required:true},
            editoptions: {maxlength: 3},
            editable: true,
            align: 'center',
            width: 100,
          },
          {
            label: '계약서명',
            name: 'rptNm',
            edittype: 'text',
            align: 'left',
            editable: true,
            editrules:{required:true},
            editoptions: {maxlength: 300},
            width:550,
          },
          {name: 'verNo', hidden: true},
          {name: 'rptFlpth', hidden: true},
          {name: 'applcDe', hidden: true},
          {name: 'applcYn', hidden: true},
          {name: 'changeCn', hidden: true},
          {name: 'entryEmpId', hidden: true},
          {name: 'entryEmpName', hidden: true},
          {name: 'entryDate', hidden: true},
          {name: 'chngEmpId', hidden: true},
          {name: 'chngEmpName', hidden: true},
          {name: 'chngDate', hidden: true},
          {name: 'ctrtRowCount', hidden: true},
          {name: '_rowStatusCode', hidden: true}
        ], 
        Events: {
          onDataLoad: this.grid_onDataLoad,
          onClick: this.grid_onClick,
        },
      };

      var me = this;
      var $grdMain = $("#"+this.sheetId);

      console.log('grid_sheet_id='+"#"+me.sheetId);

      $grdMain.jqGrid({
				//height:200,
				width:200,
				autowidth:true,
				//shrinkToFit:true,
				selectionMode : 'singlerow', 
        onselectrow : true,
        multiselect: false,
				colModel: OPT.Cols,
        cellEdit: true,
        cellsubmit: 'clientArray',
        //datatype: 'local',
        //data: 'dataArray',

        //sortname: 'invdate', 
        viewrecords: true,
        //sortorder: "desc",
        

        sortable : true,   //sort 기능
        loadonce : true,   //sort 기능
        
        // eslint-disable-next-line
        beforeSelectRow: function(rowid, e) {
          console.log( "beforeSelectRow:rowid>>>>>"+rowid);
          return true; // true 반환하지 않으면 다음 이벤트가 수행되지 않음.
        },                
				onSelectCell: function(rowid) {
          //$('#history').append('onSelectRow() - rowid: ', rowid,'\t\tstatus: ', status,'\t\te: ', e + '<br/>');
          console.log( "onSelectRow:rowid>>>>>"+rowid);

          var $grdMain = $("#"+me.sheetId);

          //var rowid = grdMain.getGridParam("selrow");

          console.log(' rowid='+ rowid + ',lastRowId='+ me.lastRowId);


          if(rowid && rowid!==me.lastRowId && me.lastRowId){
            console.log(' save 처리...');
            $grdMain.saveRow(me.lastRowId);
            $grdMain.restoreRow(me.lastRowId);
          }
          //$grdMain.editRow(rowid, true);
          var data = $grdMain.getRowData(rowid);
          if(me.lastRowId != rowid) {
              me.setReportMain(data);
              me.callEvent({name: 'hist_search', param: { manageCd: data.manageCd }});
              me.lastRowId = rowid;
          }
				},
        loadComplete: function() {
          //조회된건은 수정불가. 삭제만가능...
          console.log('loadComplete~~~');
          var $grdMain = $("#"+me.sheetId);
          var ids = $grdMain.jqGrid('getDataIDs');
          for (var i=0;i<ids.length;i++) {
              var id=ids[i];
              if ($grdMain.jqGrid('getCell',id,'manageCd') != '') {
                  $grdMain.jqGrid('setCell',id,'manageCd','','not-editable-cell');
            }
          }

          //첫번째로우 선택.
          if(ids && ids.length > 0){
              var data = $grdMain.getRowData(ids[0]);
              me.setReportMain(data);
              me.callEvent({name: 'hist_search', param: { manageCd: data.manageCd }});
              me.lastRowId = ids[0];
          }
        },
        //수정건 수정으로표시.
        // eslint-disable-next-line
        afterSaveCell: function (rowid, name, val, iRow, iCol) {
        //beforeSaveCell: function (rowid, name, val, iRow, iCol) {
          console.log('afterSaveCell~~~'+rowid);
          var $grdMain = $("#"+me.sheetId);
          if ($grdMain.jqGrid('getCell', rowid, "_rowStatusCode") == 'N') {
            $grdMain.jqGrid('setRowData', rowid, { _rowStatusCode: "U"});
            $grdMain.jqGrid('setRowData', rowid, { _rowStatusName: "U"});
          }
          return true;
        }, 
        // eslint-disable-next-line
        beforeEditCell:function(rowid, cellname, value, iRow, iCol){
          console.log('beforeEditCell~~~'+rowid);
          var data = $grdMain.getRowData(rowid);
          if(me.lastRowId != rowid) {
              me.setReportMain(data);
              me.callEvent({name: 'hist_search', param: { manageCd: data.manageCd }});
              me.lastRowId = rowid;
          }          
        },
        //열려있는 상태에서도 저장이되게끔 해줘야함.
        afterEditCell:function(rowid, cellname, value, iRow, iCol){
        //beforeSaveCell:function(rowid, cellname, value, iRow, iCol){
          console.log('afterEditCell~~~'+rowid);
          var $grdMain = $("#"+me.sheetId);
          $("#" + rowid + "_" + cellname).blur(function(){
              $grdMain.jqGrid("saveCell",iRow,iCol);
          });
          return true;
        },
      });
      $gridAutoResize( $grdMain );
    },
    onBeforeSearch() {
      console.log('onBeforeSearch!!');
      this.upperFocusKeyCode = '';
      this.onSearch();
    },
    async onSearch() {
      //this.searchInfo.rptNm = '';
      let response = await axios.post("/report/selectReportFile", {...this.searchInfo});
      var data = response.data;
      console.log(data);
      for(var i=0;i<data.length;i++) {
        data[i].no = (i+1);
        data[i].isNew = false;
        //data[i].pgmCd = this.searchInfo.pgmCd;
      }

			let me = this;
			var $grdMain = $("#"+me.sheetId);
      $grdMain.jqGrid( 'clearGridData' );
      $grdMain.jqGrid( 'setGridParam', { data:data }).trigger('reloadGrid');
      //$("#"+this.sheetId1).clearGridData(true).setGridParam({data:response.data}).trigger("reloadGrid");
      //$grid.jqGrid.setGridParam({data:response.data}).trigger("reloadGrid");

    },
    /**
     *  신규추가. 한건씩만 추가 가능.
     */
    onAdd() {
      let me = this;
      var $grdMain = $("#"+me.sheetId);
      let rowCnt = $grdMain.getGridParam("reccount");

      //신규 추가된 건이 있으면 저장 후 처리.
      //var changeData = $grdMain.getChangedCells('all');//dirty:변경된 컬럼만.
      //console.log("changeData>>>>", changeData);


      //신규 추가된 건이 있으면 저장 후 처리.
      //var changeData = $grdMain.getChangedCells('all');//dirty:변경된 컬럼만.
      //console.log("changeData>>>>", changeData);

      //$grdMain.saveRow(me.lastRowId);
      //$grdMain.restoreRow(me.lastRowId);

      var ids = $grdMain.jqGrid('getDataIDs');
      console.log("getDataIDs>>>>", ids);
      for (var i=0;i<ids.length;i++) {
        var rowData =$grdMain.getRowData(ids[i]);
        //console.log("rowData>>>>", rowData);
        if (String(rowData._rowStatusCode).trim() == 'I') {
          this.$alert('신규 추가건이 존재합니다.<br/>저장 후 이력상세정보를 입력하세요.');
          return;
        }
      }


      //console.log(  $grdMain.getrow);

      me.callEvent({name: 'hist_search', param: { manageCd: '' }});


      var addData = {
          "_rowStatusCode": 'I'
        , "_rowStatusName":'I'
        , "no": rowCnt+1
        , "manageCd" : ""
        , "verNo" : 0
        , "rptNm" : ""
        , "rptFlpth" : ""
        , "applcDe" : ""
        , "applcYn" : ""
        , "changeCn" : ""
      };
       // 페이징 처리 시 현 페이지의 Max RowId 값
      $grdMain.jqGrid("addRowData", rowCnt+1, addData, 'last'); // 마지막 행에 Row 추가      
      $grdMain.jqGrid("editCell", rowCnt+1, 2, true); //관리코드

      me.lastRowId = $grdMain.getGridParam("selrow");
    },
    async onSave() {
      var $grdMain = $("#"+this.sheetId);
      // eslint-disable-next-line
      var changeData = $grdMain.getChangedCells('all');//dirty:변경된 컬럼만.


      var isChanged = false;
      var grdData = $grdMain.getRowData();
      if(grdData){
        for (var i=0;i<grdData.length;i++) {
          if ('I,D,U'.indexOf(String(grdData[i]._rowStatusCode).trim()) > -1) {
            isChanged = true;
            break;
          }
        }
      }
      console.log("onSave:getRowData>>>>", grdData);

      if(!isChanged){
        this.$alert('수정된 내용이 없습니다.');
        return;
      }
      //일단 다(all)보낸다.
      // eslint-disable-next-line
      for (var i=0; i< grdData.length; i++){
        if('I'==String(grdData[i]._rowStatusCode).trim()){
          if (''==String(grdData[i].manageCd).trim()) {
            this.$alert('관리코드는 필수입력 항목입니다.');
            $grdMain.jqGrid("editCell", grdData[i].id, 2, true); //관리코드
            return;
          }
          this.newData = Object.assign({},grdData[i]);
          console.log('this.newData==1',grdData[i]);
        }
        if (''==String(grdData[i].rptNm).trim()) {
          this.$alert('계약서 제목은 필수입력 항목입니다.');
          $grdMain.jqGrid("editCell", grdData[i].id, 3, true); //제목
          return;
        }
      }

      console.log('this.newData==2',this.newData);

      //중복데이터체크.
      if(this.newData && this.newData.length == 1){
        var response = await axios.post('/report/selectDuplicateCheckReportFile', this.newData);
        console.log( "중복체크", response );
        if (parseInt( response.data.checkCnt)>0) {
          //this.$alert(`중복되는 계약서 관리코드가 있습니다.`);
          this.$alert(`중복되는 계약서 관리코드가 있습니다.<br/>[${this.newData.manageCd}]`);
          return;
        }
      }

      let message = '저장하시겠습니까?';
      if (await this.$confirm(message)) {
        response = await axios
          .post('/report/saveReportFileHist', grdData)
          .catch(e => console.error('저장을 실패했습니다. :', e));

        if (response.status == 200) {
          //this.upperFocusKeyCode = response.data;
          this.onSearch();
        } else {
          this.$alert('저장을 실패했습니다.');
        }
      }
    },
    onDelete() {
      // _rowStatusCode:'I' ->삭제처리.
      // _rowStatusCode:'N','U' -> '삭제'표시
      //$("#testGrid").jqGrid('delRowData', rowids[i]); // 로우 삭제
      var $grdMain = $("#"+this.sheetId);
      var rowid = $grdMain.getGridParam("selrow");
      if(!rowid){
        this.$alert('선택된 항목이 없습니다.');
        return;
      }
      var data = $grdMain.getRowData(rowid);
      console.log('선택데이터', data);
      if(parseInt(data.ctrtRowCount) > 1){
        this.$alert('[계약서 상세이력]이 존재합니다.<br>화면 좌측의 [상세이력정보]를 확인하세요');
        return;
      }

      if('Y'==data.applcYn){
        this.$alert('사용중인 계약서 파일입니다.<br>화면 좌측의 [상세이력정보]를 확인하세요');
        return;
      }

      $grdMain.jqGrid('setCell',rowid,'_rowStatusCode','D');
      $grdMain.jqGrid('setCell',rowid,'_rowStatusName','D');

      $grdMain.saveRow(rowid);
      $grdMain.restoreRow(rowid);

    },
    onChange(e) {
      this.searchInfo.rptNm = e.target.value;
    },
  },
};
</script>

<style scoped></style>