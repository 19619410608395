<template>
  <iui-container type="css-flex" theme="bullet">
    <i-row height="70px" ref="srchBox" v-if="otisPage">
      <i-col>
        <iui-container type="table" theme="bullet">
          <i-row>
            <i-col-header>공사명</i-col-header>
            <i-col>
              <iui-text name="contractName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>고객명</i-col-header>
            <i-col>
              <iui-text name="customerName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>CC/팀</i-col-header>
            <i-col>
              <iui-text name="ccName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>견적번호</i-col-header>
            <i-col>
              <iui-text name="ctrtNo" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>영업담당</i-col-header>
            <i-col>
              <iui-text name="empName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>견적일</i-col-header>
            <i-col
              ><span style="white-space:nowrap">
                <iui-datepicker-old
                  name="checkContDateStart"
                  suffix=" ~ "
                  @change="onSetValue($event, 'checkContDateStart')"
                  :value="search.checkContDateStart"/>
                &nbsp;<iui-datepicker-old
                  name="checkContDateEnd"
                  @change="onSetValue($event, 'checkContDateEnd')"
                  :value="search.checkContDateEnd"
              /></span>
            </i-col>
            <i-col-header>진행상태</i-col-header>
            <i-col colspan="3">
              <iui-select-old
                name="statusCode"
                :items="arrStatusCode"
                :value="search.statusCode"
                :enable="true"
                @change="onSetValue"
                required
              />
            </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
    <i-row height="40px" ref="srchBox" v-if="customerPage">
      <i-col>
        <iui-container type="table" theme="bullet" v-if="customerPage">
          <i-row>
            <i-col-header>공사명</i-col-header>
            <i-col>
              <iui-text name="contractName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>견적일</i-col-header>
            <i-col
              ><span style="white-space:nowrap">
                <iui-datepicker-old
                  name="checkContDateStart"
                  suffix="~"
                  @change="onSetValue($event, 'checkContDateStart')"
                  :value="search.checkContDateStart"/>
                &nbsp;<iui-datepicker-old
                  name="checkContDateEnd"
                  @change="onSetValue($event, 'checkContDateEnd')"
                  :value="search.checkContDateEnd"
              /></span>
            </i-col>
            <i-col-header>진행상태</i-col-header>
            <i-col>
              <iui-select-old
                name="statusCode"
                :items="arrStatusCode"
                :value="search.statusCode"
                :enable="true"
                @change="onSetValue"
                required
              />
            </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
    <i-row height="260px">
      <i-col>
        <pmis-content-box no-border-box>
          <template v-slot:title v-if="isProgressPage">
            진행중 견적목록
          </template>
          <template v-slot:title v-else-if="isCompletePage">
            완료 견적목록
          </template>
          <template v-slot:title-left v-if="isProgressPage && customerPage">
            <font style="color:#FF6666; font-weight: bold;">(견적서를 확인하여 동의 하여 주시기 바랍니다.)</font>
          </template>
          <template #title-right v-if="isProgressPage && otisPage">
            <!-- <iui-button :enable="isCanPDFGen" @click="onControlButton('pdfGen')">견적서 재전송</iui-button> -->
            <iui-button :enable="isCanReject" @click="onControlButton('reject')">반송/회수관리</iui-button>
            <iui-button :enable="isCanRecovery" @click="onControlButton('recovery')">회수</iui-button>
          </template>
          <iui-container type="jqgrid" :id="sheetId" :loading="listBoxLoading" style="width:99.9%" />
          <iui-modal-old :name="recoveryModalId" :title="'회수'" :btns="modalBtns1" width="400px" height="320px">
            <Recovery :ctrtNo="focusKeys.ctrtNo" :ctrtOdr="focusKeys.ctrtOdr" :ctrtDivCd="focusKeys.ctrtDivCd" />
          </iui-modal-old>
          <iui-modal-old
            :name="rejectModalId"
            :title="'OKSS전송/재전송'"
            :btns="modalBtns2"
            width="900px"
            height="400px"
          >
            <Reject :ctrtNo="focusKeys.ctrtNo" :ctrtOdr="focusKeys.ctrtOdr" :ctrtDivCd="focusKeys.ctrtDivCd" />
          </iui-modal-old>
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
import Recovery from '../../components/popup/Recovery.vue';
import Reject from '../../components/popup/Reject.vue';
export default {
  name: 'estimate-list',
  props: {
    progressPage: {
      // 진행중 페이지
      type: Boolean,
      default: false,
    },
    completePage: {
      // 계약완료목록 페이지
      type: Boolean,
      default: false,
    },
    otisPage: {
      // OSE 페이지
      type: Boolean,
      default: false,
    },
    customerPage: {
      // 업체 페이지
      type: Boolean,
      default: false,
    },
  },
  components: {
    Recovery,
    Reject,
  },
  beforeCreate() {
    $mapGetters(this, ['estimateInfo', 'isProgressPage', 'isCompletePage', 'isEstEmp']);
    $mapMutations(this, ['setProgressPage', 'setCompletePage']);
  },
  created() {
    this.addEvent({name: 'EstimateList_Search', func: this.onPreSearch});
    this.setProgressPage(this.progressPage);
    this.setCompletePage(this.completePage);
  },
  data() {
    return {
      sheetId: 'sheet' + this.$store.getters['mdi/currentUrls'].menuId,
      modalNm: '',
      modalBtns1: [{name: '회수', callback: this.onPopupConfirm}],
      modalBtns2: [
        {name: 'OKSS전송', callback: this.onPopupConfirmSendToOkss},
        {name: 'OSEP 재전송', callback: this.onPopupConfirmReSend},
      ],
      recoveryModalId: 'recoveryModal' + this._uid,
      rejectModalId: 'rejectModal' + this._uid,

      arrStatusCode: [{text: '전체', value: ''}],
      search: {
        contractName: '',
        customerName: '',
        ccName: '',
        empName: '',
        checkContDateStart: '',
        checkContDateEnd: '',
        directYn: '',
        statusCode: '',
        isProgressPage: this.progressPage,
        isCompletePage: this.completePage,
        isOtisPage: this.otisPage,
        isCustomerPage: this.customerPage,
        summaryYm: '',
        delayYm: '',
        empNo: '',
        ctrtNo: '',
      },

      listBoxLoading: false,

      focusKeys: {
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
      },

      modalOZViewerBtns: [],
      OZParam: {},
    };
  },
  mounted() {
    if (this.progressPage) {
      this.arrStatusCode.push({text: '업체송부', value: '10'});
      this.arrStatusCode.push({text: '구성원반송', value: '11'});
      this.arrStatusCode.push({text: '회수', value: '12'});
      this.arrStatusCode.push({text: 'OKSS송부', value: '13'});
      this.arrStatusCode.push({text: '고객반송', value: '14'});
      this.arrStatusCode.push({text: '구성원서명중', value: '20'});
    }

    if (this.search.summaryYm === undefined || this.search.summaryYm == '') {
      //this.search.checkContDateStart = $_addDay($_getCurrentDate(), -30);
      this.search.checkContDateStart = $_addMonth($_getCurrentDate(), -3);
      this.search.checkContDateEnd = $_getCurrentDate();
    }

    if (this.isCompletePage) {
      this.arrStatusCode.push({text: '계약완료', value: '30'});
      this.arrStatusCode.push({text: '공전소전송완료', value: '40'});
      this.arrStatusCode.push({text: '공전소전송실패', value: '41'});
    }

    const OPT = {
      Cols: [
        // eslint-disable-next-line
        {
          name: '견적서',
          label: '견적서',
          width: 50,
          align: 'center',
          formatter: function(cellvalue, options, rowObject) {
            cellvalue = true;
            return cellvalue == true ? '<button>보기</button>' : '';
          },
          cellattr: $_rowMearge,
        },
        {name: 'srpType', label: '유형', width: 50, align: 'center'},
        {name: 'estDate', label: '발행일자', width: 70, align: 'center'},
        {name: 'estNo', label: '견적번호', width: 100, align: 'center'},
        {name: 'revisionNo', label: '차수', width: 50, align: 'center'},
        {name: 'mainNo', label: '관리번호', width: 70, align: 'center'},
        {name: 'custName', label: '고객명', width: 150, align: 'left'},
        {name: 'estName', label: '공사명', width: 200, align: 'left'},
        {name: 'modelType', label: '기종', width: 180, align: 'left'},
        {
          name: 'adjEstPrice',
          label: '공급가액',
          width: 100,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
        },
        {name: 'ctrtNo', label: 'ctrtNo', width: 0, hidden: true},
        {name: 'ctrtOdr', label: 'ctrtOdr', width: 0, hidden: true},
        {name: 'ctrtDivCd', label: 'ctrtDivCd', width: 0, hidden: true},
        {name: 'ctrtSttusCd', label: 'ctrtSttusCd', width: 0, hidden: true},
        {name: 'docCat1', label: 'docCat1', width: 0, hidden: true},
        {name: 'docNm', label: 'docNm', width: 0, hidden: true},
        {name: 'docGb1', label: 'docGb1', width: 0, hidden: true},
        {name: 'docGb2', label: 'docGb2', width: 0, hidden: true},
        {name: 'docGb3', label: 'docGb3', width: 0, hidden: true},
        {name: 'estEmpId', label: '견적담당자ID', width: 0, hidden: true},
      ],
    };
    if (this.isProgressPage) {
      OPT.Cols.push({
        name: 'sttusRecovery',
        label: '회수',
        width: 50,
        align: 'center',
        formatter: function(cellvalue) {
          return cellvalue == '○' ? 'Ｏ' : '';
        },
      });
      OPT.Cols.push({
        name: 'sttusSend',
        label: '송부',
        width: 50,
        align: 'center',
        formatter: function(cellvalue) {
          return cellvalue == '○' ? 'Ｏ' : '';
        },
      });
      OPT.Cols.push({
        name: 'sttusSigning',
        label: '서명',
        width: 50,
        align: 'center',
        formatter: function(cellvalue) {
          return cellvalue == '○' ? 'Ｏ' : '';
        },
      });
      OPT.Cols.push({
        name: 'sttusReject',
        label: '반송',
        width: 50,
        align: 'center',
        formatter: function(cellvalue) {
          return cellvalue == '○' ? 'Ｏ' : '';
        },
      });
    }
    OPT.Cols.push({name: 'estEmpNm', label: '견적담당자명', width: 80, align: 'center'});

    const me = this;
    var $grid = $('#' + me.sheetId);
    $grid.jqGrid({
      height: 120,
      width: 1135,
      colModel: OPT.Cols,
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
      autowidth: false,
      shrinkToFit: false,
      // eslint-disable-next-line
      onCellSelect: function(rowId, iCol, cellContent, e) {
        var rowData = $('#' + me.sheetId).jqGrid('getRowData', rowId);
        if (me.focusKeys.ctrtNo!=rowData.ctrtNo 
            || me.focusKeys.ctrtOdr!=rowData.ctrtOdr 
            || me.focusKeys.ctrtDivCd!=rowData.ctrtDivCd) {
          // 20211101 lgksja 보기일경우는 팝업만 열리게
          me.$emit('click', rowData);
        }

        me.focusKeys.ctrtNo = rowData.ctrtNo;
        me.focusKeys.ctrtOdr = rowData.ctrtOdr;
        me.focusKeys.ctrtDivCd = rowData.ctrtDivCd;
        if (iCol == $('#' + me.sheetId).jqGrid('getGridParam').iColByName['견적서']) {
          me.onView(rowData);
        }
      },
    });

    $gridAutoResize($grid);
    window.addEventListener('resize', this.onResize);
    setTimeout(() => {
      this.onResize();
    }, 1000);

    // 20210407 검색란 보이기/감추기
    $initSrchShowButton({
      srchBox: this.$refs.srchBox,
      srchList: this.$parent.$parent,
    });
  },
  methods: {
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      var $grid = $('#' + this.sheetId);
      var pobj = $grid
        .closest('.ui-jqgrid')
        .parent()
        .parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth - 2, false);
    },
    onSetValue(e, t) {
      let target = typeof e == 'string' ? t : e.target.name;
      let value = typeof e == 'string' ? e : e.target.value;
      this.search[target] = value;
    },
    onPreSearch() {
      var urls = this.$router.app.urls.filter(data => data.menuId == this.$route.name)[0];
      if (urls !== undefined) {
        var params = urls.params;
        console.log('params', params);
        if (params !== undefined) {
          this.search.statusCode = '';
          this.search.summaryYm = '';
          this.search.delayYm = '';
          this.search.empNo = '';

          this.search.statusCode = params.statusCode;
          this.search.summaryYm = params.summaryYm;
          this.search.delayYm = params.delayYm;
          this.search.empNo = params.empNo;
        }
      }

      this.focusKeys.ctrtNo = '';
      this.focusKeys.ctrtOdr = '';
      this.focusKeys.ctrtDivCd = '';
      this.onSearch();
    },
    async onSearch() {
      if (this.search.summaryYm === undefined || this.search.summaryYm == '') {
        // ITSR42275 lgksja 계약번호가 입력되면 계약일자와 무관하게 조회하도록
        if (this.search.ctrtNo === undefined || this.search.ctrtNo == '') {
          let yearCheck = 0;
          let monthCheck = 0;
          let dayCheck = 0;

          let startYyyy = this.search.checkContDateStart.substring(0, 4); // 시작 년도
          let endYyyy = this.search.checkContDateEnd.substring(0, 4); // 종료 년도

          let startMm = this.search.checkContDateStart.substring(4, 6); // 시작 달
          let endMm = this.search.checkContDateEnd.substring(4, 6); // 종료 달

          let startDd = this.search.checkContDateStart.substring(6, 8); // 시작 일
          let endDd = this.search.checkContDateEnd.substring(6, 8); // 종료 일

          // 계약기간 비워져 있을시에 alert
          if (!this.search.checkContDateStart || !this.search.checkContDateEnd) {
            // this.$alert('검색하실 계약일의 시작일과 종료일을 선택해주세요. (1개월 이내)');
            this.$alert('검색하실 계약일의 시작일과 종료일을 선택해주세요. (3개월 이내)');
            return;
          }
          // 계약기간 검사 (연,월,일 기준 검사)
          yearCheck = parseInt(endYyyy) - parseInt(startYyyy);
          monthCheck = parseInt(endMm) - parseInt(startMm);
          dayCheck = parseInt(endDd) - parseInt(startDd);

          // 3개월용
          if (yearCheck == 1){
            // 작년 10월, 11월, 12월 기준 으로 3개월 검사시
            if (!((startMm == 10 || startMm == 11 || startMm == 12) && monthCheck <- 8)){
              this.$alert("계약 기간을 3개월 이내로 검색해주세요.");
              return ;
            }
          } else if (yearCheck == 0){
            if (monthCheck > 3 && yearCheck == 0){
              this.$alert("계약 기간을 3개월 이내로 검색해주세요.");
              return ;
            }
          } else {
            this.$alert("계약 기간을 3개월 이내로 검색해주세요.");
            return ;
          }

          // 1개월용
          // if (yearCheck == 1) {
          //   // 작년 10월, 11월, 12월 기준 으로 1개월 검사시
          //   if (!(startMm == 12 && monthCheck < -10)) {
          //     this.$alert('계약 기간을 1개월 이내로 검색해주세요.');
          //     return;
          //   }
          // } else if (yearCheck == 0) {
          //   if (monthCheck > 1 && yearCheck == 0) {
          //     this.$alert('계약 기간을 1개월 이내로 검색해주세요.');
          //     return;
          //   }
          // } else {
          //   this.$alert('계약 기간을 1개월 이내로 검색해주세요.');
          //   return;
          // }

          if (yearCheck <= 0 && monthCheck <= 0 && dayCheck < 0) {
            this.$alert('계약일의 시작일은 종료일 보다 뒤일 수 없습니다.<br>시작일을 다시 선택해주세요.');
            this.search.checkContDateStart = '';
            return;
          }
        } else {
          this.search.checkContDateStart = '';
          this.search.checkContDateEnd = '';
        }
      } else {
        this.search.checkContDateStart = '';
        this.search.checkContDateEnd = '';
      }

      this.listBoxLoading = true;

      let param = {
        searchGubun1: this.search.searchGubun1,
        searchText1: this.search.searchText1,
        contractName: this.search.contractName,
        customerName: this.search.customerName,
        ccName: this.search.ccName,
        empName: this.search.empName,
        checkContDateStart: this.search.checkContDateStart,
        checkContDateEnd: this.search.checkContDateEnd,
        directYn: this.search.directYn,
        statusCode: this.search.statusCode,
        progressPage: this.search.isProgressPage,
        completePage: this.search.isCompletePage,
        otisPage: this.search.isOtisPage,
        customerPage: this.search.isCustomerPage,
        summaryYm: this.search.summaryYm,
        delayYm: this.search.delayYm,
        empNo: this.search.empNo,
        ctrtNo: this.search.ctrtNo,
      };

      let response = null;
      try {
        response = await axios.post('/estimate/selectEstimateList', param);
        this.listBoxLoading = false;
        $('#' + this.sheetId)
          .clearGridData(true)
          .setGridParam({data: response.data})
          .trigger('reloadGrid');

        var rowData;
        var ids = $('#' + this.sheetId).jqGrid('getDataIDs');
        if (ids && ids.length > 0) {
          if (this.focusKeys.ctrtNo && this.focusKeys.ctrtOdr && this.focusKeys.ctrtDivCd) {
            for (var i = 0; i <= ids.length - 1; i++) {
              rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[i]);

              if (
                rowData['ctrtNo'] == this.focusKeys.ctrtNo &&
                rowData['ctrtOdr'] == this.focusKeys.ctrtOdr &&
                rowData['ctrtDivCd'] == this.focusKeys.ctrtDivCd
              ) {
                $('#' + this.sheetId).jqGrid('setSelection', ids[i], true);
                this.$emit('click', rowData);
                break;
              }
            }
          } else {
            $('#' + this.sheetId).jqGrid('setSelection', ids[0], true);
            rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[0]);
            this.$emit('click', rowData);
          }
        } else {
          this.$emit('resetValues', null);
        }
      } catch (ex) {
        console.log(ex);
        this.listBoxLoading = false;
      }
    },
    async onControlButton(type) {
      const me = this;

      console.log('ctrtNo', me.estimateInfo.ctrtNo);
      console.log('ctrtOdr', me.estimateInfo.ctrtOdr);
      console.log('ctrtDivCd', me.estimateInfo.ctrtDivCd);
      console.log('ctrtSttusCd', me.estimateInfo.ctrtSttusCd);

      me.focusKeys.ctrtNo = me.estimateInfo.ctrtNo;
      me.focusKeys.ctrtOdr = me.estimateInfo.ctrtOdr;
      me.focusKeys.ctrtDivCd = me.estimateInfo.ctrtDivCd;

      console.log('SCD 관리자', $isAdmin());
      console.log('견적담당자', this.isEstEmp);

      if (!($isAdmin() || this.isEstEmp)) {
        this.$alert('SCD 관리자 또는 견적담당자만 해당 업무를 수행할 수 있습니다.');
        return;
      }

      // 업체송부 : 10
      // 구성원반송 : 11
      // 회수 : 12
      // OKSS송부 : 13
      // 구성원서명중 : 20
      // 계약완료 : 30
      // 공전소전송완료 : 40
      // 공전소전송실패 : 41
      var ctrtSttusCd = me.estimateInfo.ctrtSttusCd;

      if (type == 'pdfGen') {
        if (!(ctrtSttusCd == '10')) {
          this.$alert('선택된 견적은 대리인변경 재송부를 할 수 없는 상태입니다.');
          return;
        }

        if (await me.$confirm('견적서 재전송을 하시겠습니까?')) {
          let param = {
            ctrtNo: me.estimateInfo.ctrtNo,
            ctrtOdr: me.estimateInfo.ctrtOdr,
            ctrtDivCd: me.estimateInfo.ctrtDivCd,
            reason: '견적서 재송부',
            chngPgmId: me.$store.getters['mdi/currentUrls'].url,
          };

          try {
            me.listBoxLoading = true;
            await axios.post('/contract/reSend', param);
            me.listBoxLoading = false;
            me.$alert('견적서 재송부가 완료되었습니다.');
            me.onSearch();
          } catch (ex) {
            me.listBoxLoading = false;
            me.$alert('견적서 재송부가 실패하였습니다.' + e);
          }
        }
      } else if (type == 'reject') {
        if (!(ctrtSttusCd == '11' || ctrtSttusCd == '12' || ctrtSttusCd == '14')) {
          this.$alert('선택된 견적은 반송 또는 회수 할 수 없는 상태입니다.');
          return;
        }

        me.onOpenPopup(this.rejectModalId);
      } else if (type == 'recovery') {
        if (!(ctrtSttusCd == '10' || ctrtSttusCd == '20')) {
          this.$alert('선택된 견적은 회수 할 수 없는 상태입니다.');
          return;
        }

        me.onOpenPopup(this.recoveryModalId);
      }
    },
    onOpenPopup(modalNm) {
      this.modalNm = modalNm;
      this.$modal.show(modalNm);
    },
    onPopupConfirm() {
      console.log('modalNm', this.modalNm);
      if (this.modalNm == this.recoveryModalId) {
        this.callEvent({
          name: 'Recovery_onSave',
          param: data => {
            console.log('Recovery_onSave');
            console.log(data);
            this.$modal.hide(this.modalNm);
            this.onSearch();
          },
        });
      }
    },
    onPopupConfirmSendToOkss() {
      console.log('modalNm', this.modalNm);
      if (this.modalNm == this.rejectModalId) {
        console.log('sendToOkss');
        this.callEvent({
          name: 'Reject_SendToOkss',
          param: data => {
            console.log('Reject_SendToOkss');
            console.log(data);
            this.$modal.hide(this.modalNm);
            this.onSearch();
          },
        });
      }
    },
    onPopupConfirmReSend() {
      console.log('modalNm', this.modalNm);
      if (this.modalNm == this.rejectModalId) {
        console.log('reSend');
        this.callEvent({
          name: 'Reject_ReSend',
          param: data => {
            console.log('Reject_ReSend');
            console.log(data);
            this.$modal.hide(this.modalNm);
            this.onSearch();
          },
        });
      }
    },
    async onView(data) {
      console.log('onView');
      console.log('ctrtNo', data.ctrtNo);
      console.log('ctrtOdr', data.ctrtOdr);
      console.log('ctrtDivCd', data.ctrtDivCd);
      console.log('docCat1', data.docCat1);
      // this.$alert('견적 보기 팝업.');

      let param2 = {
        ctrtNo: data.ctrtNo,
        ctrtOdr: data.ctrtOdr,
        ctrtDivCd: data.ctrtDivCd,
      };
      let response2 = await axios.post('/estimate/selectSignerInfo', param2);

      console.log(response2);

      var signable = 'N';
      var signerSeCd = '30';
      var tradeNo = eval(response2.data.canSign) == true ? response2.data.tradeNo : '';

      console.log('signable', signable);
      console.log('signerSeCd', signerSeCd);
      console.log('tradeNo', tradeNo);

      //견적 조회 후 오즈뷰어 팝업시킴.
      let param = {
        checkContNo: data.ctrtNo,
        checkSeq: data.ctrtOdr,
        manageCd: data.docCat1,
      };

      axios
        .post('/ozrpt/selectOzTCtrtEstimate', param)
        .then(response => {
          // console.log('조회결과 response >>>', response);
          // console.log('조회결과 response.data >>>', response.data);

          this.OZParam = Object.assign({}, response.data.jsonData);

          this.OZParam.reportname = response.data.reportPathName;
          this.OZParam.OZFormParam = {
            signable: signable, // 필수(*):디지털 서명가능여부(Y/N)
            signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
            tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선번호(로그인한사용자)
          };

          const btns = [];
          if (
            this.customerPage &&
            eval(response2.data.osep) == true &&
            eval(response2.data.canSign) == true &&
            (data.ctrtSttusCd == '10' || data.ctrtSttusCd == '20')
          ) {
            btns.push({
              name: '반송', // 버튼명
              callback: () => {
                // 버튼 Event
                popupInterface[opt.name].vue.callEvent({
                  name: 'EstimateViewerPopup_Reject',
                  param: data => {
                    console.log('EstimateViewerPopup_Reject');
                    console.log(data);
                    opt.window.close();
                    this.$alert('반송이 완료되었습니다.');
                    this.onSearch();
                  },
                });
              },
            });
            btns.push({
              name: '동의', // 버튼명
              callback: () => {
                // 버튼 Event
                popupInterface[opt.name].vue.callEvent({
                  name: 'EstimateViewerPopup_Sign',
                  param: data => {
                    console.log('EstimateViewerPopup_Sign');
                    console.log(data);
                    opt.window.close();
                    this.$alert('동의가 완료되었습니다.');
                    this.onSearch();
                  },
                });
              },
            });
          }
          console.log('btns', btns);

          // eslint-disable-next-line
          const opt = $open({
            componentPath: '@/view/contract/tContract/components/popup/EstimateViewer.vue', // 컴포넌트 경로 (필수)
            param: {
              //ctrtNo: this.focusKeys.ctrtNo,
              //ctrtOdr: this.focusKeys.ctrtOdr,
              //ctrtDivCd: this.focusKeys.ctrtDivCd,
              ...param2,
              ctrtSttusCd: data.ctrtSttusCd,
              OZParam: this.OZParam,
              otisPage: this.otisPage,
            }, // 파라미터 (생략가능)
            btns: btns, // 팝업 버튼 (생략가능)
            name: 'EstimateViewer', // 팝업 윈도우 이름 (생략가능)
            option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
            title: '견적조회', // 팝업 제목 (생략가능)
          });
        })
        .catch(function(error) {
          console.log('========error====================');
          console.log(error);
        });
    },
  },
  computed: {
    isCanPDFGen() {
      return this.isProgressPage == true;
    },
    isCanReject() {
      return this.isProgressPage == true;
    },
    isCanRecovery() {
      return this.isProgressPage == true;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style></style>
