<template>
  <div id="wrap_manager">
    <span
      ref="server"
      style="border:3px solid white;padding:5px 20px 10px 20px;font-size:20px;line-height:30px;position:absolute;top:180px;margin:auto;background-color:red;color:white;display:none"
    ></span>
    <div id="contents_manager">
      <div class="logo_m_section">
        <h1 class="logo"><span class="blind">Otis Service Experts</span></h1>
        <div class="footer_info" style="margin-bottom:60px;">
          <span class="copy">
            ⓒ 2024 Otis Service Experts Ltd. 서울 강동구 성내로6길 11, 2층(성내동, 삼원타워)
          </span>

          <a
            href="https://www.otis.com/ko/kr/privacy-policy/"
            target="_blank"
            title="개인정보보호정책"
            ><span>개인정보보호정책</span></a
          >

          <div style="color:white;position:absolute; width:680px;margin-top:10px;font-size:12px">
            Otis의 개인정보 처리방침에 관해 전반적으로 궁금한 사항이 있으시면 이메일(<a href="mailto:privacy@otis-se.com">privacy@otis-se.com</a>) 또는<br/>
            전화(1661-6114)로 문의하시기 바랍니다. 또한 Otis Service Experts에 문의하실 연락처는 다음과 같습니다. <br/>
            개인정보 보호책임자: 김종성 / 담당 부서: 법무팀 / 전화: 1661-6114
          </div>
        </div>
      </div>
      <!-- logo_m_section end -->

      <div class="login_m_section">
        <h2 class="login"><span class="blind">LOGIN</span></h2>
        <input type="hidden" name="pgmCd" value="201900" />
        <label style="color:#fff;">OKBS+ 아이디/비밀번호 와 동일하게 입력하시기 바랍니다.</label>
        <div class="login_m">
          <input type="text" name="id" @keypress="loginOnEnter" value="" placeholder="아이디(사번)를 입력하세요." />
        </div>
        <div class="login_m mt15">
          <input
            type="password"
            name="password"
            @keypress="loginOnEnter"
            value=""
            placeholder="비밀번호(OKBS+)를 입력하세요."
          />
        </div>
        <div class="login_m_txt">
          <input type="checkbox" id="c1" /><label for="c1"><span></span>아이디 저장</label
          ><a :href="manualUrl" target="_blank">사용자매뉴얼</a>
        </div>
        <a href="#" class="btn_login02" @click="login">LOGIN </a>
        <div class="login_m mt20">
          <select
            onchange="if(this.selectedIndex>0) { window.open(this[this.selectedIndex].value);this.selectedIndex=0; }"
          >
            <option value="" selected="selected">고객/3PI/OSEP/업체용 바로가기</option>
            <option value="https://econtract-ose.otis.co.kr">(PC)전자계약시스템</option>
            <option value="https://m.econtract-ose.otis.co.kr">(Mobile)전자계약시스템</option>
          </select>
        </div>
      </div>
      <!-- login_m_section end -->
    </div>
  </div>
  <!-- wrap end -->
</template>

<script>
import {mapActions, mapMutations} from 'vuex';
// import {Datepicker, Timepicker, DatetimePicker} from '@livelybone/vue-datepicker';
import VueCookies from 'vue-cookies';
export default {
  name: 'Login',
  data: function() {
    return {
      home: 'Home',
      manualUrl: process.env.VUE_APP_BASE_URL + '/m/manual/전자계약시스템 매뉴얼(OSE내부사용자).pdf',
      example: 'Example',
      menuList: [],
      loading: false,
      modalBtns: [{name: '확인', callback: this.onJoinConfirm}],
      modalBtns2: [{name: '확인', callback: this.onSaveCompany}],
    };
  },
  mounted() {
    this.checkAccessDeviceType();

    var servername = '';
    if (location.host.indexOf('econtracttest') >= 0) {
      servername = 'OSE 개 발 서 버 내부';
    }
    // eslint-disable-next-line
    else if (location.host.indexOf('econtract') >= 0) {
    } else {
      servername = 'OSE LOCAL 내부';
    }
    if (servername != '') {
      this.$refs.server.innerHTML = servername;
      this.$refs.server.style.display = 'block';
    }

    var saveId = VueCookies.get('saveId');
    if (saveId !== undefined && saveId != null && saveId != '') {
      document.getElementsByName('id')[0].value = saveId;
      document.getElementById('c1').checked = true;
    }
  },
  // components:{Datepicker, Timepicker, DatetimePicker},
  methods: {
    ...mapMutations('login', ['setPage']),
    ...mapActions('login', ['doLogin']),
    async loginOnEnter($event) {
      if ($event.keyCode == 13) {
        $event.preventDefault();
        this.login();
        return false;
      }
    },
    async login() {
      // const formData = this.getFormData();
      var objid = document.getElementsByName('id')[0];
      objid.value = $.trim(objid.value);
      const pgmCd = document.getElementsByName('pgmCd')[0].value;
      const id = objid.value;
      const password = document.getElementsByName('password')[0].value;

      if (objid.value.length != 6) {
        alert('사번 6자리를 입력해주세요');
        return;
      }
      if (password.length < 1) {
        alert('비밀번호를 입력하세요');
        return;
      }

      const formData = {
        pgmCd: pgmCd,
        id: id,
        password: password,
        page: this.home,
      };

      this.loading = true;

      await this.doLogin(formData);

      this.loading = false;
    },
    onJoinStep() {
      this.setPage('joinStep');
    },
    async checkAccessDeviceType() {
      const me = this;
      const response = await axios.get('/gw/getDeviceType').catch(function(error) {
        console.log('error', error);
        me.setPage('NetworkErr');
        return;
      });
      console.log(response.data);
      if (response.data != 'PC') {
        const response2 = await axios.get('/gw/getUrlByDeviceType');
        console.log(response2.data);
        window.document.location.href = response2.data;
      }
      console.log('isTouchDevice = ' + this.isTouchDevice());
    },
    isTouchDevice() {
      if ('ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch)) {
        return true;
      }
      if (navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
        return true;
      }
      const prefixes = ['', '-webkit-', '-moz-', '-o-', '-ms-', ''];
      const mq = query => window.matchMedia(query).matches;
      return mq(['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(''));
    },
  },
  watch: {},
};
</script>

<style scoped>
.joinStep {
  position: relative;
  left: 480px;
  width: 100px;
  height: 50px;
  background-color: darkviolet;
  color: antiquewhite;
  font-weight: bold;
}
</style>
