window.popupInterface = {};
window.$open = params => {
  const opt = {
    path: params.path || 'index.html?Popup',
    componentPath:
      params.componentPath.indexOf('@') == 0 ? params.componentPath.replace('@', '.') : params.componentPath,
    param: params.param,
    btns: params.btns || [],
    name: params.name || 'popup',
    option: params.option || 'resizable,scrollbars, width=800, height=600',
    title: params.title || '',
  };

  // console.log("######################");
  // console.log("popup.js", params.param);
  // console.log("######################");

  const _window = window.open(opt.path, opt.name, opt.option);
  const timer = setInterval(function() {
    if (_window !== null && _window.closed) {
      clearInterval(timer);
      delete window.popupInterface[opt.name];
    }
  }, 1000);

  popupInterface[opt.name] = opt;

  return {...opt, window: _window};
};
