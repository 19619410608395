<template>
  <pmis-page ref="page" :search-project-visible="false">
    <iui-container type="css-flex">
      <i-spacer />
      <span style="color:red">
        ※ [계약서 정보]탭에서 신규 저장 후 [계약서 변경이력 정보]탭에서 상세정보를 입력하세요.</span
      >
      <i-spacer />
      <i-row>
        <i-col width="550px">
          <pmis-tab-box>
            <template v-slot:title>계약서 정보</template>
            <template v-slot:title-right>
              <iui-button value="신규" @click="onAdd('main')" />
              <iui-button value="저장" @click="onSave('main')" />
              <iui-button value="삭제" @click="onDelete('main')" />
            </template>
            <reportMain @emitReportMain="getReportMain"/>
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>계약서 변경이력 정보</template>
            <template v-slot:title-right>
              <iui-button value="신규" @click="onAdd('hist')" />
              <iui-button value="저장" @click="onSave('hist')" />
              <iui-button value="삭제" @click="onDelete('hist')" />
            </template>
            <reportHist />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
import reportMain from './components/ReportMain.vue';
import reportHist from './components/ReportHist.vue';
import store from './store/ReportMain.js';
export default {
  components: {reportMain, reportHist},
  async mounted() {
    this.callEvent({name: 'main_search'});
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['reprotMain']);
    $mapMutations(this, ['setReportMain']);
  },
  created() {
    this.setCmmBtns(this.cmmBtns);
  },
  data() {
    return {
      cmmBtns: [
        {name: '조회', class: 's', callback: this.onSearch},
        // {'name':'신규', class:'b', callback: this.onAdd},
        // {'name':'저장', class:'b', callback: this.onSave},
        // {'name':'삭제', class:'b', callback: this.onDelete},
        {name: '출력', class: 'p', callback: this.onPrint},
      ],
    };
  },
  methods: {
    onSearch() {
      this.callEvent({name: 'main_search'});
      this.callEvent({name: 'hist_search', param: { manageCd: ''}});
    },
    onAdd(type) {
      this.callEvent({name: type == 'main' ? 'main_add' : 'hist_add'});
    },
    onSave(type) {
      this.callEvent({name: type == 'main' ? 'main_save' : 'hist_save'});
    },
    onDelete(type) {
      this.callEvent({name: type == 'main' ? 'main_delete' : 'hist_delete'});
    },
    onPrint() {},
    getReportMain(row) {
      console.log('#############emit getReportMain >>>>>>>>>>');
      this.setReportMain(row);
      this.callEvent({name: 'hist_search', param: {}});
    },
  },
};

//jqGrid resize 
//$(window).on('resize.jqGrid', function () {
//    jQuery("#jqgrid_ID").jqGrid( 'setGridWidth', $("#content").width() );
//});

</script>

<style scoped></style>