<template>
  <pmis-content-box > 
    <template v-slot:header-left >
      <iui-text
        type="text"
        name="cmnDNm"
        label="코드명"
        :value="searchInfo.cmnDNm"
        @change="onChange"
        @enter="onSearch"
      ></iui-text>
    </template>
    <template v-slot:header-right>
      <iui-button value="검색" class="btn" @click="onBeforeSearch" />
    </template>
    <iui-container type="jqgrid" :id="sheetId" style="width:99.9%" />
  </pmis-content-box>
</template>

<script>
export default {
  beforeCreate() {
    $mapGetters(this, ['upperDetailInfo']);
  },
  created() {
    this.addEvent([
      {name: 'lowerCode_search', func: this.onSearch},
      {name: 'lowerCode_add', func: this.onAdd},
      {name: 'lowerCode_test', func: this.onTest},
      {name: 'lowerCode_save', func: this.onSave},
      {name: 'lowerCode_delete', func: this.onDelete},
    ]);
  },
  data() {
    return {
      sheetId: 'sheet' + this.$store.getters['mdi/currentUrls'].menuId,
      searchInfo: {cmnDNm: '', pgmCd:"", cmnUCd:""},
    };
  },
  async mounted() {
    var editoptionsYN = { value:"Y:사용;N:미사용" };
    // eslint-disable-next-line
    var formatterYN = function(v) { return v=='Y'?'사용':'미사용'; };
    const OPT = {
      Cols: [
        {
            label: 'No',
            name: 'no',
            editable: false,
            align: 'center',
            width: 50,
        },
        {
          label: '코드',
          name: 'cmnDCd',
          edittype: 'text',
          align: 'center',
          editable:true,
          width: 100,
        },
        {
          label: '코드명',
          name: 'cmnDNm',
          edittype: 'text',
          align: 'left',
          editable: true,
          width: 150,
        },
        {
          label: '사용여부',
          name: 'useDs',
          edittype: 'select',
          align: 'center',
          editable: true,
          width: 100,
					editoptions: editoptionsYN,
					formatter: 'select',
        },
        {
          label: '순서',
          name: 'cdSqn',
          type: 'text',
          align: 'center',
          width: 50,
          editable: true,
        },
        {
          label: '코드설명',
          name: 'rmk',
          type: 'text',
          align: 'left',
          width: 150,
          editable: true,
        },
        {
          label: '기타1',
          name: 'etc1',
          type: 'text',
          align: 'left',
          width: 100,
          editable: true,
        },
        {
          label: '기타2',
          name: 'etc2',
          type: 'text',
          align: 'left',
          width: 100,
          editable: true,
        },
        {
          label: '기타3',
          name: 'etc3',
          type: 'text',
          align: 'left',
          width: 100,
          editable: true,
        },
        {
          label: '기타4',
          name: 'etc4',
          type: 'text',
          align: 'left',
          width: 100,
          editable: true,
        },
        {
          label: '기타5',
          name: 'etc5',
          type: 'text',
          align: 'left',
          width: 100,
          editable: true,
        },
        {name: 'cmnUCd', hidden: true},
        {name: 'pgmCd', hidden: true},
        {name: 'cud', hidden: true}
      ],
      Events: {
        onDataLoad: this.grid_onDataLoad,
      },
    };

      var me = this;
      var $grid = $("#"+me.sheetId);
      this.sheet = $grid;

      $grid.jqGrid({
				height:200,
				width:200,
				autowidth:true,
				shrinkToFit:true,
				selectionMode : 'singlerow', 
				colModel: OPT.Cols,
				onSelectRow: function(rowid) {
          var $grid = $("#"+me.sheetId);
          var data = $grid.getRowData(rowid);
          if(rowid && rowid!==me.lastRowId && me.lastRowId) {
            $grid.saveRow(me.lastRowId);
            if(JSON.stringify($grid.getRowData(me.lastRowId))!=me.lastRowData ) {
              $grid.setCell(me.lastRowId, "cmnDCd",  me.nvl($grid.getCell(me.lastRowId,"cmnDCd")).toUpperCase(), false, false, true);
              $grid.setCell(me.lastRowId, "cdSqn",  me.nvl($grid.getCell(me.lastRowId,"cdSqn")).replace(/[^0-9]+/,""), false, false, true);
              if(data.cud!="C" && data.cud!="U") {
                $grid.setCell(me.lastRowId, "cud", "U");
              }
            }
            $grid.restoreRow(me.lastRowId);
          }
          $grid.setColProp("cmnDCd", { editable:data.cud=="C" });
          $grid.editRow(rowid,true);
          me.lastRowId = rowid;
          me.lastRowData = JSON.stringify($grid.getRowData(me.lastRowId));
				}
			});
      $gridAutoResize( $grid );

  },
  methods: {
    onBeforeSearch() {
      this.onSearch();
    },
    async onSearch(prm) {
			const me = this;
			var $grid = $("#"+me.sheetId);

      if(prm) {
        this.searchInfo.pgmCd = prm.pgmCd;
        this.searchInfo.cmnUCd = prm.cmnUCd;
      }


      let param = {  
          pgmCd: this.userInfo.pgmCd,
          ...this.searchInfo};
      $grid.jqGrid( 'clearGridData' );
      let response = await axios.post('/baseCmmnCodeRegist/selectCodeList', param);

      var data = response.data;
      for(var i=0;i<data.length;i++) {
        data[i].no = (i+1);
        data[i].pgmCd =  this.userInfo.pgmCd;
      }

      me.deletedRows = [];

      $grid.jqGrid( 'setGridParam', { data:data }).trigger('reloadGrid');
    },
    onTest() {
      var me = this;
      var $grid = $("#"+me.sheetId);

      var ids = $grid.getDataIDs();

      var datas = [];
      for(var i=0;i<ids.length;i++) {
        datas.push( $grid.getRowData(ids[i]));
      }
      console.log(JSON.stringify(datas));

    },
    onAdd() {
      var me = this;
      var $grid = $("#"+me.sheetId);

      if (!this.upperDetailInfo.cmnDCd || this.upperDetailInfo.cmnDCd == '') {
        this.$alert('상위코드를 먼저 등록하고 하위코드를 입력해야합니다.');
        return;
      } else {
        var ids = $grid.getDataIDs();
        if(!ids) ids=[];

        var nextId = ids.length+1;

        var rowdata = {
          "cud" : "C",
          "cmnUCd": this.upperDetailInfo.cmnDCd,
          "cmnDCd": "",
          "useDs" : "Y",
          "pgmCd" : this.userInfo.pgmCd,
          "cdSqn" : nextId
        }

        $grid.addRowData( nextId, rowdata, "last" );

      }
    },
    async onSave() {
      var me = this;
      var $grid = $("#"+me.sheetId);

      if(me.lastRowId) {
        var data = $grid.getRowData(me.lastRowId);
        $grid.saveRow(me.lastRowId);
        if(JSON.stringify($grid.getRowData(me.lastRowId))!=me.lastRowData ) {
          $grid.setCell(me.lastRowId, "cmnDCd",  me.nvl($grid.getCell(me.lastRowId,"cmnDCd")).toUpperCase(), false, false, true);
          $grid.setCell(me.lastRowId, "cdSqn",  me.nvl($grid.getCell(me.lastRowId,"cdSqn")).replace(/[^0-9]+/,""), false, false, true);
          if(data.cud!="C" && data.cud!="U") {
            $grid.setCell(me.lastRowId, "cud", "U" );
          }
        }
        $grid.restoreRow(me.lastRowId);
      }
      // 입력값 체크
      var ids = $grid.getDataIDs();
      var cmnDCds = {};
      var dupCds = "";
      for(var i=0;i<ids.length;i++) {
        var row = $grid.getRowData(ids[i]);
        var cmnDCd = row.cmnDCd;
        var cmnDNm = row.cmnDNm;
        var cdSqn = row.cdSqn;
        if(cmnDCd=='') {
          alert("코드를 입력하세요");
          $grid.setSelection(ids[i], true);
          return;
        }
        if(cmnDNm=='') {
          alert("코드명을 입력하세요");
          $grid.setColProp("cmnDCd", { editable:row.cud=="C" });
          $grid.setSelection(ids[i], true);
          return;
        }
        if(cdSqn=='') {
          alert("순서를 입력하세요");
          $grid.setColProp("cmnDCd", { editable:row.cud=="C" });
          $grid.setSelection(ids[i], true);
          return;
        }
        if(cmnDCds[cmnDCd]) {
          dupCds += ", "+cmnDCd;
        }
        cmnDCds[cmnDCd] = row;
      }
      if( dupCds!='') {
        alert("중복된 코드가 존재합니다 : "+dupCds.substring(2));
        return;
      }

      var ncnt = 0;
      var datas = [];
      for(i=0;i<ids.length;i++) {
        row = $grid.getRowData(ids[i]);
        if(row.cud=="C"||row.cud=="U") {
          datas.push( row );
          ncnt++;
        }
      }
      if(me.deletedRows) {
        for(i=0;i<me.deletedRows.length;i++) {
          datas.push(me.deletedRows[i]);
          ncnt++;
        }
      }
      if(ncnt==0) {
        this.$alert("저장할 내용이 없습니다");
        return;
      }

      if (await this.$confirm('저장하시겠습니까?')) {
        var response = await axios
          .post('/baseCmmnCodeRegist/save', datas)
          .catch(e => console.error('저장에 실패했습니다. ', e));
        if (response.status == 200) {
          me.deletedRows = null;
          this.onSearch();
        } else {
          this.$alert('저장에 실패하였습니다.');
        }
      }
    },
    onDelete() {
      var me = this;
      var $grid = $("#"+me.sheetId);
      if(!me.deletedRows) {
        me.deletedRows = [];
      }
      var rowid = $grid.getGridParam("selrow");
      if(rowid) {
        var row = $grid.getRowData(rowid);
        if(row.cud!="C") {
          row.cud = "D";
          me.deletedRows.push(row);
        }
        var rix = $('#' + $.jgrid.jqID(rowid))[0].rowIndex-1;
        $grid.delRowData(rowid);
        var rowids = $grid.getDataIDs();
        if(rix>=0 && rix<rowids.length) {
          $grid.setSelection(rowids[rix], true);
        }
      }
    },
    onChange(e) {
      this.searchInfo.cmnDNm = e.target.value;
    },
    nvl(v) {
      return !v?"":v.toString();
    }
  },
};
</script>

<style scoped></style>
