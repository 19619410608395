<template>
  <iui-container type="css-flex">
    <i-row>
      <i-col style="padding-right:10px;box-sizing:border-box">
        <iui-container type="jqgrid" :id="sheetId" style="width:99.9%;" />
      </i-col>
      <i-col>    
        <iui-container type="table" :headerWidth="width200">
          <i-row>
            <i-col-header>사업자(주민)번호/거래처번호/CP</i-col-header>
            <i-col>
              <iui-text :value="makeMask(memberInfo.businessNo)" :enable="false" />              
              <iui-text :value="cTradeNo" :enable="false" width="100px" />
              <iui-text :value="memberInfo.cpTradeNo" :enable="false" width="100px" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>상호(이름)</i-col-header>
            <i-col>
              <iui-text :value="memberInfo.tradeFullName" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>대표자</i-col-header>
            <i-col>
              <iui-text :value="memberInfo.repntName" :enable="false" width="100px" />
              <iui-button
                color="blue"
                v-show="isCanUrlCopy"
                v-clipboard:copy="memberInfo.shrtenUrl"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                >URL복사</iui-button
              >
              <iui-button color="blue" v-show="isCanResendNoti" @click="resendNoti">알림재송부</iui-button>
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>주소</i-col-header>
            <i-col>
              <iui-text :value="memberInfo.address" :enable="false" />
            </i-col>
          </i-row>
          <i-row v-if="isBuyagnt">
            <i-col-header>대리인</i-col-header>
            <i-col>
              <iui-text :value="memberInfo.buyagntNm" :enable="false" />
              <iui-text :value="memberInfo.buyagntMphone" :enable="false" />
              <iui-text :value="memberInfo.buyagntEmail" :enable="false" />
            </i-col>
          </i-row>
          <i-row v-else>
            <i-col-header>담당자</i-col-header>
            <i-col>
              <iui-text :value="memberInfo.mphone" :enable="false" />
              <iui-text :value="memberInfo.email" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>서명일시</i-col-header>
            <i-col>
              <iui-text v-if="isSign" :value="memberInfo.signDt" :enable="false" />
              <iui-text v-else value="미서명" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>방법 / IP</i-col-header>
            <i-col>
              <iui-text v-if="isSign" :value="memberInfo.signMthNm" :enable="false" />
              <iui-text v-if="isSign" :value="memberInfo.accesIp" :enable="false" />
              <iui-text v-else value="미서명" :enable="false" />
            </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
  </iui-container>
</template>
  
<script>
export default {
  components: {},
  beforeCreate() {
    $mapGetters(this, ['wrtcnsInfo', 'memberList', 'isEmp']);
  },
  created() {
    this.addEvent({name: 'SubcntrWrtcnsTabMembers_Load', func: this.onLoad});
  },
  data() {
    return {
      sheetId: 'sheet' + this._uid,
      listBoxLoading: false,

      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width170: '170px',
      width200: '200px',

      memberInfo: {
        wrtcnsNo : '', 
        ceckSn : '', 
        businessNo : '', 
        tradeNo : '', 
        tradeSfx : '', 
        cpTradeNo : '', 
        tradeFullName : '', 
        repntName : '', 
        address : '', 
        mphone : '', 
        email : '', 
        buyagntNm : '', 
        buyagntMphone : '', 
        buyagntEmail : '', 
        signDt : '', 
        signMthNm : '', 
        accesIp : '', 
        shrtenUrl : '', 
      },

      otisPage: false,
    };
  },
  mounted() {
    const me = this;
    $('#' + me.sheetId).jqGrid({
      height: 150,
      width: 800,
      colModel: [
        {name: 'wrtcnsNo', label: 'wrtcnsNo', width: 0, align: 'center', hidden: true},
        {name: 'tradeNo', label: '거래선번호', width: 0, align: 'center', hidden: true},
        {name: 'tradeSfx', label: '거래선SFX', width: 0, align: 'center', hidden: true},
        {name: 'tradeType', label: '구분코드', width: 0, align: 'center', hidden: true},
        {name: 'tradeTypeNm', label: '구분', width: 30, align: 'center'},
        {name: 'tradeFullName', label: '상호(법인)/<br/>이름(개인/개인사업자)', width: 80, align: 'left'},
        {name: 'repntName', label: '대표자', width: 70, align: 'center'},
        {name: 'buyagntNm', label: '대리인', width: 50, align: 'center'},
      ],
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
      // eslint-disable-next-line
      onCellSelect: function(rowId, iCol, cellContent, e) {
        var rowData = $('#' + me.sheetId).jqGrid('getRowData', rowId);
        me.onMemberInfo(rowData);
      },
    });
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.onLoad();
  },
  methods: {
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      var $grid = $('#' + this.sheetId);
      var pobj = $grid
        .closest('.ui-jqgrid')
        .parent()
        .parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth, true);
      var newHeight = pobj.height();
      $grid.jqGrid('setGridHeight', newHeight - 70, true);
    },
    onLoad(param) {
      if (param !== undefined) {
        this.otisPage = param.otisPage;
      }
      console.log('SubcntrWrtcnsTabMembers_Load', param);
      setTimeout(() => {
        this.bindData();
      }, 100);
    },
    bindData() {
      $('#' + this.sheetId)
        .clearGridData(true)
        .setGridParam({data: this.memberList})
        .trigger('reloadGrid');
      var ids = $('#' + this.sheetId).jqGrid('getDataIDs');
      if (ids && ids.length > 0) {
        $('#' + this.sheetId).jqGrid('setSelection', ids[0], true);
        var rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[0]);
        if (rowData.wrtcnsNo !== undefined) {
          this.onMemberInfo(rowData);
        }
      } else {
        this.memberInfo.wrtcnsNo = '';
        this.memberInfo.ceckSn = '';
        this.memberInfo.businessNo = '';
        this.memberInfo.tradeNo = '';
        this.memberInfo.tradeSfx = '';
        this.memberInfo.cpTradeNo = '';
        this.memberInfo.tradeFullName = '';
        this.memberInfo.repntName = '';
        this.memberInfo.address = '';
        this.memberInfo.mphone = '';
        this.memberInfo.email = '';
        this.memberInfo.buyagntNm = '';
        this.memberInfo.buyagntMphone = '';
        this.memberInfo.buyagntEmail = '';
        this.memberInfo.signDt = '';
        this.memberInfo.signMthNm = '';
        this.memberInfo.accesIp = '';
        this.memberInfo.shrtenUrl = '';
      }
    },
    async onMemberInfo(rowData) {
      let param = {
        wrtcnsNo: rowData.wrtcnsNo,
        tradeNo: rowData.tradeNo,
        tradeSfx: rowData.tradeSfx,
      };
      let response = await axios.post('/wrtcns/selectCustInfo', param);

      this.memberInfo.wrtcnsNo = response.data.custInfo.wrtcnsNo;
      this.memberInfo.ceckSn = response.data.custInfo.ceckSn;
      this.memberInfo.businessNo = response.data.custInfo.businessNo;
      this.memberInfo.tradeNo = response.data.custInfo.tradeNo;
      this.memberInfo.tradeSfx = response.data.custInfo.tradeSfx;
      this.memberInfo.cpTradeNo = response.data.custInfo.cpTradeNo;
      this.memberInfo.tradeFullName = response.data.custInfo.tradeFullName;
      this.memberInfo.repntName = response.data.custInfo.repntName;
      this.memberInfo.address = response.data.custInfo.address;
      this.memberInfo.mphone = response.data.custInfo.mphone;
      this.memberInfo.email = response.data.custInfo.email;
      this.memberInfo.buyagntNm = response.data.custInfo.buyagntNm;
      this.memberInfo.buyagntMphone = response.data.custInfo.buyagntMphone;
      this.memberInfo.buyagntEmail = response.data.custInfo.buyagntEmail;
      this.memberInfo.signDt = response.data.custInfo.signDt;
      this.memberInfo.signMthNm = response.data.custInfo.signMthNm;
      this.memberInfo.accesIp = response.data.custInfo.accesIp;
      this.memberInfo.shrtenUrl = response.data.custInfo.shrtenUrl;

      console.log('isAdmin', $isAdmin());
      console.log('this.isEmp', this.isEmp);
      console.log('this.otisPage', this.otisPage);
      console.log('this.shrtenUrl', (this.memberInfo.shrtenUrl != null));
      console.log('this.shrtenUrl', (this.memberInfo.shrtenUrl != ''));
      console.log('this.signDt', this.memberInfo.signDt);
      console.log('this.signDt', (!this.memberInfo.signDt));

    },
    onCopy() {
      this.$alert(
        '클립보드 복사에 성공하였습니다.<br/>브라우저에서 새로운 탭을 열고 주소창에 붙여넣기 하여 사용하세요.'
      );
    },
    onError(e) {
      this.$alert('클립보드 복사에 실패하였습니다.');
      console.log(e);
    },
    async resendNoti() {
      if (!(await this.$confirm(this.memberInfo.tradeFullName + '에게 알림재송부 하시겠습니까?'))) return;

      let param = {
        wrtcnsNo: this.memberInfo.wrtcnsNo,
        ceckSn: this.memberInfo.ceckSn,
        tradeNo: this.memberInfo.tradeNo,
        tradeSuffix: this.memberInfo.tradeSfx,
      };

      const me = this;
      me.listBoxLoading = true;
      axios
        .post('/wrtcns/resendNoti', param)
        .then(response => {
          me.listBoxLoading = false;
          console.log(response);
          this.onMemberInfo(this.memberInfo);
          me.$alert('알림재송부가 완료되었습니다.');
        })
        .catch(function(error) {
          me.listBoxLoading = false;
          me.$alert('알림재송부 중 오류가 발생하였습니다.');
          console.log('========error====================');
          console.log(error);
        });
    },
    makeMask(s) {
      if( s.match(/(?:[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1]))-[1-4]{1}[0-9]{6}\b/gi) ){
					return s.toString().replace(/(-?)([1-4]{1})([0-9]{6})\b/gi,"$1$2******");
			}else{
        return s;
      }
    }
  },
  computed: {
    isSign() {
      return this.memberInfo.signDt;
    },
    isBuyagnt() {
      return (
        this.memberInfo.buyagntNm !== undefined && this.memberInfo.buyagntNm != null && this.memberInfo.buyagntNm != ''
      );
    },
    isCanUrlCopy() {
      return (
        ($isAdmin() || this.isEmp) &&
        this.otisPage &&
        this.memberInfo.shrtenUrl != null &&
        this.memberInfo.shrtenUrl != ''
      );
    },
    isCanResendNoti() {
      return (
        ($isAdmin() || this.isEmp) &&
        this.otisPage &&
        this.memberInfo.shrtenUrl != null &&
        this.memberInfo.shrtenUrl != '' &&
        !this.memberInfo.signDt
      );
    },
    cTradeNo() {
      return this.memberInfo.tradeNo + '-' + this.memberInfo.tradeSfx;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style></style>
