<template>
  <iui-container type="css-flex" theme="bullet">
    <i-row height="70px" ref="srchBox">
      <i-col>
        <iui-container type="table" theme="bullet">
          <i-row>
            <i-col-header>현장명</i-col-header>
            <i-col>
              <iui-text name="contractName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>고객명</i-col-header>
            <i-col>
              <iui-text name="customerName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>CC/팀</i-col-header>
            <i-col>
              <iui-text name="ccName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>영업담당</i-col-header>
            <i-col>
              <iui-text name="empName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>계약일</i-col-header>
            <i-col
              ><span style="white-space:nowrap">
                <iui-datepicker-old
                  name="checkContDateStart"
                  suffix=" ~ "
                  @change="onSetValue($event, 'checkContDateStart')"
                  :value="search.checkContDateStart"/>
                &nbsp;<iui-datepicker-old
                  name="checkContDateEnd"
                  @change="onSetValue($event, 'checkContDateEnd')"
                  :value="search.checkContDateEnd"
              /></span>
            </i-col>
            <i-col-header>계약번호</i-col-header>
            <i-col>
              <iui-text name="ctrtNo" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>계약형태</i-col-header>
            <i-col colspan="3">
              <iui-select-old
                name="signType"
                :items="arrSignType"
                :value="search.signType"
                :enable="true"
                @change="onSetValue"
                required
              />
            </i-col>
            <!-- <i-col-header>진행상태</i-col-header>
            <i-col>
              <iui-select-old
                name="statusCode2"
                :items="arrStatusCode2"
                :value="search.statusCode2"
                :enable="true"
                @change="onSetValue"
                required
              />
            </i-col> -->
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <pmis-content-box no-border-box>
          <template v-slot:title>
            통합조회
          </template>
          <iui-container type="jqgrid" :id="sheetId" :loading="listBoxLoading" style="width:99.9%" />
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
export default {
  name: 't-contract-integration-list',
  beforeCreate() {
    $mapGetters(this, ['contractInfo']);
  },
  created() {
    this.addEvent({name: 'TContractIntegrationList_Search', func: this.onPreSearch});
  },
  data() {
    return {
      sheetId: 'sheet' + this.$store.getters['mdi/currentUrls'].menuId,

      arrDirectYn: [
        {text: '전체', value: ''},
        {text: '직영', value: 'D'},
        {text: '공동수급', value: 'C'},
      ],
      arrStatusCode: [{text: '전체', value: ''}],
      arrStatusCode2: [
        {text: '전체', value: ''},
        {text: '진행중', value: 'PRGS'},
        {text: '완료', value: 'CMPL'},
      ],
      arrSignType: [
        {text: '전체', value: ''},
        {text: '전자', value: 'ON'},
        {text: '서면', value: 'OFF'},
      ],
      search: {
        contractName: '',
        customerName: '',
        ccName: '',
        empName: '',
        checkContDateStart: '',
        checkContDateEnd: '',
        directYn: '',
        statusCode: '',
        // statusCode2: '',
        statusCode2: 'CMPL',
        signType: 'ON',
        ctrtNo: '',
      },

      listBoxLoading: false,

      focusKeys: {
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        docCat1: '',
        signType: '',
      },

      OZParam: {},
    };
  },
  mounted() {
    if (this.search.summaryYm === undefined || this.search.summaryYm == '') {
      var tempDate = $_getCurrentDate().substring(0, 6) + '26';
      this.search.checkContDateStart = $_addMonth(tempDate, -1);
      this.search.checkContDateEnd = $_addDay(tempDate, -1);
    }

    const OPT = {
      Cols: [
        // eslint-disable-next-line
        {
          name: '보기',
          label: '보기',
          width: 50,
          align: 'center',
          formatter: function(cellvalue, options, rowObject) {
            cellvalue = true;
            return cellvalue == true ? '<button>보기</button>' : '';
          },
          cellattr: $_rowMearge,
        },
        {name: 'docCat1Nm', label: '표준/비표준', width: 70, align: 'center', cellattr: $_rowMearge},
        {name: 'ctrtSttusNm', label: '계약상태', width: 80, align: 'center', cellattr: $_rowMearge},
        {name: 'signTypeNm', label: '계약형태', width: 60, align: 'center', cellattr: $_rowMearge},
        {name: 'offReasonType', label: '서면사유', width: 170, align: 'left', cellattr: $_rowMearge},
        {name: 'teamNoDesc', label: 'CCT', width: 50, align: 'center', cellattr: $_rowMearge},
        {name: 'srpType', label: '상품', width: 50, align: 'center'},
        {name: 'subcontractQhNm', label: '세부', width: 60, align: 'center'},
        {name: 'suriNo', label: '계약번호', width: 90, align: 'center'},
        {name: 'suriSeq', label: '차수', width: 50, align: 'center'},
        {name: 'tradeNo', label: '고객번호', width: 70, align: 'center', cellattr: $_rowMearge},
        {name: 'tradeName', label: '고객명', width: 170, align: 'left', cellattr: $_rowMearge},
        {name: 'buyagntYn', label: '대리인여부', width: 80, align: 'center', cellattr: $_rowMearge},
        // eslint-disable-next-line
        {
          name: 'buyAgntOneFileDown',
          label: '받기',
          width: 50,
          align: 'center',
          formatter: function(cellvalue, options, rowObject) {
            return cellvalue !== undefined && cellvalue != null && cellvalue != '' ? '<button>받기</button>' : '';
          },
        },
        // eslint-disable-next-line
        {
          name: 'buyAgntTwoFileDown',
          label: '받기',
          width: 50,
          align: 'center',
          formatter: function(cellvalue, options, rowObject) {
            return cellvalue !== undefined && cellvalue != null && cellvalue != '' ? '<button>받기</button>' : '';
          },
        },
        {name: 'mainNo', label: '관리번호', width: 70, align: 'center', cellattr: $_rowMearge},
        {name: 'mainName', label: '현장명', width: 200, align: 'left', cellattr: $_rowMearge},
        {
          name: 'contDate',
          label: '계약일',
          width: 70,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {
          name: 'signDt',
          label: '체결완료일',
          width: 70,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {
          name: 'contDate',
          label: '계약시작일',
          width: 70,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {
          name: 'buildEndDate',
          label: '준공일',
          width: 70,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {
          name: 'biddingAmt',
          label: '계약금액<br/>(VAT별도)',
          width: 80,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
          cellattr: $_rowMearge,
        },
        {name: 'contEmpNm', label: '계약담당자', width: 70, align: 'center', cellattr: $_rowMearge},
        {name: 'ctrtNo', label: 'ctrtNo', width: 0, hidden: true},
        {name: 'ctrtOdr', label: 'ctrtOdr', width: 0, hidden: true},
        {name: 'ctrtDivCd', label: 'ctrtDivCd', width: 0, hidden: true},
        {name: 'ctrtSttusCd', label: 'ctrtSttusCd', width: 0, hidden: true},
        {name: 'tradeSfx', label: 'tradeSfx', width: 0, hidden: true},
        {name: 'docCat1', label: 'docCat1', width: 0, hidden: true},
        {name: 'signType', label: 'signType', width: 0, hidden: true},
        {name: 'contEmpId', label: 'contEmpId', width: 0, hidden: true},
        {name: 'fileName', label: 'fileName', width: 0, hidden: true},
        {name: 'buyAgntOneFileName', label: 'buyAgntOneFileName', width: 0, hidden: true},
        {name: 'buyAgntTwoFileName', label: 'buyAgntTwoFileName', width: 0, hidden: true},
        {name: 'subcontractQh', label: '세부', width: 0, hidden: true},
      ],
    };

    const me = this;
    var $grid = $('#' + me.sheetId);
    $grid.jqGrid({
      // height:500,
      width: 1135,
      colModel: OPT.Cols,
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
      autowidth: false,
      shrinkToFit: false,
      // eslint-disable-next-line
      onCellSelect: function(rowId, iCol, cellContent, e) {
        var rowData = $('#' + me.sheetId).jqGrid('getRowData', rowId);
        if (me.focusKeys.ctrtNo!=rowData.ctrtNo 
            || me.focusKeys.ctrtOdr!=rowData.ctrtOdr 
            || me.focusKeys.ctrtDivCd!=rowData.ctrtDivCd) {
          // 20211101 lgksja 보기일경우는 팝업만 열리게
          me.$emit('click', rowData);
        }

        me.focusKeys.ctrtNo = rowData.ctrtNo;
        me.focusKeys.ctrtOdr = rowData.ctrtOdr;
        me.focusKeys.ctrtDivCd = rowData.ctrtDivCd;
        me.focusKeys.docCat1 = rowData.docCat1;
        me.focusKeys.signType = rowData.signType;

        if (iCol == $('#' + me.sheetId).jqGrid('getGridParam').iColByName['보기']) {
          if (rowData.signType == 'ON') {
            me.onView(rowData);
          } else {
            me.onDownloadOfflinePdf(rowData);
          }
        } else if (iCol == $('#' + me.sheetId).jqGrid('getGridParam').iColByName['buyAgntOneFileDown']) {
          me.onDownloadBuyAgntProofDoc(rowData, rowData.buyAgntOneFileName, 'A');
        } else if (iCol == $('#' + me.sheetId).jqGrid('getGridParam').iColByName['buyAgntTwoFileDown']) {
          me.onDownloadBuyAgntProofDoc(rowData, rowData.buyAgntTwoFileName, 'B');
        }
      },
    });

    var groups = [];
    groups.push({startColumnName: 'srpType', numberOfColumns: 2, titleText: '계약유형'});
    groups.push({startColumnName: 'suriNo', numberOfColumns: 2, titleText: '계약번호'});
    groups.push({startColumnName: 'buyAgntOneFileDown', numberOfColumns: 2, titleText: '대리인 증명서'});

    $grid.jqGrid('setGroupHeaders', {
      useColSpanStyle: true,
      groupHeaders: groups,
    });

    $gridAutoResize($grid);
    window.addEventListener('resize', this.onResize);
    setTimeout(() => {
      this.onResize();
    }, 1000);

    // 20210407 검색란 보이기/감추기
    $initSrchShowButton({
      srchBox: this.$refs.srchBox,
      srchList: this.$parent.$parent,
    });
  },
  methods: {
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      var $grid = $('#' + this.sheetId);
      var pobj = $grid
        .closest('.ui-jqgrid')
        .parent()
        .parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth - 2, false);
      var newHeight = pobj.height();
      $grid.jqGrid('setGridHeight', newHeight - 80, false);
    },
    onSetValue(e, t) {
      let target = typeof e == 'string' ? t : e.target.name;
      let value = typeof e == 'string' ? e : e.target.value;
      this.search[target] = value;
    },
    onPreSearch() {
      this.focusKeys.ctrtNo = '';
      this.focusKeys.ctrtOdr = '';
      this.focusKeys.ctrtDivCd = '';
      this.focusKeys.docCat1 = '';
      this.focusKeys.signType = '';
      this.onSearch();
    },
    async onSearch() {
      this.listBoxLoading = true;

      let param = {
        searchGubun1: this.search.searchGubun1,
        searchText1: this.search.searchText1,
        contractName: this.search.contractName,
        customerName: this.search.customerName,
        ccName: this.search.ccName,
        empName: this.search.empName,
        checkContDateStart: this.search.checkContDateStart,
        checkContDateEnd: this.search.checkContDateEnd,
        directYn: this.search.directYn,
        statusCode: this.search.statusCode,
        statusCode2: this.search.statusCode2,
        signType: this.search.signType,
        ctrtNo: this.search.ctrtNo,
      };

      let response = null;
      try {
        response = await axios.post('/tcontract/selectContractIntegrationList', param);
        this.listBoxLoading = false;
        $('#' + this.sheetId)
          .clearGridData(true)
          .setGridParam({data: response.data})
          .trigger('reloadGrid');

        var rowData;
        var ids = $('#' + this.sheetId).jqGrid('getDataIDs');
        if (ids && ids.length > 0) {
          if (this.focusKeys.ctrtNo && this.focusKeys.ctrtOdr && this.focusKeys.ctrtDivCd) {
            for (var i = 0; i <= ids.length - 1; i++) {
              rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[i]);

              if (
                rowData['ctrtNo'] == this.focusKeys.ctrtNo &&
                rowData['ctrtOdr'] == this.focusKeys.ctrtOdr &&
                rowData['ctrtDivCd'] == this.focusKeys.ctrtDivCd
              ) {
                $('#' + this.sheetId).jqGrid('setSelection', ids[i], true);
                this.$emit('click', rowData);
                break;
              }
            }
          } else {
            $('#' + this.sheetId).jqGrid('setSelection', ids[0], true);
            rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[0]);
            this.$emit('click', rowData);
          }
        } else {
          this.$emit('resetValues', null);
        }
      } catch (ex) {
        console.log(ex);
        this.listBoxLoading = false;
      }
    },
    async onView(data) {
      console.log('onView');
      console.log('ctrtNo', data.ctrtNo);
      console.log('ctrtOdr', data.ctrtOdr);
      console.log('ctrtDivCd', data.ctrtDivCd);
      console.log('docCat1', data.docCat1);

      var signable = 'N';
      var signerSeCd = '10';
      var tradeNo = '';

      console.log('signable', signable);
      console.log('signerSeCd', signerSeCd);
      console.log('tradeNo', tradeNo);

      //계약서 조회 후 오즈뷰어 팝업시킴.
      let param = {
        checkContNo: data.ctrtNo,
        checkSeq: data.ctrtOdr,
        manageCd: data.docCat1,
      };

      // eslint-disable-next-line
      var opt = {};
      const btns = [];
      //20221123 ITSR62079
      var opt_param = {
                    ctrtNo: data.ctrtNo,
                    ctrtOdr: data.ctrtOdr,
                    ctrtDivCd: data.ctrtDivCd,
                    docCat1: data.docCat1,
                    ctrtSttusCd: data.ctrtSttusCd,
                  }; 

      if (data.ctrtOdr == '00') {
        if (data.docCat1 == '000') {
          // eslint-disable-next-line
          opt = $open({
            componentPath: '@/view/contract/tContract/components/popup/ContractViewer.vue', // 컴포넌트 경로 (필수)
            param: {
              ...opt_param,
              OZParam: {},
              otisPage: true,
            }, // 파라미터 (생략가능)
            btns: btns, // 팝업 버튼 (생략가능)
            name: 'TContractViewer', // 팝업 윈도우 이름 (생략가능)
            option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
            title: '계약서조회', // 팝업 제목 (생략가능)
          });
        } else {
          var uri = '/ozrpt/selectOzTContract';
          // 발주서 리포트 코드 값이 바뀌면 수정해야 함.
          if (data.docCat1 == 'C20' || data.docCat1 == 'C50' || data.docCat1 == 'C80') {
            uri = '/ozrpt/selectOzTCtrtOrder';
          }
          axios
            .post(uri, param)
            .then(response => {
              // console.log('조회결과 response >>>', response);
              console.log('조회결과 response.data >>>', response.data);

              this.OZParam = Object.assign({}, response.data.jsonData);

              this.OZParam.reportname = response.data.reportPathName;
              this.OZParam.OZFormParam = {
                signable: signable, // 필수(*):디지털 서명가능여부(Y/N)
                signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
                tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선번호(로그인한사용자)
              };

              // eslint-disable-next-line
              opt = $open({
                componentPath: '@/view/contract/tContract/components/popup/ContractViewer.vue', // 컴포넌트 경로 (필수)
                param: {
                  ...opt_param,
                  OZParam: this.OZParam,
                  otisPage: true,
                }, // 파라미터 (생략가능)
                btns: btns, // 팝업 버튼 (생략가능)
                name: 'TContractViewer', // 팝업 윈도우 이름 (생략가능)
                option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
                title: '계약서조회', // 팝업 제목 (생략가능)
              });
            })
            .catch(function(error) {
              console.log('========error====================');
              console.log(error);
            });
        }
      } else {
        if (data.docCat1 == '000') {
          // eslint-disable-next-line
          opt = $open({
            componentPath: '@/view/contract/tContract/components/popup/ChangeContractViewer.vue', // 컴포넌트 경로 (필수)
            param: {
              ...opt_param,
              OZParam: {},
              otisPage: true,
            }, // 파라미터 (생략가능)
            btns: btns, // 팝업 버튼 (생략가능)
            name: 'ChangeContractViewer', // 팝업 윈도우 이름 (생략가능)
            option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
            title: '변경합의서조회', // 팝업 제목 (생략가능)
          });
        } else {
          axios
            .post('/ozrpt/selectOzTCtrtChange', param)
            .then(response => {
              // console.log('조회결과 response >>>', response);
              // console.log('조회결과 response.data >>>', response.data);

              this.OZParam = Object.assign({}, response.data.jsonData);

              this.OZParam.reportname = response.data.reportPathName;
              this.OZParam.OZFormParam = {
                signable: signable, // 필수(*):디지털 서명가능여부(Y/N)
                signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
                tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선코드(로그인한사용자)
              };

              // eslint-disable-next-line
              opt = $open({
                componentPath: '@/view/contract/tContract/components/popup/ChangeContractViewer.vue', // 컴포넌트 경로 (필수)
                param: {
                  ...opt_param,
                  OZParam: this.OZParam,
                  otisPage: true,
                }, // 파라미터 (생략가능)
                btns: btns, // 팝업 버튼 (생략가능)
                name: 'ChangeContractViewer', // 팝업 윈도우 이름 (생략가능)
                option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
                title: '변경합의서조회', // 팝업 제목 (생략가능)
              });
            })
            .catch(function(error) {
              console.log('========error====================');
              console.log(error);
            });
        }
      }
    },
    async onDownloadOfflinePdf(data) {
      var fileName = data.fileName;
      var url = '/tcontract/downloadOfflinePdf?suriNo=' + data.ctrtNo + '&suriSeq=' + data.ctrtOdr;
      const response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // important
      });

      if (response.data.size > 0) {
        const downUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downUrl;
        if (typeof window.navigator.msSaveBlob === 'function') {
          window.navigator.msSaveBlob(response.data, fileName);
        } else {
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      } else {
        this.$alert('서면으로 체결 된 계약서는 OKSS에서 등록해야 다운로드 받을 수 있습니다.');
      }
    },
    async onDownloadBuyAgntProofDoc(data, fileName, tradeOdr) {
      if (fileName !== undefined && fileName != null && fileName != '') {
        var url =
          '/tcontract/downloadBuyAgntProofDoc?suriNo=' +
          data.ctrtNo +
          '&suriSeq=' +
          data.ctrtOdr +
          '&tradeOdr=' +
          tradeOdr;
        const response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
        });

        if (response.data.size > 0) {
          const downUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = downUrl;
          if (typeof window.navigator.msSaveBlob === 'function') {
            window.navigator.msSaveBlob(response.data, fileName);
          } else {
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        } else {
          this.$alert('대리인 증명서 파일을 OKSS에서 등록해야 다운로드 받을 수 있습니다.');
        }
      }
    },
  },
  computed: {},
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style></style>
