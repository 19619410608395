<template>
  <pmis-page :search-company-visible="false" :search-project-visible="false">
    <iui-container type="css-flex">
      <i-row ref="srchList">
        <i-col>
          <iui-container type="css-flex" theme="bullet">
            <i-row height="70px" ref="srchBox">
              <i-col>
                <iui-container type="table" theme="bullet">
                  <i-row>
                    <i-col-header>현장명</i-col-header>
                    <i-col>
                      <iui-text name="mainName" @change="onSetValue" @enter="onPreSearch" />
                    </i-col>
                    <i-col-header>공사명</i-col-header>
                    <i-col>
                      <iui-text name="suriName" @change="onSetValue" @enter="onPreSearch" />
                    </i-col>
                    <i-col-header>고객명</i-col-header>
                    <i-col>
                      <iui-text name="customerName" @change="onSetValue" @enter="onPreSearch" />
                    </i-col>
                    <i-col-header>기안담당자</i-col-header>
                    <i-col>
                      <iui-text name="empName" @change="onSetValue" @enter="onPreSearch" />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>기안일</i-col-header>
                    <i-col
                      ><span style="white-space:nowrap">
                        <iui-datepicker-old
                          name="dateStart"
                          suffix="~"
                          @change="onSetValue($event, 'dateStart')"
                          :value="search.dateStart"/>
                        &nbsp;<iui-datepicker-old
                          name="dateEnd"
                          @change="onSetValue($event, 'dateEnd')"
                          :value="search.dateEnd"
                      /></span>
                    </i-col>
                    <i-col-header>기안번호</i-col-header>
                    <i-col>
                      <iui-text name="wrtcnsNo" @change="onSetValue" @enter="onPreSearch" />
                    </i-col>
                    <i-col-header>계약번호</i-col-header>
                    <i-col>
                      <iui-text name="ctrtNo" @change="onSetValue" @enter="onPreSearch" />
                    </i-col>
                    <i-col-header>계약형태</i-col-header>
                    <i-col>
                      <iui-select-old
                        name="signType"
                        :items="arrSignType"
                        :value="search.signType"
                        :enable="true"
                        @change="onSetValue"
                        required
                      />
                    </i-col>
                  </i-row>
                </iui-container>
              </i-col>
            </i-row>
            <i-row>
              <i-col>
                <pmis-content-box no-border-box>
                  <template v-slot:title>
                    하도급동의서 통합 목록
                  </template>
                  <iui-container type="jqgrid" :id="sheetId" :loading="listBoxLoading" style="width:99.9%" />
                </pmis-content-box>
              </i-col>
            </i-row>
          </iui-container>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>
  
<script>
  export default {
    beforeCreate() {
      $mapGetters(this, ['wrtcnsInfo', 'isEmp']);
    },
    created() {
      this.addEvent({name: 'SubcntrWrtcnsStatistics_Search', func: this.onPreSearch});
      this.setCmmBtns(this.cmmBtns);
    },
    data() {
      return {
        cmmBtns: [
          {name: '조회', class: 's', callback: this.onSearch},
          {name: '출력', class: 'p', callback: this.onPrint},
        ],
        //sheetId: 'sheet',// + this._uid,
        sheetId: 'sheet' + this.$store.getters['mdi/currentUrls'].menuId,
        arrSignType: [
          {text: '전체', value: ''},
          {text: '전자', value: 'ON'},
          {text: '서면', value: 'OFF'},
        ],
        search: {
          ctrtNo: '',
          tradeNo: '',
          mainName: '',
          suriName: '',
          customerName: '',
          empId: '',
          empName: '',
          dateStart: '',
          dateEnd: '',
          statusCode: '',
          teamTreeEmpNo: '',
          wrtcnsNo: '',
          signType: '',
        },
  
        listBoxLoading: false,
  
        focusKeys: {
          wrtcnsNo: '',
          ceckSn: '',
          docCat1: '',
          signType: '',
          wrtcnsSttusCd: '',
          empId: '',
        },
  
        modalOZViewerBtns: [],
        OZParam: {},
      };
    },
    mounted() {

      this.search.dateStart = $_addMonth($_getCurrentDate(), -1);
      this.search.dateEnd = $_getCurrentDate();

      // 개발용
      if (process.env.NODE_ENV == 'development') {
        this.search.dateStart = '20241001';
        this.search.dateEnd = '20241030';
      }

      const OPT = {
        Cols: [          
          // eslint-disable-next-line
          {
            name: '계약서',
            label: '계약서',
            width: 50,
            align: 'center',
            formatter: function(cellvalue, options, rowObject) {
              var returnStr = "";
              if (rowObject.signType == 'ON') {
                returnStr = '<button style="border:1px solid #668CF3;background-color:#668CF3">전자</button>';
              } else if (rowObject.signType == 'OFF' && rowObject.fileName != '') {
                returnStr = '<button style="border:1px solid #19B70A;background-color:#19B70A">서면</button>';
              } else {
                returnStr = '<button style="border:1px solid #808080;background-color:#808080" title="없음">없음</button>';
              }
              return returnStr;
            },
            cellattr: $_rowMearge,
          }, // 계약서
          {name: 'signTypeNm', label: '계약형태', width: 60, align: 'center', cellattr: $_rowMearge},
          // {name: 'offReasonType', label: '서면사유', width: 170, align: 'left', cellattr: $_rowMearge},
          {name: 'teamNoDesc', label: '부서', width: 100, align: 'left', cellattr: $_rowMearge}, // 부서
          {name: 'wrtcnsNo', label: '기안번호', width: 90, align: 'center', cellattr: $_rowMearge}, // 기안번호
          {name: 'startDate', label: '기안일', width: 80, align: 'center', cellattr: $_rowMearge}, // 기안일
          {name: 'checkContTypeA3', label: '계약형태', width: 60, align: 'center', cellattr: $_rowMearge}, // 계약형태
          {name: 'checkContNo', label: '계약번호', width: 80, align: 'center', cellattr: $_rowMearge}, // 계약번호
          {name: 'contStartDate', label: '계약시작일', width: 80, align: 'center', cellattr: $_rowMearge}, // 계약시작일
          {name: 'contEndDate', label: '계약종료일', width: 80, align: 'center', cellattr: $_rowMearge}, // 계약종료일
          {name: 'cpTradeNo', label: '고객번호', width: 70, align: 'center', cellattr: $_rowMearge}, // 고객번호
          {name: 'tradeNm', label: '고객명', width: 140, align: 'left', cellattr: $_rowMearge}, // 고객명
          {name: 'buyagntYn', label: '대리인여부', width: 80, align: 'center', cellattr: $_rowMearge},
          {
            name: 'buyAgntProofFileDown',
            label: '증명서',
            width: 50,
            align: 'center',
            formatter: function(cellvalue, options, rowObject) {
              var returnStr = "";
              if (cellvalue !== undefined && cellvalue != null && cellvalue != '') {
                returnStr = '<button style="border:1px solid #668CF3;background-color:#668CF3">받기</button>';
              } else {
                returnStr = '<button style="border:1px solid #808080;background-color:#808080" title="없음">없음</button>';
              }
              return returnStr;
            },
          },
          {name: 'mainNo', label: '관리번호', width: 70, align: 'center', cellattr: $_rowMearge}, // 관리번호
          {name: 'mainName', label: '현장명', width: 140, align: 'left', cellattr: $_rowMearge}, // 현장명
          {name: 'suriName', label: '공사명', width: 140, align: 'left', cellattr: $_rowMearge}, // 공사명
          {name: 'ceckSn', label: 'ceckSn', width: 0, hidden: true},
          {name: 'ctrtDivCd', label: 'docCat1', width: 0, hidden: true},
          {name: 'ctrtSttusCd', label: 'docCat1', width: 0, hidden: true},
          {name: 'docCat1', label: 'docCat1', width: 0, hidden: true},
          {name: 'signType', label: 'signType', width: 0, hidden: true},
          {name: 'signTypeWrt', label: 'signTypeWrt', width: 0, hidden: true},
          {name: 'tradeNo', label: 'signType', width: 0, hidden: true},
          {name: 'tradeSuffix', label: 'signType', width: 0, hidden: true},
          {name: 'wrtcnsSttusCd', label: 'wrtcnsSttusCd', width: 0, hidden: true},
          {name: 'empId', label: 'empId', width: 0, hidden: true},
          {name: 'wrtFileName', label: 'empId', width: 0, hidden: true},
          {name: 'buyAgntProofFileName', label: 'empId', width: 0, hidden: true},
        ],
      };
      OPT.Cols.push(
        {
          name: 'partAmt',
          label: '부품판가',
          width: 80,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
          cellattr: $_rowMearge,
        }
      );
      OPT.Cols.push(
        {
          name: 'laborAmt',
          label: '인건비판가',
          width: 80,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
          cellattr: $_rowMearge,
        }
      );
      OPT.Cols.push(
        {
          name: 'distAmt',
          label: '총판가',
          width: 80,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
          cellattr: $_rowMearge,
        }
      );
      OPT.Cols.push(
        {
          name: '동의서',
          label: '동의서',
          width: 50,
          align: 'center',
          formatter: function(cellvalue, options, rowObject) {
              var returnStr = "";
              if (rowObject.signTypeWrt == 'ON') {
                returnStr = '<button style="border:1px solid #668CF3;background-color:#668CF3">전자</button>';
              } else if (rowObject.signTypeWrt == 'OFF' && rowObject.wrtFileName != '') {
                returnStr = '<button style="border:1px solid #19B70A;background-color:#19B70A">서면</button>';
              } else {
                returnStr = '<button style="border:1px solid #808080;background-color:#808080" title="없음">없음</button>';
              }
              return returnStr;
            },
          cellattr: $_rowMearge,
        }
      );
      OPT.Cols.push({name: 'signTypeWrtNm', label: '동의서형태', width: 70, align: 'center', cellattr: $_rowMearge});
      OPT.Cols.push({name: 'offReasonWrt', label: '서면사유', width: 170, align: 'left', cellattr: $_rowMearge});
      OPT.Cols.push({name: 'empName', label: '기안담당자', width: 70, align: 'center', cellattr: $_rowMearge});

      const me = this;
      var $grid = $('#' + me.sheetId);
      $grid.jqGrid({
        height: 120,
        width: 1135,
        colModel: OPT.Cols,
        iconSet: 'fontAwesome',
        idPrefix: 'g1_',
        selectionMode: 'singlerow',
        rownumbers: true,
        autowidth: false,
        shrinkToFit: false,
        // eslint-disable-next-line
        onCellSelect: function(rowId, iCol, cellContent, e) {
          console.log('iCol', iCol);
          console.log('rowId', rowId);
          console.log('cellContent', cellContent);
          console.log('e', e);
          var rowData = $('#' + me.sheetId).jqGrid('getRowData', rowId);
          if (me.focusKeys.wrtcnsNo!=rowData.wrtcnsNo) {
            me.$emit('click', rowData);
          }
          me.focusKeys.wrtcnsNo = rowData.wrtcnsNo;
          me.focusKeys.ceckSn = rowData.ceckSn;
          me.focusKeys.docCat1 = rowData.docCat1;
          me.focusKeys.signType = rowData.signType;
          me.focusKeys.wrtcnsSttusCd = rowData.wrtcnsSttusCd;
          me.focusKeys.empId = rowData.empId;

          if (iCol == $('#' + me.sheetId).jqGrid('getGridParam').iColByName['계약서']) {
            if (rowData.signType == 'ON') {
              me.onViewContract(rowData);
            } else {
              me.onDownloadOfflinePdfContract(rowData);
            }
          }
          if (iCol == $('#' + me.sheetId).jqGrid('getGridParam').iColByName['동의서']) {
            if (rowData.signTypeWrt == 'ON') {
              me.onView(rowData);
            } else {
              me.onDownloadOfflineWrtcns(rowData);
            }
          }
          if (iCol == $('#' + me.sheetId).jqGrid('getGridParam').iColByName['buyAgntProofFileDown']) {
            me.onDownloadBuyAgntProofFile(rowData);
          }
        },
      });

      var groups = [];
      groups.push({startColumnName: 'buyagntYn', numberOfColumns: 2, titleText: '대리인'});

      $grid.jqGrid('setGroupHeaders', {
        useColSpanStyle: true,
        groupHeaders: groups,
      });

      $gridAutoResize($grid);
      window.addEventListener('resize', this.onResize);
      setTimeout(() => {
        this.onResize();
        this.onPreSearch();
      }, 1000);

      // 검색란 보이기/감추기
      $initSrchShowButton({
        srchBox: this.$refs.srchBox,
        srchList: this.$refs.srchList,
      });
    },
    methods: {
      // async onSearch() {
      // },
      // async onAdd() {
      // },
      // async onSave() {
      // },
      // async onDelete() {
      // },
      async onPrint() {
        this.$store.dispatch('mdi/doPrint');
      },
      // eslint-disable-next-line
      onResize(event) {
        // console.log('window has been resized', event);
        var $grid = $('#' + this.sheetId);
        var pobj = $grid
            .closest('.ui-jqgrid')
            .parent()
            .parent();
        var newWidth = pobj.width();
        $grid.jqGrid('setGridWidth', newWidth - 2, false);
      },
      onSetValue(e, t) {
        let target = typeof e == 'string' ? t : e.target.name;
        let value = typeof e == 'string' ? e : e.target.value;
        this.search[target] = value;
      },
      onPreSearch() {
        var urls = this.$router.app.urls.filter(data => data.menuId == this.$route.name)[0];
        if (urls !== undefined) {
          var params = urls.params;
          console.log('params', params);
          if (params !== undefined) {
            this.search.statusCode = '';
            this.search.statusCode = params.statusCode;
          }
        }

        this.focusKeys.wrtcnsNo = '';
        this.focusKeys.docCat1 = '';
        this.focusKeys.signType = '';
        this.focusKeys.wrtcnsSttusCd = '';
        this.focusKeys.empId = '';
        this.onSearch();
      },
      async onSearch() {

        // 일자와 무관하게 조회하도록
        if (this.search.wrtcnsNo === undefined || this.search.wrtcnsNo == '') {

          // 기간 비워져 있을시에 alert
          if (!this.search.dateStart || !this.search.dateEnd) {
            this.$alert('검색하실 기안일의 시작일과 종료일을 선택해주세요.');
            return;
          }

          if (parseInt(this.search.dontDateStart) > parseInt(this.search.dateEnd)) {
            this.$alert('시작일은 종료일 보다 뒤일 수 없습니다.<br>시작일을 다시 선택해주세요.');
            return;
          }

          var sDate = new Date(
            this.search.dateStart.substring(0, 4),
            this.search.dateStart.substring(4, 6),
            this.search.dateStart.substring(6, 8)
          );

          var eDate = new Date(
            this.search.dateEnd.substring(0, 4),
            this.search.dateEnd.substring(4, 6),
            this.search.dateEnd.substring(6, 8)
          );

          var dif = eDate - sDate;
          var cDay = 24 * 60 * 60 * 1000; // 시 * 분 * 초 * 밀리세컨
          var cMonth = cDay * 30; // 월 만듬
          // var cYear = cMonth * 12; // 년 만듬

          // console.log('sDate', sDate);
          // console.log('eDate', eDate);
          // console.log('dif', dif);
          // console.log('parseInt(dif/cMonth)', parseInt(dif/cMonth));

          if (parseInt(dif / cMonth) > 1) {
            this.$alert('기간을 1개월 이내로 검색해주세요.');
            return;
          }
        } else {
          this.search.dateStart = '';
          this.search.dateEnd = '';
        }

        this.listBoxLoading = true;

        let param = {
          ctrtNo: this.search.ctrtNo,
          tradeNo: this.search.tradeNo,
          mainName: this.search.mainName,
          suriName: this.search.suriName,
          customerName: this.search.customerName,
          empId: this.search.empId,
          empName: this.search.empName,
          dateStart: this.search.dateStart,
          dateEnd: this.search.dateEnd,
          statusCode: this.search.statusCode,
          otisPage: true,
          customerPage: false,
          teamTreeEmpNo: this.search.teamTreeEmpNo,
          wrtcnsNo: this.search.wrtcnsNo,
          signType: this.search.signType,
        };

        let response = null;
        try {
          response = await axios.post('/wrtcns/selectWrtcnsListStatistics', param);
          this.listBoxLoading = false;
          $('#' + this.sheetId)
            .clearGridData(true)
            .setGridParam({data: response.data})
            .trigger('reloadGrid');

          var rowData;
          var ids = $('#' + this.sheetId).jqGrid('getDataIDs');
          if (ids && ids.length > 0) {
            if (this.focusKeys.ctrtNo && this.focusKeys.ctrtOdr && this.focusKeys.ctrtDivCd) {
              for (var i = 0; i <= ids.length - 1; i++) {
                rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[i]);

                if (
                  rowData['wrtcnsNo'] == this.focusKeys.wrtcnsNo
                ) {
                  console.log('일치하는 Row 찾아 조회');
                  $('#' + this.sheetId).jqGrid('setSelection', ids[i], true);
                  this.$emit('click', rowData);
                  break;
                }
              }
            } else {
              console.log('일치하는 Row 못찾아 첫번째 행을 조회');
              $('#' + this.sheetId).jqGrid('setSelection', ids[0], true);
              rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[0]);
              this.$emit('click', rowData);
            }
          } else {
            console.log('검색된 데이터 없어서 리셋');
            this.$emit('resetValues', null);
          }
        } catch (ex) {
          console.log(ex);
          this.listBoxLoading = false;
        }
      },
      async onViewContract(data) {
        console.log('onViewContract');
        console.log('checkContNo', data.checkContNo);
        console.log('ceckSn', data.ceckSn);
        console.log('ctrtDivCd', data.ctrtDivCd);
        console.log('docCat1', data.docCat1);

        var signable = 'N';
        var signerSeCd = '10';
        var tradeNo = '';

        console.log('signable', signable);
        console.log('signerSeCd', signerSeCd);
        console.log('tradeNo', tradeNo);

        //자동연장공문 조회 후 오즈뷰어 팝업시킴.
        let param = {
          checkContNo: data.ctrtNo,
          checkSeq: data.ctrtOdr,
          manageCd: data.docCat1,
        };

        // eslint-disable-next-line
        var opt = {};
        const btns = [];

        var opt_param = {
          ctrtNo: data.checkContNo,
          ctrtOdr: data.ceckSn,
          ctrtDivCd: data.ctrtDivCd,
          docCat1: data.docCat1,
          ctrtSttusCd: data.ctrtSttusCd,
        }; 

        if (data.ctrtOdr == '00') {
          if (data.docCat1 == '000') {
            // eslint-disable-next-line
            opt = $open({
              componentPath: '@/view/contract/oContract/components/popup/ContractViewer.vue', // 컴포넌트 경로 (필수)
              param: {
                ...opt_param,
                OZParam: {},
                otisPage: true,
              }, // 파라미터 (생략가능)
              btns: btns, // 팝업 버튼 (생략가능)
              name: 'OContractViewer', // 팝업 윈도우 이름 (생략가능)
              option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
              title: '계약서조회', // 팝업 제목 (생략가능)
            });
          } else {
            axios
              .post('/ozrpt/selectOzOContract', param)
              .then(response => {
                // console.log('조회결과 response >>>', response);
                // console.log('조회결과 response.data >>>', response.data);

                this.OZParam = Object.assign({}, response.data.jsonData);

                this.OZParam.reportname = response.data.reportPathName;
                this.OZParam.OZFormParam = {
                  signable: signable, // 필수(*):디지털 서명가능여부(Y/N)
                  signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
                  tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선번호(로그인한사용자)
                  signMthCd: '',
                };

                // eslint-disable-next-line
                opt = $open({
                  componentPath: '@/view/contract/oContract/components/popup/ContractViewer.vue', // 컴포넌트 경로 (필수)
                  param: {
                    ...opt_param,
                    OZParam: this.OZParam,
                    otisPage: true,
                  }, // 파라미터 (생략가능)
                  btns: btns, // 팝업 버튼 (생략가능)
                  name: 'OContractViewer', // 팝업 윈도우 이름 (생략가능)
                  option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
                  title: '계약서조회', // 팝업 제목 (생략가능)
                });
              })
              .catch(function(error) {
                console.log('========error====================');
                console.log(error);
              });
          }
        } else {
          if (data.docCat1 == '000') {
            // eslint-disable-next-line
            opt = $open({
              componentPath: '@/view/contract/oContract/components/popup/OfficialDocumentViewer.vue', // 컴포넌트 경로 (필수)
              param: {
                ...opt_param,
                OZParam: {},
                otisPage: true,
              }, // 파라미터 (생략가능)
              btns: btns, // 팝업 버튼 (생략가능)
              name: 'OfficialDocumentViewer', // 팝업 윈도우 이름 (생략가능)
              option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
              title: '공문조회', // 팝업 제목 (생략가능)
            });
          } else {
            axios
              .post('/ozrpt/selectOzOContract', param)
              .then(response => {
                // console.log('조회결과 response >>>', response);
                // console.log('조회결과 response.data >>>', response.data);

                this.OZParam = Object.assign({}, response.data.jsonData);

                this.OZParam.reportname = response.data.reportPathName;
                this.OZParam.OZFormParam = {
                  signable: signable, // 필수(*):디지털 서명가능여부(Y/N)
                  signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
                  tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선번호(로그인한사용자)
                };

                // eslint-disable-next-line
                opt = $open({
                  componentPath: '@/view/contract/oContract/components/popup/OfficialDocumentViewer.vue', // 컴포넌트 경로 (필수)
                  param: {
                    ...opt_param,
                    OZParam: this.OZParam,
                    otisPage: true,
                  }, // 파라미터 (생략가능)
                  btns: btns, // 팝업 버튼 (생략가능)
                  name: 'OfficialDocumentViewer', // 팝업 윈도우 이름 (생략가능)
                  option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
                  title: '공문조회', // 팝업 제목 (생략가능)
                });
              })
              .catch(function(error) {
                console.log('========error====================');
                console.log(error);
              });
          }
        }
      },
      async onDownloadOfflinePdfContract(data) {
        var fileName = data.fileName;
        if (fileName == '') {
          this.$alert('서면으로 체결 된 계약서는 OKSS에서 등록해야 다운로드 받을 수 있습니다.');
          return;
        }
        var url = '/ocontract/downloadOfflinePdf?checkContNo=' + data.checkContNo + '&checkSeq=' + data.ceckSn;
        const response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
        });

        if (response.data.size > 0) {
          const downUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = downUrl;
          if (typeof window.navigator.msSaveBlob === 'function') {
            window.navigator.msSaveBlob(response.data, fileName);
          } else {
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        } else {
          this.$alert('서면으로 체결 된 계약서는 OKSS에서 등록해야 다운로드 받을 수 있습니다.');
        }
      },
      async onView(data) {
        console.log('onView');
        console.log('wrtcnsNo', data.wrtcnsNo);

        let param2 = {
          wrtcnsNo: data.wrtcnsNo,
          tradeNo: data.tradeNo,
          tradeSuffix: data.tradeSuffix,
        };
        let response = await axios.post('/wrtcns/selectSignerInfo', param2);

        console.log(response);

        var signable = 'N';
        var signerSeCd = eval(response.data.buyagnt) == true ? '20' : '10';
        var tradeNo = eval(response.data.buyagnt) == false && eval(response.data.canSign) == true ? response.data.tradeNo : '';

        let param = {
          wrtcnsNo: data.wrtcnsNo,
          manageCd: 'WRT01',
        };

        var opt = {};
        const btns = [];

        var opt_param = {
          wrtcnsNo: data.wrtcnsNo,
          tradeNo: data.tradeNo,
          tradeSfx: data.tradeSuffix,
        }; 
  
        console.log('btns', btns);
        console.log('signable', signable);
        console.log('signerSeCd', signerSeCd);
        console.log('tradeNo', tradeNo);

        axios
          .post('/ozrpt/selectOzQryReportFileName', param)
          .then(response => {
            // console.log('조회결과 response >>>', response);
            // console.log('조회결과 response.data >>>', response.data);

            this.OZParam.reportname = response.data.reportPathName;
            this.OZParam.odiname = response.data.odiNm;
            this.OZParam.OZFormParam = {
              signable: signable, // 필수(*):서명가능여부(Y/N)
              signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
              tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선코드(로그인한사용자)
              signMthCd: '',
              draftNo: data.wrtcnsNo,
              checkSeq: data.ceckSn,
              signDt: '',
              signData1: '',
              signData2: '',
              tmstmpIssuDt: '',
            };

            console.log('opt_param', opt_param);
            console.log('this.OZParam', this.OZParam);

            // eslint-disable-next-line
            opt = $open({
              componentPath: '@/view/wrtcns/subcntrWrtcns/components/popup/SubcntrWrtcnsView.vue', // 컴포넌트 경로 (필수)
              param: {
                ...opt_param,
                OZParam: this.OZParam,
                otisPage: this.otisPage,
              }, // 파라미터 (생략가능)
              btns: btns, // 팝업 버튼 (생략가능)
              name: 'SubcntrWrtcnsViewer', // 팝업 윈도우 이름 (생략가능)
              option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
              title: '동의서조회', // 팝업 제목 (생략가능)
            });
          })
          .catch(function(error) {
            console.log('========error====================');
            console.log(error);
          });
      },
      async onDownloadOfflineWrtcns(data) {
        console.log('onDownloadOfflineWrtcns');
        if (data.wrtFileName !== undefined && data.wrtFileName != null && data.wrtFileName != '') {
          var url = '/wrtcns/downloadOfflineFile?wrtcnsNo=' + data.wrtcnsNo;
          const response = await axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // important
          });

          if (response.data.size > 0) {
            const downUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downUrl;
            if (typeof window.navigator.msSaveBlob === 'function') {
              window.navigator.msSaveBlob(response.data, data.wrtFileName);
            } else {
              link.setAttribute('download', data.wrtFileName);
              document.body.appendChild(link);
              link.click();
              link.remove();
            }
          }
        } else {
          this.$alert('서면으로 체결 된 동의서는 OKSS에서 등록해야 다운로드 받을 수 있습니다.');
        }
      },
      async onDownloadBuyAgntProofFile(data) {
        console.log('onDownloadBuyAgntProofFile');
        if (data.buyAgntProofFileName !== undefined && data.buyAgntProofFileName != null && data.buyAgntProofFileName != '') {
          var url = '/wrtcns/downloadBuyAgntProofFile?wrtcnsNo=' + data.wrtcnsNo;
          const response = await axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // important
          });

          if (response.data.size > 0) {
            const downUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downUrl;
            if (typeof window.navigator.msSaveBlob === 'function') {
              window.navigator.msSaveBlob(response.data, data.buyAgntProofFileName);
            } else {
              link.setAttribute('download', data.buyAgntProofFileName);
              document.body.appendChild(link);
              link.click();
              link.remove();
            }
          }
        } else {
          this.$alert('대리인 증명서 파일을 OKSS에서 등록해야 다운로드 받을 수 있습니다.');
        }
      },
    },
    computed: {
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    },
  };
</script>
  
<style></style>