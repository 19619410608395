<template>
  <pmis-page ref="page" :search-project-visible="false">
    <iui-container type="css-flex">
      <i-row>
        <i-col width="100%">
          <masterSign @emitMasterSignInfo="searchMasterSignInfo" />
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
import masterSign from './components/MasterSignHist.vue';
//import lowerCode from './components/LowerCode.vue';
import store from './store/MasterSignHist.js';
export default {
  components: {masterSign},
  async mounted() {
    this.callEvent({name: 'search'});
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['masterSignHist']);
    $mapMutations(this, ['setMasterSignHist']);
  },
  created() {
    this.setCmmBtns(this.cmmBtns);
  },
  data() {
    return {
      cmmBtns: [
        {name: '조회', class: 's', callback: this.onSearch},
        {name: '출력', class: 'p', callback: this.onPrint},
      ],
    };
  },
  methods: {
    onSearch() {
      this.callEvent({name: 'search'});
    },
    onAdd() {
      this.callEvent({name: 'add'});
    },
    onSave() {
      this.callEvent({name: 'save'});
    },
    onDelete() {
      this.callEvent({name: 'delete'});
    },
    onPrint() {},

    searchMasterSignInfo(row) {
      console.log(  'searchMasterSignInfo=================');
      this.setMasterSignHist(row);
      this.callEvent({name: 'search', param: {}});
    },
  },
};
</script>

<style scoped></style>