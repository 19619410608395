<template>
  <iui-container type="css-flex" theme="bullet">
    <i-row height="70px" ref="srchBox" v-if="otisPage">
      <i-col>
        <iui-container type="table" theme="bullet">
          <i-row>
            <i-col-header>현장명</i-col-header>
            <i-col>
              <iui-text name="mainName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>공사명</i-col-header>
            <i-col>
              <iui-text name="suriName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>고객명</i-col-header>
            <i-col>
              <iui-text name="customerName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>기안담당자</i-col-header>
            <i-col>
              <iui-text name="empName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>기안일</i-col-header>
            <i-col
              ><span style="white-space:nowrap">
                <iui-datepicker-old
                  name="dateStart"
                  suffix="~"
                  @change="onSetValue($event, 'dateStart')"
                  :value="search.dateStart"/>
                &nbsp;<iui-datepicker-old
                  name="dateEnd"
                  @change="onSetValue($event, 'dateEnd')"
                  :value="search.dateEnd"
              /></span>
            </i-col>
            <i-col-header>기안번호</i-col-header>
            <i-col>
              <iui-text name="wrtcnsNo" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>계약번호</i-col-header>
            <i-col>
              <iui-text name="ctrtNo" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>진행상태</i-col-header>
            <i-col>
              <iui-select-old
                name="statusCode"
                :items="arrStatusCode"
                :value="search.statusCode"
                :enable="true"
                @change="onSetValue"
                required
              />
            </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
    <i-row height="40px" ref="srchBox" v-if="customerPage">
      <i-col>
        <iui-container type="table" theme="bullet">
          <i-row>
            <i-col-header>현장명</i-col-header>
            <i-col>
              <iui-text name="mainName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>공사명</i-col-header>
            <i-col>
              <iui-text name="suriName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>기안일</i-col-header>
            <i-col
              ><span style="white-space:nowrap">
                <iui-datepicker-old
                  name="dateStart"
                  suffix="~"
                  @change="onSetValue($event, 'dateStart')"
                  :value="search.dateStart"/>
                &nbsp;<iui-datepicker-old
                  name="dateEnd"
                  @change="onSetValue($event, 'dateEnd')"
                  :value="search.dateEnd"
              /></span>
            </i-col>
            <i-col-header>진행상태</i-col-header>
            <i-col>
              <iui-select-old
                name="statusCode"
                :items="arrStatusCode"
                :value="search.statusCode"
                :enable="true"
                @change="onSetValue"
                required
              />
            </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
    <i-row height="260px">
      <i-col>
        <pmis-content-box no-border-box>
          <template v-slot:title>
            동의서 목록
          </template>
          <template #title-right v-if="otisPage">
            <iui-button :enable="isCanReject" @click="onControlButton('reject')">회수 및 OKSS전송</iui-button>
          </template>
          <iui-container type="jqgrid" :id="sheetId" :loading="listBoxLoading" style="width:99.9%" />
          <iui-modal-old
            :name="rejectModalId"
            :title="'회수 및 OKSS전송'"
            :btns="modalBtns"
            width="600px"
            height="300px"
          >
            <Reject :wrtcnsNo="focusKeys.wrtcnsNo" />
          </iui-modal-old>
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container>
</template>
  
<script>
  import Reject from './popup/Reject.vue';
  export default {
    name: 'subcntr-wrtcns-list',
    props: {
      otisPage: {
        // OSE 페이지
        type: Boolean,
        default: false,
      },
      customerPage: {
        // 업체 페이지
        type: Boolean,
        default: false,
      },
    },
    components: {
      Reject,
    },
    beforeCreate() {
      $mapGetters(this, ['wrtcnsInfo', 'isEmp']);
    },
    created() {
      this.addEvent({name: 'SubcntrWrtcns_Search', func: this.onPreSearch});
    },
    data() {
      return {
        //sheetId: 'sheet',// + this._uid,
        sheetId: 'sheet' + this.$store.getters['mdi/currentUrls'].menuId,
        modalNm: '',
        modalBtns: [
          {name: '회수 및 OKSS전송', callback: this.onPopupConfirmSendToOkss},
        ],
        rejectModalId: 'rejectModal' + this._uid,
        arrStatusCode: [{text: '전체', value: ''}],
        search: {
          ctrtNo: '',
          tradeNo: '',
          mainName: '',
          suriName: '',
          customerName: '',
          empId: '',
          empName: '',
          dateStart: '',
          dateEnd: '',
          statusCode: '',
          isOtisPage: this.otisPage,
          isCustomerPage: this.customerPage,
          teamTreeEmpNo: '',
          wrtcnsNo: '',
        },
  
        listBoxLoading: false,
  
        focusKeys: {
          wrtcnsNo: '',
          ceckSn: '',
          docCat1: '',
          signType: '',
          wrtcnsSttusCd: '',
          empId: '',
        },
  
        modalOZViewerBtns: [],
        OZParam: {},
      };
    },
    mounted() {
      this.arrStatusCode.push({text: '업체송부', value: '10'});
      this.arrStatusCode.push({text: '동의완료', value: '30'});
      this.arrStatusCode.push({text: '공전소전송완료', value: '40'});
      this.arrStatusCode.push({text: '공전소전송실패', value: '41'});

      if (this.customerPage) {
        this.search.dateStart = $_addMonth($_getCurrentDate(), -3);
      } else if (this.otisPage) {
        this.search.dateStart = $_addMonth($_getCurrentDate(), -1);
      }
      this.search.dateEnd = $_getCurrentDate();

      // 개발용
      if (process.env.NODE_ENV == 'development') {
        this.search.dateStart = '20241001';
        this.search.dateEnd = '20241030';
      }

      const OPT = {
        Cols: [          
          // eslint-disable-next-line
          {
            name: '계약서',
            label: '계약서',
            width: 50,
            align: 'center',
            formatter: function(cellvalue, options, rowObject) {
              var returnStr = "";
              if (rowObject.signType == 'ON') {
                returnStr = '<button style="border:1px solid #668CF3;background-color:#668CF3">전자</button>';
              } else if (rowObject.signType == 'OFF' && rowObject.fileName != '') {
                returnStr = '<button style="border:1px solid #19B70A;background-color:#19B70A">서면</button>';
              } else {
                returnStr = '<button style="border:1px solid #808080;background-color:#808080" title="없음">없음</button>';
              }
              return returnStr;
            },
            cellattr: $_rowMearge,
          }, // 계약서
          {name: 'teamNoDesc', label: '부서', width: 100, align: 'left', cellattr: $_rowMearge}, // 부서
          {name: 'wrtcnsNo', label: '기안번호', width: 90, align: 'center', cellattr: $_rowMearge}, // 기안번호
          {name: 'startDate', label: '기안일', width: 80, align: 'center', cellattr: $_rowMearge}, // 기안일
          {name: 'checkContTypeA3', label: '계약형태', width: 60, align: 'center', cellattr: $_rowMearge}, // 계약형태
          {name: 'checkContNo', label: '계약번호', width: 80, align: 'center', cellattr: $_rowMearge}, // 계약번호
          {name: 'contStartDate', label: '계약시작일', width: 80, align: 'center', cellattr: $_rowMearge}, // 계약시작일
          {name: 'contEndDate', label: '계약종료일', width: 80, align: 'center', cellattr: $_rowMearge}, // 계약종료일
          {name: 'cpTradeNo', label: '고객번호', width: 70, align: 'center', cellattr: $_rowMearge}, // 고객번호
          {name: 'tradeNm', label: '고객명', width: 140, align: 'left', cellattr: $_rowMearge}, // 고객명
          {name: 'mainNo', label: '관리번호', width: 70, align: 'center', cellattr: $_rowMearge}, // 관리번호
          {name: 'mainName', label: '현장명', width: 140, align: 'left', cellattr: $_rowMearge}, // 현장명
          {name: 'suriName', label: '공사명', width: 140, align: 'left', cellattr: $_rowMearge}, // 공사명
          {name: 'ceckSn', label: 'ceckSn', width: 0, hidden: true},
          {name: 'ctrtDivCd', label: 'docCat1', width: 0, hidden: true},
          {name: 'ctrtSttusCd', label: 'docCat1', width: 0, hidden: true},
          {name: 'docCat1', label: 'docCat1', width: 0, hidden: true},
          {name: 'signType', label: 'signType', width: 0, hidden: true},
          {name: 'tradeNo', label: 'signType', width: 0, hidden: true},
          {name: 'tradeSuffix', label: 'signType', width: 0, hidden: true},
          {name: 'wrtcnsSttusCd', label: 'wrtcnsSttusCd', width: 0, hidden: true},
          {name: 'empId', label: 'empId', width: 0, hidden: true},
        ],
      };
      if (this.otisPage) {
        OPT.Cols.push(
          {
            name: 'partAmt',
            label: '부품판가',
            width: 80,
            align: 'right',
            formatter: 'number',
            formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
            cellattr: $_rowMearge,
          }
        );
        OPT.Cols.push(
          {
            name: 'laborAmt',
            label: '인건비판가',
            width: 80,
            align: 'right',
            formatter: 'number',
            formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
            cellattr: $_rowMearge,
          }
        );
        OPT.Cols.push(
          {
            name: 'distAmt',
            label: '총판가',
            width: 80,
            align: 'right',
            formatter: 'number',
            formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
            cellattr: $_rowMearge,
          }
        );
      }
      OPT.Cols.push(
        {
          name: '동의서',
          label: '동의서',
          width: 50,
          align: 'center',
          formatter: function(cellvalue, options, rowObject) {
            cellvalue = true;
            return cellvalue == true ? '<button>보기</button>' : '';
          },
          cellattr: $_rowMearge,
        }
      );
      OPT.Cols.push(
        {
          name: 'wrtcnsSttusSend',
          label: '송부',
          width: 50,
          align: 'center',
          formatter: function(cellvalue) {
            return cellvalue == 'O' ? 'Ｏ' : '';
          },
        }
      );
      OPT.Cols.push(
        {
          name: 'wrtcnsSttusCmpl',
          label: '완료',
          width: 50,
          align: 'center',
          formatter: function(cellvalue) {
            return cellvalue == 'O' ? 'Ｏ' : '';
          },
        }
      );
      OPT.Cols.push({name: 'empName', label: '기안담당자', width: 70, align: 'center', cellattr: $_rowMearge});

      const me = this;
      var $grid = $('#' + me.sheetId);
      $grid.jqGrid({
        height: 120,
        width: 1135,
        colModel: OPT.Cols,
        iconSet: 'fontAwesome',
        idPrefix: 'g1_',
        selectionMode: 'singlerow',
        rownumbers: true,
        autowidth: false,
        shrinkToFit: false,
        // eslint-disable-next-line
        onCellSelect: function(rowId, iCol, cellContent, e) {
          console.log('iCol', iCol);
          console.log('rowId', rowId);
          console.log('cellContent', cellContent);
          console.log('e', e);
          var rowData = $('#' + me.sheetId).jqGrid('getRowData', rowId);
          if (me.focusKeys.wrtcnsNo!=rowData.wrtcnsNo) {
            me.$emit('click', rowData);
          }
          me.focusKeys.wrtcnsNo = rowData.wrtcnsNo;
          me.focusKeys.ceckSn = rowData.ceckSn;
          me.focusKeys.docCat1 = rowData.docCat1;
          me.focusKeys.signType = rowData.signType;
          me.focusKeys.wrtcnsSttusCd = rowData.wrtcnsSttusCd;
          me.focusKeys.empId = rowData.empId;

          if (iCol == $('#' + me.sheetId).jqGrid('getGridParam').iColByName['계약서']) {
            if (rowData.signType == 'ON') {
              me.onViewContract(rowData);
            } else {
              me.onDownloadOfflinePdfContract(rowData);
            }
          }
          if (iCol == $('#' + me.sheetId).jqGrid('getGridParam').iColByName['동의서']) {
            me.onView(rowData);
          }
        },
      });

      var groups = [];
      groups.push({startColumnName: 'wrtcnsSttusSend', numberOfColumns: 2, titleText: '진행상태'});
      $grid.jqGrid('setGroupHeaders', {
        useColSpanStyle: true,
        groupHeaders: groups,
      });

      $gridAutoResize($grid);
      window.addEventListener('resize', this.onResize);
      setTimeout(() => {
        this.onResize();
      }, 1000);

      // 검색란 보이기/감추기
      $initSrchShowButton({
        srchBox: this.$refs.srchBox,
        srchList: this.$parent.$parent,
      });
    },
    methods: {
      // eslint-disable-next-line
      onResize(event) {
        // console.log('window has been resized', event);
        var $grid = $('#' + this.sheetId);
        var pobj = $grid
            .closest('.ui-jqgrid')
            .parent()
            .parent();
        var newWidth = pobj.width();
        $grid.jqGrid('setGridWidth', newWidth - 2, false);
      },
      onSetValue(e, t) {
        let target = typeof e == 'string' ? t : e.target.name;
        let value = typeof e == 'string' ? e : e.target.value;
        this.search[target] = value;
      },
      onPreSearch() {
        var urls = this.$router.app.urls.filter(data => data.menuId == this.$route.name)[0];
        if (urls !== undefined) {
          var params = urls.params;
          console.log('params', params);
          if (params !== undefined) {
            this.search.statusCode = '';
            this.search.statusCode = params.statusCode;
          }
        }

        this.focusKeys.wrtcnsNo = '';
        this.focusKeys.docCat1 = '';
        this.focusKeys.signType = '';
        this.focusKeys.wrtcnsSttusCd = '';
        this.focusKeys.empId = '';
        this.onSearch();
      },
      async onSearch() {

        // 일자와 무관하게 조회하도록
        if (this.search.wrtcnsNo === undefined || this.search.wrtcnsNo == '') {

          // 기간 비워져 있을시에 alert
          if (!this.search.dateStart || !this.search.dateEnd) {
            this.$alert('검색하실 기안일의 시작일과 종료일을 선택해주세요.');
            return;
          }

          if (parseInt(this.search.dontDateStart) > parseInt(this.search.dateEnd)) {
            this.$alert('시작일은 종료일 보다 뒤일 수 없습니다.<br>시작일을 다시 선택해주세요.');
            return;
          }

          var sDate = new Date(
            this.search.dateStart.substring(0, 4),
            this.search.dateStart.substring(4, 6),
            this.search.dateStart.substring(6, 8)
          );

          var eDate = new Date(
            this.search.dateEnd.substring(0, 4),
            this.search.dateEnd.substring(4, 6),
            this.search.dateEnd.substring(6, 8)
          );

          var dif = eDate - sDate;
          var cDay = 24 * 60 * 60 * 1000; // 시 * 분 * 초 * 밀리세컨
          var cMonth = cDay * 30; // 월 만듬
          // var cYear = cMonth * 12; // 년 만듬

          // console.log('sDate', sDate);
          // console.log('eDate', eDate);
          // console.log('dif', dif);
          // console.log('parseInt(dif/cMonth)', parseInt(dif/cMonth));

          if (this.customerPage) {
            if (parseInt(dif / cMonth) > 3) {
              this.$alert('기간을 3개월 이내로 검색해주세요.');
              return;
            }
          } else if (this.otisPage) {
            if (parseInt(dif / cMonth) > 1) {
              this.$alert('기간을 1개월 이내로 검색해주세요.');
              return;
            }
          }
        } else {
          this.search.dateStart = '';
          this.search.dateEnd = '';
        }

        this.listBoxLoading = true;

        let param = {
          ctrtNo: this.search.ctrtNo,
          tradeNo: this.search.tradeNo,
          mainName: this.search.mainName,
          suriName: this.search.suriName,
          customerName: this.search.customerName,
          empId: this.search.empId,
          empName: this.search.empName,
          dateStart: this.search.dateStart,
          dateEnd: this.search.dateEnd,
          statusCode: this.search.statusCode,
          otisPage: this.search.isOtisPage,
          customerPage: this.search.isCustomerPage,
          teamTreeEmpNo: this.search.teamTreeEmpNo,
          wrtcnsNo: this.search.wrtcnsNo,
        };

        let response = null;
        try {
          response = await axios.post('/wrtcns/selectWrtcnsList', param);
          this.listBoxLoading = false;
          $('#' + this.sheetId)
            .clearGridData(true)
            .setGridParam({data: response.data})
            .trigger('reloadGrid');

          var rowData;
          var ids = $('#' + this.sheetId).jqGrid('getDataIDs');
          if (ids && ids.length > 0) {
            if (this.focusKeys.ctrtNo && this.focusKeys.ctrtOdr && this.focusKeys.ctrtDivCd) {
              for (var i = 0; i <= ids.length - 1; i++) {
                rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[i]);

                if (
                  rowData['wrtcnsNo'] == this.focusKeys.wrtcnsNo
                ) {
                  console.log('일치하는 Row 찾아 조회');
                  $('#' + this.sheetId).jqGrid('setSelection', ids[i], true);
                  this.$emit('click', rowData);
                  break;
                }
              }
            } else {
              console.log('일치하는 Row 못찾아 첫번째 행을 조회');
              $('#' + this.sheetId).jqGrid('setSelection', ids[0], true);
              rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[0]);
              this.$emit('click', rowData);
            }
          } else {
            console.log('검색된 데이터 없어서 리셋');
            this.$emit('resetValues', null);
          }
        } catch (ex) {
          console.log(ex);
          this.listBoxLoading = false;
        }
      },
      async onViewContract(data) {
        console.log('onViewContract');
        console.log('checkContNo', data.checkContNo);
        console.log('ceckSn', data.ceckSn);
        console.log('ctrtDivCd', data.ctrtDivCd);
        console.log('docCat1', data.docCat1);

        var signable = 'N';
        var signerSeCd = '10';
        var tradeNo = '';

        console.log('signable', signable);
        console.log('signerSeCd', signerSeCd);
        console.log('tradeNo', tradeNo);

        //자동연장공문 조회 후 오즈뷰어 팝업시킴.
        let param = {
          checkContNo: data.ctrtNo,
          checkSeq: data.ctrtOdr,
          manageCd: data.docCat1,
        };

        // eslint-disable-next-line
        var opt = {};
        const btns = [];

        var opt_param = {
          ctrtNo: data.checkContNo,
          ctrtOdr: data.ceckSn,
          ctrtDivCd: data.ctrtDivCd,
          docCat1: data.docCat1,
          ctrtSttusCd: data.ctrtSttusCd,
        }; 

        if (data.ctrtOdr == '00') {
          if (data.docCat1 == '000') {
            // eslint-disable-next-line
            opt = $open({
              componentPath: '@/view/contract/oContract/components/popup/ContractViewer.vue', // 컴포넌트 경로 (필수)
              param: {
                ...opt_param,
                OZParam: {},
                otisPage: true,
              }, // 파라미터 (생략가능)
              btns: btns, // 팝업 버튼 (생략가능)
              name: 'OContractViewer', // 팝업 윈도우 이름 (생략가능)
              option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
              title: '계약서조회', // 팝업 제목 (생략가능)
            });
          } else {
            axios
              .post('/ozrpt/selectOzOContract', param)
              .then(response => {
                // console.log('조회결과 response >>>', response);
                // console.log('조회결과 response.data >>>', response.data);

                this.OZParam = Object.assign({}, response.data.jsonData);

                this.OZParam.reportname = response.data.reportPathName;
                this.OZParam.OZFormParam = {
                  signable: signable, // 필수(*):디지털 서명가능여부(Y/N)
                  signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
                  tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선번호(로그인한사용자)
                  signMthCd: '',
                };

                // eslint-disable-next-line
                opt = $open({
                  componentPath: '@/view/contract/oContract/components/popup/ContractViewer.vue', // 컴포넌트 경로 (필수)
                  param: {
                    ...opt_param,
                    OZParam: this.OZParam,
                    otisPage: true,
                  }, // 파라미터 (생략가능)
                  btns: btns, // 팝업 버튼 (생략가능)
                  name: 'OContractViewer', // 팝업 윈도우 이름 (생략가능)
                  option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
                  title: '계약서조회', // 팝업 제목 (생략가능)
                });
              })
              .catch(function(error) {
                console.log('========error====================');
                console.log(error);
              });
          }
        } else {
          if (data.docCat1 == '000') {
            // eslint-disable-next-line
            opt = $open({
              componentPath: '@/view/contract/oContract/components/popup/OfficialDocumentViewer.vue', // 컴포넌트 경로 (필수)
              param: {
                ...opt_param,
                OZParam: {},
                otisPage: true,
              }, // 파라미터 (생략가능)
              btns: btns, // 팝업 버튼 (생략가능)
              name: 'OfficialDocumentViewer', // 팝업 윈도우 이름 (생략가능)
              option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
              title: '공문조회', // 팝업 제목 (생략가능)
            });
          } else {
            axios
              .post('/ozrpt/selectOzOContract', param)
              .then(response => {
                // console.log('조회결과 response >>>', response);
                // console.log('조회결과 response.data >>>', response.data);

                this.OZParam = Object.assign({}, response.data.jsonData);

                this.OZParam.reportname = response.data.reportPathName;
                this.OZParam.OZFormParam = {
                  signable: signable, // 필수(*):디지털 서명가능여부(Y/N)
                  signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
                  tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선번호(로그인한사용자)
                };

                // eslint-disable-next-line
                opt = $open({
                  componentPath: '@/view/contract/oContract/components/popup/OfficialDocumentViewer.vue', // 컴포넌트 경로 (필수)
                  param: {
                    ...opt_param,
                    OZParam: this.OZParam,
                    otisPage: true,
                  }, // 파라미터 (생략가능)
                  btns: btns, // 팝업 버튼 (생략가능)
                  name: 'OfficialDocumentViewer', // 팝업 윈도우 이름 (생략가능)
                  option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
                  title: '공문조회', // 팝업 제목 (생략가능)
                });
              })
              .catch(function(error) {
                console.log('========error====================');
                console.log(error);
              });
          }
        }
      },
      async onDownloadOfflinePdfContract(data) {
        var fileName = data.fileName;
        if (fileName == '') {
          this.$alert('서면으로 체결 된 계약서는 OKSS에서 등록해야 다운로드 받을 수 있습니다.');
          return;
        }
        var url = '/ocontract/downloadOfflinePdf?checkContNo=' + data.checkContNo + '&checkSeq=' + data.ceckSn;
        const response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
        });

        if (response.data.size > 0) {
          const downUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = downUrl;
          if (typeof window.navigator.msSaveBlob === 'function') {
            window.navigator.msSaveBlob(response.data, fileName);
          } else {
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        } else {
          this.$alert('서면으로 체결 된 계약서는 OKSS에서 등록해야 다운로드 받을 수 있습니다.');
        }
      },
      async onView(data) {
        console.log('onView');
        console.log('wrtcnsNo', data.wrtcnsNo);

        let param2 = {
          wrtcnsNo: data.wrtcnsNo,
          tradeNo: data.tradeNo,
          tradeSuffix: data.tradeSuffix,
        };
        let response = await axios.post('/wrtcns/selectSignerInfo', param2);

        console.log(response);

        var signable = 'N';
        var signerSeCd = eval(response.data.buyagnt) == true ? '20' : '10';
        var tradeNo = eval(response.data.buyagnt) == false && eval(response.data.canSign) == true ? response.data.tradeNo : '';

        let param = {
          wrtcnsNo: data.wrtcnsNo,
          manageCd: 'WRT01',
        };

        var opt = {};
        const btns = [];

        var opt_param = {
          wrtcnsNo: data.wrtcnsNo,
          tradeNo: data.tradeNo,
          tradeSfx: data.tradeSuffix,
        }; 
  
        if (
          eval(response.data.buyagnt) == false && this.customerPage &&
          eval(response.data.canSign) == true && data.wrtcnsSttusCd == '10'
        ) {
          signable = 'Y';
          btns.push({
            name: '서명', // 버튼명
            callback: () => {
              // 버튼 Event
              popupInterface[opt.name].vue.callEvent({
                name: 'SubcntrWrtcnsViewerPopup_Sign',
                param: data => {
                  console.log('SubcntrWrtcnsViewerPopup_Sign');
                  console.log(data);
                  opt.window.close();
                  this.$alert('서명이 완료되었습니다.');
                  this.onSearch();
                },
              });
            },
          });
        }
        console.log('btns', btns);
        console.log('signable', signable);
        console.log('signerSeCd', signerSeCd);
        console.log('tradeNo', tradeNo);

        axios
          .post('/ozrpt/selectOzQryReportFileName', param)
          .then(response => {
            // console.log('조회결과 response >>>', response);
            // console.log('조회결과 response.data >>>', response.data);

            this.OZParam.reportname = response.data.reportPathName;
            this.OZParam.odiname = response.data.odiNm;
            this.OZParam.OZFormParam = {
              signable: signable, // 필수(*):서명가능여부(Y/N)
              signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
              tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선코드(로그인한사용자)
              signMthCd: '',
              draftNo: data.wrtcnsNo,
              checkSeq: data.ceckSn,
              signDt: '',
              signData1: '',
              signData2: '',
              tmstmpIssuDt: '',
            };

            console.log('opt_param', opt_param);
            console.log('this.OZParam', this.OZParam);

            // eslint-disable-next-line
            opt = $open({
              componentPath: '@/view/wrtcns/subcntrWrtcns/components/popup/SubcntrWrtcnsView.vue', // 컴포넌트 경로 (필수)
              param: {
                ...opt_param,
                OZParam: this.OZParam,
                otisPage: this.otisPage,
              }, // 파라미터 (생략가능)
              btns: btns, // 팝업 버튼 (생략가능)
              name: 'SubcntrWrtcnsViewer', // 팝업 윈도우 이름 (생략가능)
              option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
              title: '동의서조회', // 팝업 제목 (생략가능)
            });
          })
          .catch(function(error) {
            console.log('========error====================');
            console.log(error);
          });
      },
      async onControlButton(type) {
        const me = this;

        console.log('wrtcnsNo', me.wrtcnsInfo.wrtcnsNo);

        me.focusKeys.wrtcnsNo = me.wrtcnsInfo.wrtcnsNo;
        me.focusKeys.ceckSn = me.wrtcnsInfo.ceckSn;

        console.log('SCD 관리자', $isAdmin());
        console.log('담당자', this.isEmp);

        if (!($isAdmin() || this.isEmp)) {
          this.$alert('SCD 관리자 또는 담당자만 해당 업무를 수행할 수 있습니다.');
          return;
        }

        // 업체송부 : 10
        // 완료 : 30
        // 공전소전송완료 : 40
        // 공전소전송실패 : 41
        var wrtcnsSttusCd = me.wrtcnsInfo.wrtcnsSttusCd;
        if (type == 'reject') {
          if (wrtcnsSttusCd != '10') {
            this.$alert('선택된 동의서는 회수 및 OKSS전송 할 수 없는 상태입니다.');
            return;
          }

          me.onOpenPopup(this.rejectModalId);
        }
      },
      onOpenPopup(modalNm) {
        this.modalNm = modalNm;
        this.$modal.show(modalNm);
      },
      onPopupConfirmSendToOkss() {
        console.log('modalNm', this.modalNm);
        if (this.modalNm == this.rejectModalId) {
          console.log('sendToOkss');
          this.callEvent({
            name: 'Reject_SendToOkss',
            param: data => {
              console.log('Reject_SendToOkss');
              console.log(data);
              this.$modal.hide(this.modalNm);
              this.onSearch();
            },
          });
        }
      },
    },
    computed: {
      isCanReject() {
        return this.focusKeys.wrtcnsSttusCd == '10' && ($isAdmin() || this.focusKeys.empId == JSON.parse(sessionStorage.getItem('userInfo')).empNo);
      },
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    },
  };
</script>
  
<style></style>
  