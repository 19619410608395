<template>
  <pmis-page :search-company-visible="false" :search-project-visible="false">
    <iui-container type="css-flex" theme="bullet">
      <i-row height="270px" ref="srchList">
        <i-col>
          <iui-container type="css-flex" theme="bullet">
            <i-row height="70px" ref="srchBox">
              <i-col>
                <iui-container type="table" theme="bullet">
                  <i-row>
                    <i-col-header>알림연계번호</i-col-header>
                    <i-col>
                      <iui-text name="ntcnCntcNo" @change="onSetSearchValue" @enter="onPreListSearch" />
                    </i-col>
                    <i-col-header>서식제목</i-col-header>
                    <i-col>
                      <iui-text name="formatSj" @change="onSetSearchValue" @enter="onPreListSearch" />
                    </i-col>
                    <i-col-header>서식설명내용</i-col-header>
                    <i-col>
                      <iui-text name="formatDcCn" @change="onSetSearchValue" @enter="onPreListSearch" />
                    </i-col>
                    <i-col-header>사용여부</i-col-header>
                    <i-col>
                      <iui-checkbox-group-old
                        name="useYn"
                        :items="[{label: '사용', value: 'Y'}]"
                        :checkedValues="[search.useYn]"
                        @change="onSetSearchValue"
                      />
                    </i-col>
                  </i-row>
                  <i-row>
                    <i-col-header>메인업무</i-col-header>
                    <i-col>
                      <iui-select-old
                        name="mainJobCd"
                        :value="search.mainJobCd"
                        :items="arrMainJobCd"
                        @change="onSetSearchValue"
                      />
                    </i-col>
                    <i-col-header>폼구분</i-col-header>
                    <i-col>
                      <iui-select-old
                        name="formDivCd"
                        :value="search.formDivCd"
                        :items="arrFormDivCd"
                        @change="onSetSearchValue"
                      />
                    </i-col>
                    <i-col-header>알림방법</i-col-header>
                    <i-col>
                      <iui-select-old
                        name="ntcnMthCd"
                        :value="search.ntcnMthCd"
                        :items="arrNtcnMthCd"
                        @change="onSetSearchValue"
                      />
                    </i-col>
                    <i-col-header>알림시점</i-col-header>
                    <i-col>
                      <iui-select-old
                        name="ntcnPnttmCd"
                        :value="search.ntcnPnttmCd"
                        :items="arrNtcnPnttmCd"
                        @change="onSetSearchValue"
                      />
                    </i-col>
                  </i-row>
                </iui-container>
              </i-col>
            </i-row>
            <i-row height="200px">
              <i-col>
                <pmis-content-box>
                  <template v-slot:title>
                    알림 서식 관리
                  </template>
                  <template v-slot:title-left>
                    <font style="color:#FF6666; font-weight: bold;"
                      >사용 중 일 수 있는 알림 서식 수정에는 주의를 부탁 드립니다.</font
                    >
                  </template>
                  <iui-container type="jqgrid" :id="sheetId" :loading="listBoxLoading" style="width:99.9%" />
                </pmis-content-box>
              </i-col>
            </i-row>
          </iui-container>
        </i-col>
      </i-row>
      <i-spacer />
      <i-row>
        <i-col>
          <iui-container type="table" :headerWidth="width200" style="height:100%">
            <i-row>
              <i-col-header>알림양식연번</i-col-header>
              <i-col>
                <iui-text :value="ntcnForm.ntcnFormsn" :enable="false" width="100px" />
              </i-col>
              <i-col-header>사용여부</i-col-header>
              <i-col>
                <iui-checkbox-group-old
                  name="useYn"
                  :items="[{label: '사용', value: 'Y'}]"
                  :checkedValues="[ntcnForm.useYn]"
                  @change="onSetValue"
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>메인업무명</i-col-header>
              <i-col>
                <iui-select-old
                  name="mainJobCd"
                  :value="ntcnForm.mainJobCd"
                  :items="arrMainJobCd"
                  @change="onSetValue"
                  required
                />
              </i-col>
              <i-col-header>폼구분명</i-col-header>
              <i-col>
                <iui-select-old
                  name="formDivCd"
                  :value="ntcnForm.formDivCd"
                  :items="arrFormDivCd"
                  @change="onSetValue"
                  required
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>알림방법명</i-col-header>
              <i-col>
                <iui-select-old
                  name="ntcnMthCd"
                  :value="ntcnForm.ntcnMthCd"
                  :items="arrNtcnMthCd"
                  @change="onSetValue"
                  required
                />
              </i-col>
              <i-col-header>알림시점명</i-col-header>
              <i-col>
                <iui-select-old
                  name="ntcnPnttmCd"
                  :value="ntcnForm.ntcnPnttmCd"
                  :items="arrNtcnPnttmCd"
                  @change="onSetValue"
                  required
                />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>알림연계번호(카카오톡인 경우)</i-col-header>
              <i-col>
                <iui-text name="ntcnCntcNo" :value="ntcnForm.ntcnCntcNo" @change="onSetValue" width="100px" />
              </i-col>
              <i-col-header>서식설명</i-col-header>
              <i-col>
                <iui-text name="formatDcCn" :value="ntcnForm.formatDcCn" @change="onSetValue" />
              </i-col>
            </i-row>
            <i-row>
              <i-col-header>서식제목</i-col-header>
              <i-col colspan="3">
                <iui-text name="formatSj" :value="ntcnForm.formatSj" @change="onSetValue" required />
              </i-col>
            </i-row>
            <i-row style="height:100%">
              <i-col-header>서식내용(이메일은 HTML가능)</i-col-header>
              <i-col colspan="3">
                <iui-text name="formatCn" type="multi" :value="ntcnForm.formatCn" @change="onSetValue" required />
              </i-col>
            </i-row>
          </iui-container>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
export default {
  beforeCreate() {},
  async created() {
    this.setCmmBtns(this.cmmBtns);

    this.arrMainJobCd = [];
    this.arrMainJobCd.push({text: '선택', value: ''});
    // eslint-disable-next-line
    (await $getCode(this, 'MAIN_JOB_CD')).forEach((item, index) => {
      this.arrMainJobCd.push({text: item.codeNm, value: item.code});
    });

    this.arrFormDivCd = [];
    this.arrFormDivCd.push({text: '선택', value: ''});
    // eslint-disable-next-line
    (await $getCode(this, 'FORM_DIV_CD')).forEach((item, index) => {
      this.arrFormDivCd.push({text: item.codeNm, value: item.code});
    });

    this.arrNtcnMthCd = [];
    this.arrNtcnMthCd.push({text: '선택', value: ''});
    // eslint-disable-next-line
    (await $getCode(this, 'NTCN_MTH_CD')).forEach((item, index) => {
      this.arrNtcnMthCd.push({text: item.codeNm, value: item.code});
    });

    this.arrNtcnPnttmCd = [];
    this.arrNtcnPnttmCd.push({text: '선택', value: ''});
    // eslint-disable-next-line
    (await $getCode(this, 'NTCN_PNTTM_CD')).forEach((item, index) => {
      this.arrNtcnPnttmCd.push({text: item.codeNm, value: item.code});
    });
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width170: '170px',
      width200: '200px',

      cmmBtns: [
        {name: '조회', class: 's', callback: this.onSearch},
        {name: '신규', class: 'b', callback: this.onAdd},
        {name: '저장', class: 'b', callback: this.onSave},
        {name: '삭제', class: 'b', callback: this.onDelete},
        {name: '출력', class: 'p', callback: this.onPrint},
      ],

      sheetId: 'sheet' + this._uid,

      arrMainJobCd: [],
      arrFormDivCd: [],
      arrNtcnMthCd: [],
      arrNtcnPnttmCd: [],

      search: {
        mainJobCd: '',
        formDivCd: '',
        ntcnMthCd: '',
        ntcnPnttmCd: '',
        ntcnCntcNo: '',
        formatSj: '',
        formatDcCn: '',
        useYn: 'Y',
      },

      listBoxLoading: false,

      focusKeys: {
        ntcnFormsn: '',
      },

      ntcnForm: {
        ntcnFormsn: '0',
        mainJobCd: '',
        formDivCd: '',
        ntcnMthCd: '',
        ntcnPnttmCd: '',
        ntcnCntcNo: '',
        formatSj: '',
        formatCn: '',
        formatDcCn: '',
        useYn: 'Y',
      },
    };
  },
  mounted() {
    const OPT = {
      Cols: [
        {name: 'ntcnFormsn', label: '알림양식연번', width: 50, align: 'left', hidden: true},
        {name: 'mainJobCd', label: '메인업무코드', width: 0, align: 'left', hidden: true},
        {name: 'mainJobNm', label: '메인업무명', width: 50, align: 'center'},
        {name: 'formDivCd', label: '폼구분코드', width: 0, align: 'left', hidden: true},
        {name: 'formDivNm', label: '폼구분명', width: 50, align: 'center'},
        {name: 'ntcnMthCd', label: '알림방법코드', width: 0, align: 'left', hidden: true},
        {name: 'ntcnMthNm', label: '알림방법명', width: 50, align: 'center'},
        {name: 'ntcnPnttmCd', label: '알림시점코드', width: 0, align: 'left', hidden: true},
        {name: 'ntcnPnttmNm', label: '알림시점명', width: 50, align: 'center'},
        {name: 'ntcnCntcNo', label: '알림연계번호', width: 50, align: 'left'},
        {name: 'formatSj', label: '서식제목', width: 150, align: 'left'},
        {name: 'useYn', label: '사용여부', width: 50, align: 'center'},
      ],
    };

    const me = this;
    var $grid = $('#' + me.sheetId);
    $grid.jqGrid({
      height: 120,
      // width:800,
      colModel: OPT.Cols,
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
      autowidth: true,
      shrinkToFit: true,
      // eslint-disable-next-line
      onCellSelect: function(rowId, iCol, cellContent, e) {
        var rowData = $('#' + me.sheetId).jqGrid('getRowData', rowId);
        me.focusKeys.ntcnFormsn = rowData.ntcnFormsn;
        me.onSearchDetail(rowData);
      },
    });

    $gridAutoResize($grid);
    window.addEventListener('resize', this.onResize);

    $initSrchShowButton({
      srchBox: this.$refs.srchBox,
      srchList: this.$refs.srchList,
    });

    this.onPreListSearch();
  },
  methods: {
    async onSearch() {
      console.log('onSearch');
      this.onPreListSearch();
    },
    async onAdd() {
      console.log('onAdd');
      console.log(this.ntcnForm);

      if (!(await this.$confirm('새로운 알림 서식을 등록하시겠습니까?'))) return;

      this.onReset();
    },
    onReset() {
      this.focusKeys.ntcnFormsn = '0';

      this.ntcnForm.ntcnFormsn = '0';
      this.ntcnForm.mainJobCd = '';
      this.ntcnForm.formDivCd = '';
      this.ntcnForm.ntcnMthCd = '';
      this.ntcnForm.ntcnPnttmCd = '';
      this.ntcnForm.ntcnCntcNo = '';
      this.ntcnForm.formatSj = '';
      this.ntcnForm.formatCn = '';
      this.ntcnForm.formatDcCn = '';
      this.ntcnForm.useYn = 'Y';

      console.log(this.ntcnForm);
    },
    async onSave() {
      console.log('onSave');
      if (!this.ntcnForm.mainJobCd.trim()) {
        this.$alert('메인업무를 선택해야합니다.');
        return;
      }

      if (!this.ntcnForm.formDivCd.trim()) {
        this.$alert('폼구분을 선택해야합니다.');
        return;
      }

      if (!this.ntcnForm.ntcnMthCd.trim()) {
        this.$alert('알림방법을 선택해야합니다.');
        return;
      }

      if (!this.ntcnForm.ntcnPnttmCd.trim()) {
        this.$alert('알림시점을 선택해야합니다.');
        return;
      }

      if (this.ntcnForm.ntcnMthCd == '10' && !this.ntcnForm.ntcnCntcNo.trim()) {
        this.$alert('알림방법이 카카오톡인 경우 알림연계번호를 입력해야합니다.');
        return;
      }

      if (!this.ntcnForm.formatSj.trim()) {
        this.$alert('서식제목을 입력해야합니다.');
        return;
      }

      if (!this.ntcnForm.formatCn.trim()) {
        this.$alert('서식내용을 입력해야합니다.');
        return;
      }

      if (this.ntcnForm.useYn == 'Y') {
        if (!(await this.$confirm('알림 서식을 저장하시겠습니까?'))) return;
      } else {
        if (
          !(await this.$confirm(
            '사용여부가 미사용으로 알림 서식을 저장하시겠습니까?<br/>사용중인 내용을 미사용으로 변경하는 경우 알림 발송이 안될 수 있습니다.'
          ))
        )
          return;
      }

      let param = {
        ...this.ntcnForm,
        chngPgmId: this.$store.getters['mdi/currentUrls'].url,
      };

      await axios.post('/notiForm/saveNtcnForm', param);
      if (this.ntcnForm.ntcnFormsn == 0) {
        this.onPreListSearch();
      } else {
        this.onListSearch();
      }
    },
    async onDelete() {
      console.log('onDelete');
      if (this.ntcnForm.ntcnFormsn == 0) {
        this.$alert('알림서식을 조회 후 삭제가 가능합니다.');
        return;
      }

      if (
        !(await this.$confirm(
          '알림 서식을 삭제하시겠습니까?<br/>사용중인 내용을 삭제하는 경우 알림 발송이 안될 수 있습니다.'
        ))
      )
        return;

      let param = {
        ...this.ntcnForm,
      };

      await axios.post('/notiForm/deleteNtcnForm', param);
      this.onPreListSearch();
    },
    async onPrint() {
      console.log('onPrint');
      this.$store.dispatch('mdi/doPrint');
    },
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      var $grid = $('#' + this.sheetId);
      var pobj = $grid
        .closest('.ui-jqgrid')
        .parent()
        .parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth - 1, false);
    },
    onSetSearchValue(obj, name) {
      let key = '';
      let value = '';
      if (typeof obj == 'string') {
        key = name;
        value = obj;
      } else {
        key = obj.target.name;
        value = obj.target.type == 'checkbox' ? (obj.target.checked ? 'Y' : 'N') : obj.target.value;
      }
      this.search[key] = value;
    },
    onSetValue(obj, name) {
      let key = '';
      let value = '';
      if (typeof obj == 'string') {
        key = name;
        value = obj;
      } else {
        key = obj.target.name;
        value = obj.target.type == 'checkbox' ? (obj.target.checked ? 'Y' : 'N') : obj.target.value;
      }
      this.ntcnForm[key] = value;
    },
    onPreListSearch() {
      this.focusKeys.ntcnFormsn = '0';
      this.onListSearch();
    },
    async onListSearch() {
      this.listBoxLoading = true;

      console.log(this.search);

      let param = {
        ...this.search,
      };

      let response = null;
      try {
        response = await axios.post('/notiForm/selectNtcnFormList', param);
        this.listBoxLoading = false;
        $('#' + this.sheetId)
          .clearGridData(true)
          .setGridParam({data: response.data})
          .trigger('reloadGrid');

        if (response.data.length == 0) {
          this.onReset();
        }

        var rowData;
        var ids = $('#' + this.sheetId).jqGrid('getDataIDs');
        if (ids && ids.length > 0) {
          if (this.focusKeys.ntcnFormsn > 0) {
            for (var i = 0; i <= ids.length - 1; i++) {
              rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[i]);
              if (rowData['ntcnFormsn'] == this.focusKeys.ntcnFormsn) {
                $('#' + this.sheetId).jqGrid('setSelection', ids[i], true);
                this.onSearchDetail(rowData);
                break;
              }
            }
          } else {
            $('#' + this.sheetId).jqGrid('setSelection', ids[0], true);
            rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[0]);
            this.onSearchDetail(rowData);
          }
        }
      } catch (ex) {
        console.log(ex);
        this.listBoxLoading = false;
      }
    },

    async onSearchDetail(rowData) {
      console.log(rowData);

      let param = {
        ntcnFormsn: rowData.ntcnFormsn,
      };

      let response = await axios.post('/notiForm/selectByNtcnFormsn', param);
      this.ntcnForm = response.data;
    },
  },
  computed: {},
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style></style>
