<template>
  <pmis-page :search-company-visible="false" :search-project-visible="false" @search-click="onSearch">
    <iui-container type="css-flex" theme="bullet">
      <i-row>
        <i-col style="padding:0px;">
          <iframe style="width:100%;height:99%;margin:0px;" ref="ifbi" border="0" frameborder="no"> </iframe>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
export default {
  beforeCreate() {},
  async created() {},
  data() {
    return {
      theme: 'bullet',
    };
  },
  mounted() {
    this.onSearch();
  },
  methods: {
    async onSearch() {
      try {
        const iframe_src =  (location.origin.indexOf('127.0.0.1') !== -1 || location.origin.indexOf('localhost') !== -1)?"http://econtracttest.kr.otis.com/":location.origin.replace("otis-se.com","otis.com");       
        this.$refs.ifbi.src = iframe_src + "/oseRateView";
      } catch (ex) {        
        console.log(ex);
      }
    },
  },
  computed: {},
  beforeDestroy() {},
};
</script>

<style></style>
