<template>
  <iui-container type="css-flex" theme="bullet">
    <i-row>
      <i-col>
        <WrtcnsView />
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import LinkWrtcnsStore from './store/LinkWrtcns.js';
import WrtcnsStore from './subcntrWrtcns/store/subcntrWrtcns.js';
import WrtcnsView from '@/view/wrtcns/components/linkwrtcns/WrtcnsView.vue';

export default {
  name: 'LinkWrtcns',
  components: {
    // eslint-disable-next-line
    WrtcnsView,
  },
  beforeCreate() {
    this.$store.dispatch('setStoreNm', 'LinkWrtcnsStore');
    $init(this, LinkWrtcnsStore);
    this.$store.dispatch('setStoreNm', 'WrtcnsStore');
    $init(this, WrtcnsStore);
  },
  data() {
    return {};
  },
  mounted() {
    this.checkAccessDeviceType();
  },
  methods: {
    ...mapMutations('login', ['setPage']),
    ...mapMutations('LinkWrtcnsStore', {
      setDecParams: 'setDecParams',
    }),
    async checkAccessDeviceType() {
      console.log('###############');
      console.log(window.params);

      let param = {
        encParam: window.params.encParam,
      };

      let response = await axios.post('/urlLink/decAndParseParam', param);
      console.log(response);
      console.log('###############');

      this.setDecParams(response.data);

      console.log('decParams', this.decParams);

      // TODO : 대리인 동의 테스트 후 주석 또는 삭제
      // this.decParams.isBuyagnt = true;

      var isIE = /*@cc_on!@*/ false || !!document.documentMode;
      if (isIE) {
        this.$alert('현재 인터넷 익스플로어(IE)로 접속하셨습니다.<br/>크롬(Chrome)브라우저 사용을 권고 드립니다.');
      }

      if (this.isWrtcns) {
        console.log('isWrtcns');
        this.callEvent({name: 'Wrtcns_Search', param: undefined});
      }
    },

    onLogin() {
      this.setPage('Login');
    },
  },
  computed: {
    ...mapGetters('LinkWrtcnsStore', {
      decParams: 'decParams',
    }),
    isWrtcns() {
      return window.params.shortUrlLinkType == 'WRTCNS';
    },
  },
};
</script>

<style scoped></style>
