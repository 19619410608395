<template>
  <pmis-page :search-company-visible="false" :search-project-visible="false">
    <iui-container type="css-flex">
      <i-row height="330px">
        <i-col>
          <SubcntrWrtcnsList customer-page @click="onClickListRow" @resetValues="onResetValues" />
        </i-col>
      </i-row>
      <i-spacer />
      <i-row>
        <i-col>
          <pmis-content-box :loading="contentBoxLoading">
            <iui-tab-old :comps="tabComps" :isAuto="false" @click="setTabIndex" />
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container>
  </pmis-page>
</template>

<script>
import store from '@/view/wrtcns/subcntrWrtcns/store/subcntrWrtcns.js';
import SubcntrWrtcnsList from './components/SubcntrWrtcnsList.vue';
export default {
  name: 'SubcntrWrtcns',
  components: {
    // eslint-disable-next-line
    SubcntrWrtcnsList,
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['searchInfo', 'wrtcnsList']);
    $mapMutations(this, [
      'setSearchInfo',
      'setWrtcnsList',
      'setWrtcnsInfo',
      'setMemberList',
      'resetAllValues',
    ]);
  },
  data() {
    return {
      cmmBtns: [
        {name: '조회', class: 's', callback: this.onSearch},
        // {name: '신규', class: 'b', callback: this.onAdd},
        // {name: '저장', class: 'b', callback: this.onSave},
        // {name: '삭제', class: 'b', callback: this.onDelete},
        {name: '출력', class: 'p', callback: this.onPrint},
      ],
      tabComps: [
        {
          tabNm: '고객',
          compNm: 'SubcntrWrtcns_Members',
          path: '@/view/wrtcns/subcntrWrtcns/components/tab/Members.vue',
        },
      ],
      tabIndex: 0,
      wrtcnsNo: '',
      ceckSn: '',
      contentBoxLoading: false,
    };
  },
  computed: {},
  created() {
    this.addEvent({name: 'SubcntrWrtcns_SearchDetail', func: this.onSearchDetail});
    this.setCmmBtns(this.cmmBtns);
  },
  mounted() {
    this.onSearch();
  },

  methods: {
    async onSearch() {
      this.callEvent({name: 'SubcntrWrtcns_Search'});
    },
    // async onAdd() {

    // },
    // async onSave() {

    // },
    // async onDelete() {

    // },
    async onPrint() {
      this.$store.dispatch('mdi/doPrint');
    },

    onClickListRow(row) {
      this.wrtcnsNo = row.wrtcnsNo;
      this.ceckSn = row.ceckSn;
      this.onSearchDetail();
    },
    async onSearchDetail() {
      this.contentBoxLoading = true;
      let param = {
        wrtcnsNo: this.wrtcnsNo,
        ceckSn: this.ceckSn,
        isCustomerPage: true,
      };
      let response = await axios.post('/wrtcns/selectAllInfo', param);
      this.contentBoxLoading = false;

      this.setWrtcnsInfo(response.data.wrtcnsInfo);
      this.setMemberList(response.data.memberList);

      this.onCallEvent();
    },
    setTabIndex(index) {
      this.tabIndex = index;
      this.callEvent({name: 'setActiveTab', param: this.tabIndex});
      this.onCallEvent();
    },
    onCallEvent() {
      if (this.tabIndex == 0) {
        this.callEvent({name: 'SubcntrWrtcnsTabMembers_Load', param: undefined});
      }
    },
    onResetValues() {
      console.log('onResetValues');
      this.resetAllValues();
      this.onCallEvent();
    },
  },
};
</script>

<style scoped></style>
