<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text
        type="text"
        name="cmnUNm"
        label="코드명"
        :value="searchInfo.cmnUNm"
        @change="onChange"
        @enter="onSearch"
      ></iui-text>
    </template>
    <template v-slot:header-right>
      <iui-button value="검색" class="btn" @click="onBeforeSearch" />
    </template>
    <iui-container type="jqgrid" :id="elId" style="width:99.9%" />
  </pmis-content-box>
</template>

<script>
// eslint-disable-next-line
import {mapGetters, mapMutations} from 'vuex';
export default { 
  beforeCreate() {
    $mapGetters(this, ['upperDetailInfo']);
    $mapMutations(this, ['setUpperDetailInfo' ]);
  },
  created() {
    this.addEvent([
      {name: 'upperCode_search', func: this.onBeforeSearch},
      {name: 'upperCode_add', func: this.onAdd},
      {name: 'upperCode_save', func: this.onSave},
      {name: 'upperCode_delete', func: this.onDelete},
    ]);
  },
  mounted() {
    this.initSheet();
  },
  data() {
    return {
      elId: 'sheetDiv' + this._uid,
      //sheetId: 'sheet' + this._uid,
      sheetId: 'sheet' + this.$store.getters['mdi/currentUrls'].menuId,
      searchInfo: {
        pgmCd : '',
        cmnUCd : 'ROOT',
        cmnUNm: '',
      },
      upperFocusKeyCode: '',
    };
  },
  methods: {
    ...mapMutations('store', {
      setUpperDetailInfo: 'setUpperDetailInfo'
    }),

    initSheet() {
      const OPT = {
        Cols: [
          {
            label: 'No',
            name: 'no',
            editable: false,
            align: 'center',
            width: 50,
          },
          {
            label: '코드',
            name: 'cmnDCd',
            edittype: 'text',
            ResultMask: '^[A-Za-z0-9]{0,20}$' /* 영문(대/소문자), 숫자 4자리 */,
            ResultMessage: '영문, 숫자 조합으로 20자리까지만 입력가능합니다.',
            align: 'left',
            width: 100,
            Required: 1,
            TextColor: '#0000FF',
            Cursor: 'pointer',
            editable: true,
          },
          {
            label: '코드명',
            name: 'cmnDNm',
            edittype: 'text',
            align: 'left',
            editable: true,
            width:150,
          },
          {
            label: '코드설명',
            edittype: 'text',
            name: 'rmk',
            type: 'text',
            align: 'left',
            editable: true,
            width:150,
          },
          {name: 'useDs', hidden: true},
          {name: 'cmnUCd', hidden: true},
          {name: 'isNew', hidden: true},
          {name: 'pgmCd', hidden: true},
          {name: 'cdSqn', hidden: true},
          {name: 'etc1', hidden: true},
          {name: 'etc2', hidden: true},
          {name: 'etc3', hidden: true},
          {name: 'etc4', hidden: true},
          {name: 'etc5', hidden: true},
          {name: 'cud', hidden: true}
        ], 
        Events: {
          onDataLoad: this.grid_onDataLoad,
          onClick: this.grid_onClick,
        },
      };

      var me = this;
      var $grid = $("#"+me.elId);


      $grid.jqGrid({
				height:200,
				width:200,
				autowidth:true,
				shrinkToFit:true,
				selectionMode : 'singlerow', 
				colModel: OPT.Cols,
				onSelectRow: function(rowid) {
          var $grid = $("#"+me.elId);
          var data = $grid.getRowData(rowid);
          if(rowid && rowid!==me.lastRowId && me.lastRowId) {
            $grid.saveRow(me.lastRowId);
            if(JSON.stringify($grid.getRowData(me.lastRowId))!=me.lastRowData ) {
              $grid.setCell(me.lastRowId, "cmnDCd",  me.nvl($grid.getCell(me.lastRowId,"cmnDCd")).toUpperCase(), false, false, true);
              if(data.cud!="C" && data.cud!="U") {
                $grid.setCell(me.lastRowId, "cud", "U");
              }
            }
            $grid.restoreRow(me.lastRowId);
          }
          $grid.setColProp("cmnDCd", { editable:data.cud=="C" });
          $grid.editRow(rowid,true);

          if(me.lastRowId != rowid) {
            data = $grid.getRowData(rowid);
            me.setUpperDetailInfo(data);
            me.callEvent({name: 'lowerCode_search', param: {pgmCd: me.userInfo.pgmCd, cmnUCd: data.cmnDCd }});
          }

          me.lastRowId = rowid;
          me.lastRowData = JSON.stringify($grid.getRowData(me.lastRowId));
				}
      });
      $gridAutoResize( $grid );
	},
    onBeforeSearch() {
      this.upperFocusKeyCode = '';
      this.onSearch();
    },
    async onSearch() {

      this.searchInfo.pgmCd = this.userInfo.pgmCd;
      let response = await axios.post('/baseCmmnCodeRegist/selectCodeList', {...this.searchInfo});
      var data = response.data;
      for(var i=0;i<data.length;i++) {
        data[i].no = (i+1);
        data[i].isNew = false;
        data[i].pgmCd = this.searchInfo.pgmCd;
      }
			const me = this;
			var $grid = $("#"+me.elId);

      me.deletedRows = [];

      $grid.jqGrid( 'setGridParam', { data:data }).trigger('reloadGrid');
    },

    onAdd() {
      var me = this;
      var $grid = $("#"+me.elId);

      var ids = $grid.getDataIDs();
      if(!ids) ids=[];

      var nextId = ids.length+1;

      var rowdata = {
        "cud" : "C",
        "cmnUCd": "ROOT",
        "cmnDCd": "",
        "useDs" : "Y",
        "pgmCd" : this.userInfo.pgmCd,
        "cdSqn" : 0
      }

      $grid.addRowData( nextId, rowdata, "last" );
      $grid.setColProp("cmnDCd", true);
      $grid.setSelection($grid.getDataIDs()[nextId-1], true);
    },
    async onSave() {
      var me = this;
      var $grid = $("#"+me.elId);

      if(me.lastRowId) {
        var data = $grid.getRowData(me.lastRowId);
        $grid.saveRow(me.lastRowId);
        if(JSON.stringify($grid.getRowData(me.lastRowId))!=me.lastRowData ) {
          $grid.setCell(me.lastRowId, "cmnDCd",  me.nvl($grid.getCell(me.lastRowId,"cmnDCd")).toUpperCase(), false, false, true);
          if(data.cud!="C" && data.cud!="U") {
            $grid.setCell(me.lastRowId, "cud", "U" );
          }
        }
        $grid.restoreRow(me.lastRowId);
      }
      // 입력값 체크
      var ids = $grid.getDataIDs();
      var cmnDCds = {};
      var dupCds = "";
      for(var i=0;i<ids.length;i++) {
        var row = $grid.getRowData(ids[i]);
        var cmnDCd = row.cmnDCd;
        var cmnDNm = row.cmnDNm;
        if(cmnDCd=='') {
          alert("코드를 입력하세요");
          $grid.setColProp("cmnDCd", { editable:row.cud=="C" });
          $grid.setSelection(ids[i], true);
          return;
        }
        if(cmnDNm=='') {
          alert("코드명을 입력하세요");
          $grid.setColProp("cmnDCd", { editable:row.cud=="C" });
          $grid.setSelection(ids[i], true);
          return;
        }
        if(cmnDCds[cmnDCd]) {
          dupCds += ", "+cmnDCd;
        }
        cmnDCds[cmnDCd] = row;
      }
      if( dupCds!='') {
        alert("중복된 코드가 존재합니다 : "+dupCds.substring(2));
        return;
      }

      var ncnt = 0;
      var datas = [];
      for(i=0;i<ids.length;i++) {
        row = $grid.getRowData(ids[i]);
        if(row.cud=="C"||row.cud=="U") {
          datas.push( row );
          ncnt++;
        }
      }
      if(me.deletedRows) {
        for(i=0;i<me.deletedRows.length;i++) {
          datas.push(me.deletedRows[i]);
          ncnt++;
        }
      }
      if(ncnt==0) {
        this.$alert("저장할 내용이 없습니다");
        return;
      }

      if (await this.$confirm('저장하시겠습니까?')) {
        var response = await axios
          .post('/baseCmmnCodeRegist/save', datas)
          .catch(e => console.error('저장에 실패했습니다. ', e));
        if (response.status == 200) {
          me.deletedRows = null;
          this.onSearch();
        } else {
          this.$alert('저장에 실패하였습니다.');
        }
      }
    },
    onDelete() {
      var me = this;
      var $grid = $("#"+me.elId);
      if(!me.deletedRows) {
        me.deletedRows = [];
      }
      var rowid = $grid.getGridParam("selrow");
      if(rowid) {
        var row = $grid.getRowData(rowid);
        if(row.cud!="C") {
          row.cud = "D";
          me.deletedRows.push(row);
        }
        var rix = $('#' + $.jgrid.jqID(rowid))[0].rowIndex-1;
        $grid.delRowData(rowid);
        var rowids = $grid.getDataIDs();
        if(rix>=0 && rix<rowids.length) {
          $grid.setSelection(rowids[rix], true);
        }
      }
    },
    onChange(e) {
      this.searchInfo.cmnUNm = e.target.value;
    },
    nvl(v) {
      return !v?"":v.toString();
    }
  },
};
</script>

<style scoped></style>