<template>
  <pmis-content-box > 
    <template v-slot:header-left >
      <span> ※ ODI 파일명은 QueryType일경우 사용되며 OZR 파일명과 동일하게 입력하세요(확장자제거).</span>
    </template>
    <template v-slot:header-right>
      <iui-button value="검색" class="btn" @click="onBeforeSearch" />
    </template>
    <iui-container type="jqgrid" :id="sheetId" style="width:99.9%" />
  </pmis-content-box>
</template>

<script>

export default {
  beforeCreate() {
    $mapGetters(this, ['reportMain']);
  },
  created() {
    this.addEvent([
      {name: 'hist_search', func: this.onSearch},
      {name: 'hist_add', func: this.onAdd},
      {name: 'hist_save', func: this.onSave},
      {name: 'hist_delete', func: this.onDelete},
    ]);
  },
  data() {
    return {
      //sheetId: 'sheet' + this._uid,
      sheetId: 'sheet' + this.$store.getters['mdi/currentUrls'].menuId + '_Hist',
      lastRowId: '',
      lowerFocusKeyCode: '',
      searchInfo: {rptNm: ''},
      saveRow : 0, //grid 저장셀 row
      saveCol : 0, //grid 저장셀 col
    };
  },
  beforeDestroy() {
      console.log("beforeDestroy...resize");
      window.removeEventListener('resize', this.resizeHandler); //resize 이벤트 제거
  },  
  async mounted() {
    //console.log("ready...resize");
    window.addEventListener('resize', this.resizeHandler); //resize 이벤트추가.

    var editoptionsYN = { value:"Y:사용;N:미사용" };
    // eslint-disable-next-line
    var formatterYN = function(v) { return v=='Y'?'사용':'미사용'; };
    const OPT = {
      Cols: [
        {
          label: '상태',
          name: '_rowStatusName',
          editable: false,
          align: 'center',
          width: 40,
        },        
        {
            label: 'No',
            name: 'no',
            editable: false,
            align: 'center',
            width: 45,
        },
        {
          label: '관리코드',
          name: 'manageCd',
          edittype: 'text',
          align: 'center',
          editable:false,
          width: 80,
        },
        {
          label: '이력순번',
          name: 'verNo',
          edittype: 'text',
          align: 'center',
          editable: false,
          width: 80,
        },
        {
          label: '사용여부',
          name: 'applcYn',
          edittype: 'select',
          align: 'center',
          editable: true,
          width: 80,
					editoptions: editoptionsYN,
					formatter: 'select',
        },
        {
          label: '사용시작일자',
          name: 'applcDate',  //yyyy-mm-dd 형태로 조회해야 포멧적용됨.
          //edittype: 'text',
          align: 'center',
          editable: true,
          width: 110,
          formatter: "date",
          //editrules : {date:true},
          //datefmt : "date",
          formatoptions: { 
            newformat: "Y-m-d",
            //srcformat: "Y-m-d" 
          },
          editoptions:{
            readonly : 'readonly',
            size:10, 
            dataInit:function(el){ 
              //$("#" + el.id).removeClass('hasDatepicker');
              console.log('dataInit======', el);
              console.log('dataInit======'+ el.id);
              //setTimeout(function(){
              //$(el).datepicker({ dateFormat: "yy-mm-dd", constraintinput:false});
              $(el).datepicker({
                closeText: '닫기',
                prevText: '이전달',
                nextText: '다음달',
                currentText: '오늘',
                monthNames: ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'],
                monthNamesShort: ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'],
                dayNames: ['일','월','화','수','목','금','토'],
                dayNamesShort: ['일','월','화','수','목','금','토'],
                dayNamesMin: ['일','월','화','수','목','금','토'],
                changeMonth: true,    //달력 셀렉트 박스 유무
                changeYear: true,    //년 셀렉트 박스 유무
                duration: 20,
                buttonImageOnly : false, //true이면 입력 필드 옆에 이미지만 표시됩니다. false이면 버튼 안에 이미지가 표시
                //buttonText : '', //button 에 표시되는 글자입니다.
                showOn: 'focus',	//focus, button, both 중에 선택할 수 있습니다. focus 는 포커스가 오면 달력이 팝업 됩니다. button 은 버튼을 클릭하면 달력이 팝업 됩니다. both는 두 가지 경우 모두에서 팝업 됩니다.
                //buttonImage : "../images/button/butt_calender.gif",
                dateFormat: "yy-mm-dd", //날짜 출력 형식
                // eslint-disable-next-line
                onSelect: function (dateText, inst) {
                  //console.log('formattedDate',formattedDate);
                  console.log('date',dateText);
                  //console.log('inst',inst);

                  console.log( 'datepicker==>R,C:' + me.saveRow +','+ me.saveCol);
                  $("#"+me.sheetId).jqGrid("saveCell", me.saveRow, me.saveCol);
                },
                onClose: function() {
                  console.log( 'datepicker==onclose~~');
                  //alert( $(this).datepicker().val() );

                }             
              }); 
              //}, 30);             
            }, 
            defaultValue: function(){ 
              var currentTime = new Date(); 
              var month = parseInt(currentTime.getMonth() + 1); 
              month = month <= 9 ? "0"+month : month; 
              var day = currentTime.getDate(); 
              day = day <= 9 ? "0"+day : day; 
              var year = currentTime.getFullYear(); 
              return year+"-"+month + "-"+day;
            }
          }
        },        
        {
          label: '오즈파일 서버경로(.ozr)',
          name: 'rptFlpth',
          type: 'text',
          align: 'left',
          width: 350,
          editable: true,
        },
        {
          label: 'ODI 파일명',
          name: 'odiNm',
          type: 'text',
          align: 'left',
          width: 100,
          editable: true,
        },
        {
          label: '변경사유',
          name: 'changeCn',
          type: 'text',
          align: 'left',
          //editrules: { required:true, integer:true },
          width: 200,
          editable: true,
        },        
        {
          label: '변경일자',
          name: 'chngDate',
          type: 'text',
          align: 'center',
          //editrules: { required:true, integer:true },
          width: 100,
          editable: false,
        },
        {
          label: '변경자',
          name: 'chngEmpName',
          type: 'text',
          align: 'center',
          //editrules: { required:true, integer:true },
          width: 100,
          editable: false,
        },
        {name: 'rptNm', hidden: true},
        {name: 'entryEmpId', hidden: true},
        {name: 'entryEmpName', hidden: true},
        {name: 'entryDate', hidden: true},
        {name: 'chngEmpId', hidden: true},
        {name: '_rowStatusCode', hidden: true}
      ],
      Events: {
        onDataLoad: this.grid_onDataLoad,
      },
    };

      var me = this;
      var $grid = $("#"+me.sheetId);
      this.sheet = $grid;

      console.log('grid_sheet_id='+"#"+me.sheetId);

      $grid.jqGrid({
				height:200,
				width:200,
				autowidth:true,
				shrinkToFit:true,
				selectionMode : 'singlerow', 
				colModel: OPT.Cols,
        onselectrow : true,
        reorderColumns : false, //컬럼이동속성
        reorderRows: false, //로우이동속성
        resizable : true, //컬럼크기조정가능속성.

        //rownumbers: true,  //row number 표시.
        //rownumWidth: 40,   //row number 표시.

        sortable: true,    //sort 기능
        loadonce : true,   //sort 기능

        cellEdit: true,
        cellsubmit: 'clientArray',        
				//onSelectRow: function(rowid) {
        onSelectCell: function(rowid) {
          var $grdHist = $("#"+me.sheetId);
          //var lastRowId = $grid.lastRowId;
          // eslint-disable-next-line
          var rowdata = $grdHist.getRowData(rowid);
          if(rowid && rowid!==me.lastRowId && me.lastRowId){
            $grdHist.setRowData( me.lastRowId, lastRowData );
            $grdHist.restoreRow(me.lastRowId);
          }
          //$grdHist.editRow(rowid,true);
          me.lastRowId = rowid;
          //var rowdata = $grid.getRowData(rowid);
					//me.callEvent({name: 'log_selectLoginLogList', param: {pgmCd: me.userInfo.pgmCd, userid: rowdata.userid}});
				}, 
        //수정건 수정으로표시.
        // eslint-disable-next-line
        afterSaveCell: function (rowid, name, val, iRow, iCol) {
        //beforeSaveCell: function (rowid, name, val, iRow, iCol) {
          console.log('afterSaveCell~~~');
          var $grdHist = $("#"+me.sheetId);
          if ($grdHist.jqGrid('getCell', rowid, "_rowStatusCode") == 'N') {
            $grdHist.jqGrid('setRowData', rowid, { _rowStatusCode: "U"});
            $grdHist.jqGrid('setRowData', rowid, { _rowStatusName: "U"});
          }
          //$(this).datepicker('destroy');
          return true;
        }, 
        //열려있는 상태에서도 저장이되게끔 해줘야함.
        afterEditCell:function(rowid, cellname, value, iRow, iCol){
        //beforeSaveCell:function(rowid, cellname, value, iRow, iCol){
          console.log('afterEditCell~~~');
          var $grdHist = $("#"+me.sheetId);

          me.saveRow = iRow;
          me.saveCol = iCol;
          console.log( '**afterEditCell:' +cellname+"="+me.saveRow +','+ me.saveCol);


          if(''!=cellname){ //datapicker
            $("#" + rowid + "_" + cellname).blur(function(){
                $grdHist.jqGrid("saveCell",iRow,iCol);
            });
          }
          
          //$(this).datepicker('destroy');

          //return true;
        },
			});
      $gridAutoResize( $grid );

  },
  methods: {
    // eslint-disable-next-line
    resizeHandler(e) {
      //console.log('resizeHandler >>>>>>>>');
      //console.log("curentWidth=="+$("#"+this.sheetId).width());

      var $grdHist = $("#"+this.sheetId);
      var parentWidth = $grdHist.closest(".ui-jqgrid").parent().parent().width();
      //console.log( "parentWidth=="+parentWidth);
      $grdHist.jqGrid( 'setGridWidth',  parentWidth - 10, true);

    },
    onBeforeSearch() {
      this.lowerFocusKeyCode = '';
      this.onSearch();
    },
    async onSearch(prm) {
			const me = this;
			var $grdHist = $("#"+me.sheetId);

      if(prm){
        this.searchInfo.manageCd = prm.manageCd;
      }
      let param = {  
          //manageCd: prm.manageCd,
          ...this.searchInfo
      };

console.log('onSearch>>>>>', param);

      $grdHist.jqGrid( 'clearGridData' );
      let response = await axios.post('/report/selectReportFileHist', param);
      var data = response.data;
      for(var i=0;i<data.length;i++) {
        data[i].no = (i+1);
        //data[i].pgmCd =  this.userInfo.pgmCd;
      }
      console.log('respose.data....>>>>>>', data);
      $grdHist.jqGrid( 'setGridParam', { data:data }).trigger('reloadGrid');
    },

    onAdd() {
      var me = this;
      var $grdHist = $("#"+me.sheetId);

      var ids = $grdHist.jqGrid('getDataIDs');
      console.log("getDataIDs>>>>", ids);
      for (var i=0;i<ids.length;i++) {
        var rowData =$grdHist.getRowData(ids[i]);
        //console.log("rowData>>>>", rowData);
        if (String(rowData._rowStatusCode).trim() == 'I') {
          this.$alert('신규 추가건이 존재합니다.<br/>저장 후 이력상세정보를 입력하세요.');
          return;
        }
      }      

      if (!this.reportMain.manageCd) {
        this.$alert('계약서 정보를 먼저 선택 또는 등록하고 입력하세요.');
        return;
      } else {
        // eslint-disable-next-line
        var ids = $grdHist.getDataIDs();
        if(!ids) ids=[];

        var nextId = ids.length+1;

        var currentTime = new Date(); 
        currentTime.setDate(currentTime.getDate() + 1);
        var month = parseInt(currentTime.getMonth() + 1); 
        month = month <= 9 ? "0"+month : month; 
        var day = currentTime.getDate(); 
        day = day <= 9 ? "0"+day : day; 
        var year = currentTime.getFullYear(); 
        var defaultDate = year+"-"+month + "-"+day;        

        var rowdata = {
          "manageCd": this.reportMain.manageCd,
          "rptNm": this.reportMain.rptNm,
          "applcYn" : "N",
          "applcDate" : defaultDate,
          "changeCn" : "",
          "_rowStatusCode": "I",
          "_rowStatusName": "I",          
        }
        // eslint-disable-next-line
        var addRow = $grdHist.addRowData( nextId, rowdata, "last" );    
      }

    },
    async onSave() {
      var $grdHist = $("#"+this.sheetId);
      var isChanged = false;
      //var currSelRow = $grdHist.getGridParam("selrow"); //현재선택된 로우.
      //$grdHist.jqGrid("saveRow", currSelRow); //보기모드로 변경. 안된다.

      console.log( 'onSave==>' + this.saveRow +','+ this.saveCol);
      $grdHist.jqGrid("saveCell", this.saveRow, this.saveCol);


      var ids = $grdHist.jqGrid('getDataIDs');
      if(ids){
        for (var i=0;i<ids.length;i++) {
          

          var rowStatus = String($grdHist.jqGrid('getCell',ids[i],'_rowStatusCode')).trim();
          if ('I,D,U'.indexOf(rowStatus) > -1) {
            isChanged = true;
            break;
          }
        }
      }
      //console.log("onSave:getRowData>>>>", grdData);
      //console.log("onSave:changeData>>>>", changeData);

      if(!isChanged){
        this.$alert('수정된 내용이 없습니다.');
        return;
      }

      //일단 다(all)보낸다.
      // eslint-disable-next-line
      for (var i=0; i< ids.length; i++){
        var grdRowData = $grdHist.getRowData(ids[i]);
        if ('I,U'.indexOf(grdRowData._rowStatusCode) < 0) continue;
        console.log("grdRowData",grdRowData);
        if (''==String(grdRowData.applcYn).trim()) {
          this.$alert('사용여부는 필수입력 항목입니다.');
          //$grdHist.jqGrid("editCell", i, 4, true); //사용여부
          return;
        }  
        if (''==String(grdRowData.rptFlpth).trim()) {
          this.$alert('파일경로명(.ozr)는 필수입력 항목입니다.');
          //$grdHist.jqGrid("editCell", i, 6, true); //파일경로
          return;
        }        
        if (''==String(grdRowData.applcDe).trim()) {
          this.$alert('사용시작일자는 필수입력 항목입니다.');
          //$grdHist.jqGrid("editCell", ids[i], 6, true); //사용시작일자
          return;
        }        
        if (''==String(grdRowData.changeCn).trim()) {
          this.$alert('변경사유는 필수입력 항목입니다.');
          //$grdHist.jqGrid("editCell", ids[i], 7, true); //변경사유
          return;
        }
      }//end for loop 


      if (await this.$confirm('저장하시겠습니까?')) {
        var response = await axios
          .post('/report/saveReportFileHist', $grdHist.getRowData())
          .catch(e => console.error('저장을 실패했습니다. ', e));

        if (response.status == 200) {
          //this.lowerFocusKeyCode = response.data;
          this.onSearch();
        } else {
          this.$alert('저장을 실패하였습니다.');
        }
      }

    },
    onDelete() {
      var $grdHist = $("#"+this.sheetId);
      var rowid = $grdHist.getGridParam("selrow");
      if(!rowid){
        this.$alert('선택된 항목이 없습니다.');
        return;
      }
      var data = $grdHist.getRowData(rowid);
      if('Y'==data.applcYn){
        this.$alert('사용중인 계약서 파일입니다.<br>사용여부를 확인하세요.');
        return;
      }

      $grdHist.jqGrid('setCell',rowid,'_rowStatusCode','D');
      $grdHist.jqGrid('setCell',rowid,'_rowStatusName','D');

      $grdHist.saveRow(rowid);
      $grdHist.restoreRow(rowid);
    },
    onChange(e) {
      console.log('onChange==============타나????????');
      this.searchInfo.manageCd = e.target.value;
    },
  },
};
</script>

<style scoped></style>
