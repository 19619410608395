const state = () => ({
    decParams : {
      ctrtNo : '',
      ctrtOdr : '',
      ctrtDivCd : '',
      tradeNo : '',
      tradeSfx : '',
      tradeType : '',
      estmtNo : '',
      estmtOdr : '',
      reqstdocNo : '',
      reqstdocOdr : '',
      reqstdocDivCd: '',
      wrtcnsNo: '',
      isBuyagnt : false,
      isPersonal : false,
    },
  });
  
  const getters = {
    decParams: state => state.decParams,
  };
  const mutations = {
    setDecParams(state, payload) {
      for (let key in state.decParams) {
        if (payload && payload.hasOwnProperty(key)) {
          state.decParams[key] = payload[key];
        }
      }
  
      let DP = state.decParams;
  
      DP.isBuyagnt = eval(DP.isBuyagnt);
      DP.isPersonal = eval(DP.isPersonal);
    },
  };
  const actions = {};
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
  };
  