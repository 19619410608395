<template>
  <iui-container type="css-flex">
    <i-row v-if="!isGoPwFind">
      <i-col>
        <pmis-content-box>
          <template v-slot:title>
            <span v-if="!identifyComplete">아이디찾기</span>
            <span v-if="identifyComplete">아이디(거래선번호)찾기결과</span>
          </template>
          <!-- 아이디 검색 table -->
          <iui-container v-if="!identifyComplete" type="table">
            <colgroup>
              <col width="150px" />
              <col />
            </colgroup>
            <i-row style="line-height: 30px;">
              <i-col-header>사업자번호/주민등록번호</i-col-header>
              <i-col>
                <iui-text
                  name="searchBsnNo"
                  placeholder="예) 123-45-67890"
                  maxLength="12"
                  :value.sync="searchData.searchBsnNo"
                  @keydown="searchBsnNoParse"
                  @keydown.enter="onSearchUserInfo"
                ></iui-text>
                <iui-text
                  name="searchPersonId"
                  placeholder="예) 701201-1234567"
                  maxLength="14"
                  :value.sync="searchData.searchPersonId"
                  @keydown="searchPersonIdParse"
                  @keydown.enter="onSearchUserInfo"
                ></iui-text>
                <iui-button @click="onSearchUserInfo">기본정보 조회</iui-button>
              </i-col>
            </i-row>
            <i-row style="line-height: 30px; display: none">
              <i-col-header>이름</i-col-header>
              <i-col>
                <iui-text
                  name="searchNm"
                  placeholder="예) 홍길동"
                  :value.sync="searchData.searchNm"
                  @keydown.enter="onSearchUserInfo"
                ></iui-text>
              </i-col>
            </i-row>

            <i-row style="line-height: 30px;">
              <i-col-header>등록된 전화번호</i-col-header>
              <i-col>
                <!-- 데이터  -->
                <div v-if="maskTelNoData != ''">
                  <iui-select-old
                    style="width: 190px;"
                    id="resultTelNo"
                    name="resultTelNo"
                    :items="maskTelNoData"
                    defaultCd="S"
                  >
                  </iui-select-old>
                </div>
                <div v-else>등록된 휴대폰 번호가 존재하지 않습니다.</div>
              </i-col>
            </i-row>

            <i-row style="line-height: 30px;">
              <i-col-header>등록된 email정보</i-col-header>
              <i-col>
                <!-- 데이터  -->
                <div v-if="maskEmailData != ''">
                  <iui-select-old
                    style="width: 190px;"
                    id="resultEmail"
                    name="resultEmail"
                    :items="maskEmailData"
                    defaultCd="S"
                  >
                  </iui-select-old>
                </div>
                <div v-else>등록된 이메일이 존재하지 않습니다.</div>
              </i-col>
            </i-row>
          </iui-container>

          <!-- 아이디 검색 버튼 -->
          <i-row v-if="!identifyComplete" style="justify-content: center; margin-top:20px;">
            <iui-button style="padding: 0 10px 0 10px;" @click="onSecuKitIdentify">공동인증서로 ID찾기</iui-button>
            <iui-button style="padding: 0 10px 0 10px;" @click="onProcessTelNo">등록된 전화번호로 ID찾기</iui-button>
            <iui-button style="padding: 0 10px 0 10px;" @click="onProcessEmail">등록된 email정보로 ID찾기</iui-button>
          </i-row>
          <i-row v-if="!identifyComplete" style="margin: 20px 0 0 0px;">
            <ul>
              <li style="margin: 0px 5px 5px 10px; line-height:18px;">
                * 검색시 <b>사업자번호 또는 주민등록번호 중 한 항목만 입력</b> 후 조회해주십시오. <br />
              </li>
              <li style="margin: 0px 5px 5px 10px; line-height:18px;">
                * 인증서 조회시 <b>개인사업자로 발급받은 공동인증서나 개인으로 발급받은 인증서만 가능합니다.</b><br />
              </li>
              <li style="margin: 0px 5px 5px 10px; line-height:18px;">
                * 만일 사업자번호/주민등록번호 <b>두 항목 모두 조회시에도 정보가 없을 경우, 또는 정보가 다르거나,</b>
                <br />
              </li>
              <li style="margin: 0px 5px 5px 10px; line-height:18px;">
                <b>변경이 필요한 경우</b> 영업사원에게 문의 해주시기 바랍니다.
              </li>
            </ul>
          </i-row>

          <!-- 아이디 찾기 결과 -->
          <iui-container v-if="identifyComplete" type="table" header-width="50px">
            <i-row>
              <i-col-header style="width: 150px; height: 50px;">조회 정보</i-col-header>
              <i-col v-if="isSignFind">
                공동인증서찾기 [ {{ this.searchData.searchBsnNo || this.searchData.searchPersonId }} ]</i-col
              >
              <i-col v-if="isTelNoFind"> 전화번호찾기 [ {{ this.maskTelNoData[this.userSelectIndex].text }} ]</i-col>
              <i-col v-if="isEmailFind"> 이메일찾기 [ {{ this.maskEmailData[this.userSelectIndex].text }} ]</i-col>
            </i-row>
            <i-row>
              <i-col-header style="width: 150px;"> 아이디(거래선번호) </i-col-header>
              <i-col v-if="!isEmailFind && this.resultData.length != 0">
                <!-- <span v-for="(item, index) in resultTelNoData" :key="index">
                    {{item.userId }} 
                  </span> -->

                <!-- 공동인증서가 아닐때(전화번호 찾기일때) -->
                <span v-if="!isSignFind && this.resultTelNoData.length > 0">
                  {{ this.resultTelNoData[this.userSelectIndex].userId }}
                </span>

                <!-- 공동인증서일 경우 (this.userSelectIndex = -1) -->
                <span v-if="isSignFind">
                  {{ this.resultData[0].userId }}
                </span>
              </i-col>
              <i-col v-if="isEmailFind" style="text-align: left;">
                선택하신 메일 [ {{ this.maskEmailData[this.userSelectIndex].text }} ] 으로 ID(거래선번호)를 송부
                드렸습니다. 잠시 뒤 메일을 확인 해주시기 바랍니다.<br />
                메일 수신이 되지 않을 경우 다른 방법으로 아이디 찾기를 진행해주시기 바랍니다.
              </i-col>
              <i-col v-if="this.resultData.length == 0">등록 정보가 존재하지 않습니다.</i-col>
            </i-row>
          </iui-container>

          <i-row v-if="identifyComplete" style="justify-content: center; margin-top:20px;">
            <iui-button style="padding: 0 10px 0 10px;" @click="goBeforePage">이전</iui-button>
            <iui-button style="padding: 0 10px 0 10px;" @click="onClosePop">창 닫기</iui-button>
            <iui-button v-if="this.resultData.length != 0" style="padding: 0 10px 0 10px;" @click="doFindPw"
              >비밀번호 찾기</iui-button
            >
          </i-row>
        </pmis-content-box>
      </i-col>
    </i-row>

    <!-- ID로 PW 찾기 -->
    <i-row v-if="isGoPwFind">
      <UserPwFind :fromIdFind="true" />
    </i-row>
  </iui-container>
</template>

<script>
import UserPwFind from '@/view/user/find/UserPwFind.vue';
export default {
  components: {
    UserPwFind,
  },
  data() {
    return {
      searchData: {
        searchBsnNo: '',
        searchPersonId: '',
        searchNm: '',
      },
      Identify: {
        KmCertPEM: '',
        LoginId: '',
        UserSignCert: '',
        EncryptedSessionKey: '',
        EncryptedUserRandomNumber: '',
        Message: '',
      },
      resultData: [], // 전체 검색결과 배열
      resultTelNoData: [], // 검색결과 TelNo 배열
      resultEmailData: [], // 검색결과 email 배열
      maskTelNoData: [], // 검색결과 TelNo *** 처리 배열
      maskEmailData: [], // 검색결과 email *** 처리 배열

      userSelectIndex: -1, // 선택 인덱스

      isEmailFind: false, // 이메일 인증여부
      isSignFind: false, // 인증서 인증여부
      isTelNoFind: false, // 전화번호 인증여부
      identifyComplete: false, // 본인인증 완료

      isGoPwFind: false, // ID찾기 > 비밀번호찾기 이동 여부
      isDoSearch: false, // 기본정보 조회 여부
      isLawFind: false, // 법인페이지 찾기 여부
      isPrsnFind: true, // 개인페이지 찾기 여부
    };
  },

  mounted() {
    this.$secuKit.checkReady();
  },
  methods: {
    // 정보 조회
    async onSearchUserInfo() {
      // 검색정보 초기화
      this.resultData = [];
      this.resultTelNoData = [];
      this.resultEmailData = [];
      this.maskTelNoData = [];
      this.maskEmailData = [];

      let bsnPattern1 = /[(0-9)]{3}-[(0-9)]{2}-[(0-9)]{5}/;
      let bsnPattern2 = /[(0-9)]{3}-8+[(0-9)]{1}-[(0-9)]{5}/; // 법인 사업자번호

      let telNoPattern = /^(01+[(0-9)])/; // 휴대폰번호 정규식 (01x 번호만 검색)
      let personIdPattern = /[(0-9)]{6}-[(0-9)]{7}/;

      if (this.searchData.searchBsnNo == '' && this.searchData.searchPersonId == '') {
        this.$alert('검색하실 사업자번호 또는 주민등록번호를 입력해주세요.');
        return;
      }

      if (this.searchData.searchBsnNo != '' && this.searchData.searchPersonId != '') {
        this.$alert('원활한 조회를 위해 사업자번호 또는 주민등록번호 한가지만 입력해주세요.');
        return;
      }

      if (this.searchData.searchBsnNo != '' && !bsnPattern1.test(this.searchData.searchBsnNo)) {
        this.$alert('올바른 사업자번호 양식을 입력해주세요.');
        return;
      }
      if (this.searchData.searchPersonId != '' && !personIdPattern.test(this.searchData.searchPersonId)) {
        this.$alert('올바른 주민등록번호 양식을 입력해주세요.');
        return;
      }
      if (
        this.searchData.searchBsnNo != '' &&
        bsnPattern1.test(this.searchData.searchBsnNo) &&
        bsnPattern2.test(this.searchData.searchBsnNo)
      ) {
        this.$alert('개인으로 등록된 사업자번호를 입력해주세요.');
        return;
      }

      let parseBsnSearch = this.searchData.searchBsnNo.replace(/-/g, '');
      let parsePersonIdSearch = this.searchData.searchPersonId.replace(/-/g, '');
      //let searchNm = this.searchData.searchNm; // 이후 이름 검색용

      const response = await axios.post('/userInfo/selectUserInfoList', {
        searchBsnNo: parseBsnSearch,
        searchPersonId: parsePersonIdSearch,
        isPrsnFind: this.isPrsnFind,
      });

      this.resultData = response.data;
      this.resultData.forEach(element => {
        var i = 0;
        // 전화번호 검사
        if (element.telNo) {
          let chkTelNo = element.telNo
            .replace(/ /gi, '')
            .replace(/-/gi, '')
            .replace(/\(/gi, '')
            .replace(/\)/gi, '');
          // 01X 로 시작하는 번호들만 추출(010- ~ 019-)
          if (telNoPattern.test(chkTelNo) && (chkTelNo.length == 10 || chkTelNo.length == 11)) {
            // chkTelNo masking
            let maskTelNo = chkTelNo.substring(0, chkTelNo.length - 3) + '***';

            if (this.resultTelNoData.length == 0) {
              this.resultTelNoData.push({
                telNo: chkTelNo,
                userId: element.userId,
                userNm: element.repntName.replace(/ /gi, ''),
              }); // *** 처리 전 항목 push
              this.maskTelNoData.push({text: maskTelNo, value: this.resultTelNoData.length - 1}); // *** 처리된 항목 push
            } else if (this.resultTelNoData.length > 0) {
              let canPush = true; // 저장가능 여부 T/F(중복체크)
              for (i = 0; i < this.resultTelNoData.length; i++) {
                if (chkTelNo == this.resultTelNoData[i].telNo && element.userId == this.resultTelNoData[i].userId) {
                  canPush = false;
                  break;
                }
              }
              if (canPush) {
                this.resultTelNoData.push({
                  telNo: chkTelNo,
                  userId: element.userId,
                  userNm: element.repntName.replace(/ /gi, ''),
                }); // *** 처리 전 항목 push
                this.maskTelNoData.push({text: maskTelNo, value: this.resultTelNoData.length - 1}); // *** 처리된 항목 push
              }
            }
          }
        }
        // 이메일 검사
        if (element.email) {
          let checkEmail = element.email.replace(/ /gi, '');
          let EmailPattern = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i; // 이메일 패턴
          // xxx@xx.xx 등으로 맵핑되는 항목들 추출
          if (EmailPattern.test(checkEmail)) {
            let maskEmail = '***' + element.email.substring(3, element.email.length);

            if (this.resultEmailData.length == 0) {
              this.resultEmailData.push({
                email: element.email,
                userId: element.userId,
                userNm: element.repntName.replace(/ /gi, ''),
              }); // *** 처리 전 항목 push
              this.maskEmailData.push({text: maskEmail, value: this.resultEmailData.length - 1}); // *** 처리된 항목 push
            } else if (this.resultEmailData.length > 0) {
              let canPush2 = true; // 저장가능 여부 T/F(중복체크)
              for (i = 0; i < this.resultEmailData.length; i++) {
                if (checkEmail == this.resultEmailData[i].email && element.userId == this.resultEmailData[i].userId) {
                  canPush2 = false;
                  break;
                }
              }
              if (canPush2) {
                this.resultEmailData.push({
                  email: element.email,
                  userId: element.userId,
                  userNm: element.repntName.replace(/ /gi, ''),
                }); // *** 처리 전 항목 push
                this.maskEmailData.push({text: maskEmail, value: this.resultEmailData.length - 1}); // *** 처리된 항목 push
              }
            }
          }
        }
      });

      this.isDoSearch = true;
      // selectBoxIndex 초기화시에 시점 차이 떄문에 setTimeOut 설정
      setTimeout(() => {
        if (this.maskTelNoData.length == 0 && this.maskEmailData.length == 0) {
          this.$alert('등록된 전화번호, 이메일이 존재하지 않습니다.');
        }
        this.setDefaultSelect();
      }, 100);
    },

    // 검색시 selectBoxIndex 초기화
    setDefaultSelect() {
      $('#resultTelNo_select').prop('selectedIndex', 0);
      $('#resultEmail_select').prop('selectedIndex', 0);
    },

    // 사업자번호입력시 '-' 처리
    searchBsnNoParse(e) {
      var length = this.searchData.searchBsnNo.length;
      if (e.key == 'Backspace') {
        if (length == 5 || length == 8) {
          this.searchData.searchBsnNo = this.searchData.searchBsnNo.substring(0, length - 1);
        }
      } else {
        if (length == 3 || length == 6) {
          this.searchData.searchBsnNo += '-';
        }
      }
    },
    // 주민등록번호 - 처리
    searchPersonIdParse(e) {
      var length = this.searchData.searchPersonId.length;
      if (e.key == 'Backspace') {
        if (length == 8) {
          this.searchData.searchPersonId = this.searchData.searchPersonId.substring(0, length - 1);
        }
      } else {
        if (length == 6) {
          this.searchData.searchPersonId += '-';
        }
      }
    },

    // 공동인증서로 본인확인
    async onSecuKitIdentify() {
      if (!this.isDoSearch) {
        this.$alert('기본정보를 먼저 조회해주세요.');
        return;
      }
      let bsnPattern1 = /[(0-9)]{3}-[(0-9)]{2}-[(0-9)]{5}/; // 개인 사업자번호
      let bsnPattern2 = /[(0-9)]{3}-8+[(0-9)]{1}-[(0-9)]{5}/; // 법인 사업자번호 (80~89)

      let personIdPattern = /[(0-9)]{6}-[(0-9)]{7}/;

      if (this.searchData.searchBsnNo != '' && !bsnPattern1.test(this.searchData.searchBsnNo)) {
        this.$alert('올바른 사업자번호 양식을 입력해주세요.');
        return;
      }
      if (this.searchData.searchPersonId != '' && !personIdPattern.test(this.searchData.searchPersonId)) {
        this.$alert('올바른 주민등록번호 양식을 입력해주세요.');
        return;
      }

      if (
        this.searchData.searchBsnNo != '' &&
        bsnPattern1.test(this.searchData.searchBsnNo) &&
        bsnPattern2.test(this.searchData.searchBsnNo)
      ) {
        this.$alert('개인으로 등록된 사업자번호를 입력해주세요.');
        return;
      }
      let response = await axios.post('/userInfo/kmCertPem');
      this.Identify.KmCertPEM = response.data;
      // 인증서 툴킷 init
      let checkReady = this.$secuKit.secuKitReady;
      console.log('checkReady = ' + checkReady);

      if (!checkReady) {
        this.$alert('공동인증서 툴킷 로드에 실패하였습니다.');
        return;
      }

      const r1 = await this.$secuKit.showDialog();
      console.log('r1', r1);

      const certID = this.$secuKit.certListInfo.getCertID();
      console.log('certID', certID);

      let data1 = {
        certType: 'SignCert',
        certID: certID,
        isViewVID: '1', // 0 : VID 추출 안함,  1 : VID 추출
      };

      // 인증서 정보 받아오기
      const r2 = await this.$secuKit.invoke('viewCertInfomationWithVID', data1);
      console.log('r2 :', r2);

      let userDn = r2.userDN; // 인증서 userDn
      let UserSignCert = r2.certPEM; // SignCert 정보 (--begin~~~)
      let vidRandom = r2.vidRandom; // 신원확인 정보 rvalue

      let data2 = {
        algorithm: 'SEED/CBC/PADDING', // 암호화 알고리즘
        keyName: certID,
        sourceString: vidRandom,
      };

      const r3 = await this.$secuKit.invoke('symmetricEncryptData', data2);
      console.log('r3 :', r3); //r3.symmetricEncryptData; // 신원확인 정보를 대칭키 암호화

      let data3 = {
        serverCert: removePEMHeader(removeCRLF(this.Identify.KmCertPEM)), // 서버 인증서 정보
        rsaVersion: 'V15', // RSA 버전 (V15 / V21)
        keyName: certID,
      };

      const r4 = await this.$secuKit.invoke('getSymmetricKeyIV', data3);
      console.log('r4 :', r4); //r4.getSymmetricKeyIV; // 서버 인증서로 대칭키 암호화에 사용된 키 RSA 암호화 추출

      this.Identify.UserSignCert = UserSignCert;
      this.Identify.EncryptedSessionKey = r4.getSymmetricKeyIV;
      this.Identify.EncryptedUserRandomNumber = r3.symmetricEncryptData;

      let param = {
        ssn: this.searchData.searchBsnNo.replace(/-/gi, ''),
        personId: this.searchData.searchPersonId.replace(/-/gi, ''),
        userSignCert: this.Identify.UserSignCert,
        encryptedSessionKey: this.Identify.EncryptedSessionKey,
        encryptedUserRandomNumber: this.Identify.EncryptedUserRandomNumber,
        userDn,
      };

      let response2 = await axios.post('/userInfo/identifyUserCert', param);
      console.log('identifyUserCert : ', response2);

      if (response2.data.bolVerify == true) {
        this.Identify.Message = response2.data.subjectDN + '\n' + response2.data.serialNumber;
        this.$alert('공동인증서 본인인증에 성공하였습니다.');
        this.isSignFind = true;
        this.identifyComplete = true;
      } else {
        this.Identify.Message = response2.data.errorMessage;
        this.$alert('공동인증서 본인인증에 실패하였습니다.' + '\n' + this.Identify.Message);
      }
    },
    // 모바일으로 ID찾기 ( PASS )
    async onProcessTelNo() {
      if (!this.isDoSearch) {
        this.$alert('기본정보를 먼저 조회해주세요.');
        return;
      }

      this.userSelectIndex = $('#resultTelNo_select option:selected').val();
      if (!this.userSelectIndex || this.userSelectIndex == '') {
        if (this.maskTelNoData.length <= 0) {
          this.$alert('등록된 휴대폰 번호가 존재하지 않습니다.');
        } else {
          this.$alert('휴대폰 인증을 위한 번호를 선택해주세요.');
        }

        return;
      }

      //PASS 인증 호출 ( 휴대폰 번호만 비교 )
      var passResult = await this.$PASSCertificationByFindID(this.resultTelNoData[this.userSelectIndex].telNo);
      if (passResult.result) {
        //telNo - 알림톡 전송
        await axios.post('/userInfo/sendIdFindResult', {
          userId: this.resultTelNoData[this.userSelectIndex].userId,
          findType: 'telNoFind',
          telNo: this.resultTelNoData[this.userSelectIndex].telNo,
          email: '',
        });
        this.$alert('PASS 본인 인증에 성공하였습니다.');
        this.isTelNoFind = true;
        this.identifyComplete = true;
      } else {
        this.$alert('PASS 본인 인증에 실패하였습니다.');
        return;
      }

      // 테스트시 인증 x 진행하기
      // const response = await axios.post('/userInfo/sendIdFindResult',{userId: this.resultTelNoData[this.userSelectIndex].userId, findType: "telNoFind" , telNo: this.resultTelNoData[this.userSelectIndex].telNo, email: "" });
      // this.isTelNoFind = true;
      // this.identifyComplete = true;
    },

    // 이메일로 ID찾기 -> 메일 바로 발송
    async onProcessEmail() {
      if (!this.isDoSearch) {
        this.$alert('기본정보를 먼저 조회해주세요.');
        return;
      }

      this.userSelectIndex = $('#resultEmail_select option:selected').val();

      if (!this.userSelectIndex || this.userSelectIndex == '') {
        if (this.maskEmailData.length <= 0) {
          this.$alert('등록된 이메일이 존재하지 않습니다.');
        } else {
          this.$alert('메일을 발송 받으실 이메일을 선택해주세요.');
        }
        return;
      }
      // email - 전송
      await axios.post('/userInfo/sendIdFindResult', {
        userId: this.resultEmailData[this.userSelectIndex].userId,
        findType: 'emailFind',
        telNo: '',
        email: this.resultEmailData[this.userSelectIndex].email,
      });
      this.isEmailFind = true;
      this.identifyComplete = true;
    },

    //창 닫기
    onClosePop() {
      this.$modal.hide('idFind');
    },

    //검색 ID로 비밀번호 찾기
    doFindPw() {
      this.isGoPwFind = true; // 페이지 헤더 표시조절 인자값
    },

    goBeforePage() {
      // 입력, 검색값들 초기화
      this.userSelectIndex = -1;

      this.resultData = [];
      this.resultTelNoData = [];
      this.resultEmailData = [];
      this.maskTelNoData = [];
      this.maskEmailData = [];

      this.isSignFind = false;
      this.isEmailFind = false;
      this.isTelNoFind = false;
      this.identifyComplete = false;
      this.isDoSearch = false;
    },
  },
};
</script>

<style></style>
