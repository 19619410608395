<template>
  <pmis-content-box :loading="listBoxLoading">
    <iui-container type="table">
      <i-row>
        <i-col-header>서면/전자 구분</i-col-header>
        <i-col>
          <iui-radio-group-old
            name="radio"
            :items="radioItem"
            :value="cnfrmnMain.onlineYn"
            :enable="!this.readonly"
            @change="cnfrmnMain.onlineYn = $event.target.value"
          ></iui-radio-group-old>
        </i-col>
        <i-col-header>고객 날인일</i-col-header>
        <i-col>
          <iui-datepicker-old
            name="docmntSlngDe"
            :value="cnfrmnMain.docmntSlngDe"
            :enable="!this.readonly"
            @change="onSetValue($event, 'docmntSlngDe')"
            required
          />
        </i-col>
      </i-row>
      <i-row>
        <i-col-header>확인서 첨부</i-col-header>
        <i-col colspan="3">
          {{ fileUpDown_files.lclFlNm }}
          <iui-button @click="fileUpDown_search = true" v-if="false">조회</iui-button>
          <iui-button @click="fileUpDown_add = true" v-show="!this.readonly && !fileUpDown_files.lclFlNm"
            >추가</iui-button
          >
          <iui-button
            @click="fileUpDown_delete = true"
            v-show="!this.readonly && fileUpDown_files.flNo > 0 && fileUpDown_files.lclFlNm"
            >삭제</iui-button
          >
          <iui-button @click="fileUpDown_save = true" v-if="false">저장</iui-button>
          <iui-button @click="fileUpDown_download = true" v-if="fileUpDown_files.flNo > 0 && fileUpDown_files.svrFlPth"
            >다운</iui-button
          >
          <file-up-down
            company-code="201900"
            accept="application/pdf"
            v-model="fileUpDown_files"
            :file-number.sync="cnfrmnMain.fileNo"
            add-button-text="파일추가"
            delete-button-text="파일삭제"
            save-button-text="파일저장"
            download-button-text="파일다운"
            :add.sync="fileUpDown_add"
            :changed.sync="fileUpDown_changed"
            :delete.sync="fileUpDown_delete"
            :save.sync="fileUpDown_save"
            :search.sync="fileUpDown_search"
            :download.sync="fileUpDown_download"
            @save-complete="file_save_complete"
            @save-error="file_save_error"
            @delete-complete="file_delete_complete"
            @delete-error="file_delete_error"
            @delete-start="file_delete_start"
          />
        </i-col>
      </i-row>
    </iui-container>
    <div style="font-size:12px;margin-top:3px;">
      * 확인서 사진 증빙은 PDF로 1개의 파일로 첨부해주시기 바랍니다.
    </div>
    <div style="font-size:12px;margin-top:3px;">
      * 새로운 파일을 첨부하시면 기존파일을 삭제됩니다.
    </div>
    <div style="font-size:12px;margin-top:3px;">
      * 온라인 → 서면으로 변경하여 임시저장하시면 서면 확인서 출력이 가능합니다.
    </div>
    <div style="font-size:12px;margin-top:3px;">
      * 서면 → 온라인으로 변경하려면 임시저장하시면 됩니다.(완료 전 상태에서 가능합니다.)
    </div>
  </pmis-content-box>
</template>

<script>
import FileUpDown from '@/view/sample/FileUpDown';
export default {
  components: {
    FileUpDown,
  },
  beforeCreate() {
    $mapGetters(this, ['modalOnoffline']);
  },
  created() {
    this.addEvent({name: 'Onoffline_Save', func: this.onSave});
    this.addEvent({name: 'Onoffline_Complete', func: this.onComplete});
    this.addEvent({name: 'Onoffline_DeleteFile', func: this.onDeleteFile}); //20211208 파일삭제용 추가
  },
  data() {
    return {
      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width120: '120px',
      width170: '170px',
      width200: '200px',

      listBoxLoading: false,

      callback: '',

      readonly: false,

      radioItem: [
        {label: '전자', value: 'Y', isChecked: false},
        {label: '서면', value: 'N', isChecked: false},
      ],

      cnfrmnMain: {
        cnfrmnNo: '',
        cnfrmnSttusCd: '',
        onlineYn: 'Y',
        docmntSlngDe: '',
        fileNo: 0,
        cmpnsDivNm: '', ////20211111 lgksja
        isFileUpdate: false, // 이력 추가 ITSR62600
      },

      fileUpDown_files: {
        pgmCd: '',
        flNo: '',
        flDs: '',
        prjCd: '',
        fbs: '',
        lclFlNm: '',
        flSz: '',
      },
      fileUpDown_add: false,
      fileUpDown_changed: false,
      fileUpDown_delete: false,
      fileUpDown_save: false,
      fileUpDown_search: false,
      fileUpDown_download: false,

      btnMode: '',
    };
  },
  mounted() {
    this.readonly = this.modalOnoffline.readonly;
    this.cnfrmnMain.cnfrmnNo = this.modalOnoffline.cnfrmnNo;

    if (this.cnfrmnMain.cnfrmnNo !== undefined && this.cnfrmnMain.cnfrmnNo != '') {
      this.onSearch();
    }
  },
  methods: {
    onSetValue(obj, name) {
      // 변경값 SET
      let key = '';
      let value = '';
      if (typeof obj == 'string') {
        key = name;
        value = obj;
      } else {
        key = obj.target.name;
        value = obj.target.type == 'checkbox' ? (obj.target.checked ? 'Y' : '') : obj.target.value;
      }
      this.cnfrmnMain[key] = value;
      if (key == 'docmntSlngDe') {
        console.log('this.cnfrmnMain', this.cnfrmnMain);
        //20211111 lgksja 유상일경우만 알럿
        if (
          this.cnfrmnMain.cmpnsDivNm == '유상' &&
          this.cnfrmnMain.docmntSlngDe !== undefined &&
          this.cnfrmnMain.docmntSlngDe != null &&
          this.cnfrmnMain.docmntSlngDe != ''
        ) {
          this.$alert('선택하신 고객 날인일이 세금계산서 발행일이 됩니다');
        }
      }
    },
    async onSearch() {
      let param = {
        cnfrmnNo: this.cnfrmnMain.cnfrmnNo,
      };
      try {
        this.listBoxLoading = true;
        let response = await axios.post('/confdoc/selectCnfrmnMain', param);
        this.listBoxLoading = false;
        this.cnfrmnMain = response.data;
        if (this.cnfrmnMain.cnfrmnSttusCd == '50') {
          this.readonly = true;
        }

        //20211111 lgksja 완료가 아닌경우 유상이 아니면 오늘 날짜 기본세팅
        if (
          this.cnfrmnMain.cmpnsDivNm !== '유상' &&
          this.cnfrmnMain.cnfrmnSttusCd !== '50' &&
          this.cnfrmnMain.docmntSlngDe == ''
        ) {
          this.cnfrmnMain.docmntSlngDe = $_getCurrentDate();
        }
        //20211111 lgksja 작성중일때 서면 기본 체크
        if (this.cnfrmnMain.cnfrmnSttusCd == '10' && (this.cnfrmnMain.docmntSlngDe == null || this.cnfrmnMain.docmntSlngDe == '')) {
          this.cnfrmnMain.onlineYn = 'N';
        }
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    validationDocmntSlngDe() {
      if (
        this.cnfrmnMain.docmntSlngDe !== undefined &&
        this.cnfrmnMain.docmntSlngDe != null &&
        this.cnfrmnMain.docmntSlngDe != ''
      ) {
        var currDate = $_getCurrentDate();
        var docmntSlngDe = this.cnfrmnMain.docmntSlngDe;

        //console.log('currDate', currDate);
        // console.log('docmntSlngDe', docmntSlngDe);
        // console.log('parseInt(currDate) < parseInt(docmntSlngDe)', parseInt(currDate) < parseInt(docmntSlngDe));

        if (parseInt(currDate) < parseInt(docmntSlngDe)) {
          this.$alert('고객 날인일은 오늘 또는 오늘보다 이전 날짜여야 합니다.');
          return false;
        }
        //20210901 lgksja 날짜 조건 변경 ~S
        if (parseInt(currDate.substring(6, 8)) <= 7) {
          // 오늘이 8일 이전이면 고객날인일은 전월 1~31일 부터 금월 1일~금일날짜 까지만 입력 가능
          var stdDate = $_addMonth(currDate, -1);
          var stdStartDate = stdDate.substring(0, 6) + '01';
          //var stdEndDate    = $_addDay($_addMonth(stdStartDate, 1), -1);
          var stdEndDate2 = $_addDay($_addMonth(stdStartDate, 1), +6); //이번달 7일까지 가능하게

          //console.log('stdDate', stdDate);
          //console.log('stdStartDate', stdStartDate);
          //console.log('stdEndDate', stdEndDate);
          //console.log('parseInt(stdStartDate) <= parseInt(docmntSlngDe)', parseInt(stdStartDate) <= parseInt(docmntSlngDe));
          //console.log('parseInt(stdEndDate) >= parseInt(docmntSlngDe)', parseInt(stdEndDate) >= parseInt(docmntSlngDe));
          //console.log('parseInt(stdEndDate2) >= parseInt(docmntSlngDe)', parseInt(stdEndDate2) >= parseInt(docmntSlngDe));
          //console.log('stdEndDate2', stdEndDate2);

          if (!(parseInt(stdStartDate) <= parseInt(docmntSlngDe) && parseInt(stdEndDate2) >= parseInt(docmntSlngDe))) {
            // this.$alert('당월 7일까지만 고객 날인일은 전월로 입력 할 수 있습니다.');
            // return false;
            //엑셀93번 요청
            //this.$alert('세금계산서는 공급시기 (=수리공사/부품교체 완료일)에 지체없이 발급하여야 하며 늦어도 공급시기가 속하는 달의 다음달 10일까지 발급해야 합니다.<br>(부가가치세법 제15~17조 [재화 및 용역의 공급시기])<br><br>완료확인서를 지연수취 / 지연등록 할 경우 계산서 지연발행에 따른 가산세가 부과되므로, 반드시 기한 내 등록하시기 바랍니다.');
            return 'A';
          }
        } else {
          // 오늘이 8일 이후라면 이번달 까지만 입력 가능
          if (
            !(
              parseInt(currDate.substring(0, 6) + '01') <= parseInt(docmntSlngDe) &&
              parseInt($_addDay($_addMonth(currDate.substring(0, 6) + '01', 1), -1)) >= parseInt(docmntSlngDe)
            )
          ) {
            // this.$alert('당월 7일까지만 고객 날인일은 전월로 입력 할 수 있습니다.');
            // return false;
            //20211125 lgksja 엑셀93번 요청
            //this.$alert('세금계산서는 공급시기 (=수리공사/부품교체 완료일)에 지체없이 발급하여야 하며 늦어도 공급시기가 속하는 달의 다음달 10일까지 발급해야 합니다.<br>(부가가치세법 제15~17조 [재화 및 용역의 공급시기])<br><br>완료확인서를 지연수취 / 지연등록 할 경우 계산서 지연발행에 따른 가산세가 부과되므로, 반드시 기한 내 등록하시기 바랍니다.');
            return 'A';
          }
        }
        /*
        if (parseInt(currDate.substring(6,8)) < 7){  // 오늘이 7일 이전이면 전월 1~31일일 까지만 입력 가능
          var stdDate       = $_addMonth(currDate, -1);
          var stdStartDate  = stdDate.substring(0,6) + "01";
          var stdEndDate    = $_addDay($_addMonth(stdStartDate, 1), -1);

          // console.log('stdDate', stdDate);
          // console.log('stdStartDate', stdStartDate);
          // console.log('stdEndDate', stdEndDate);
          // console.log('parseInt(stdStartDate) <= parseInt(docmntSlngDe)', parseInt(stdStartDate) <= parseInt(docmntSlngDe));
          // console.log('parseInt(stdEndDate) >= parseInt(docmntSlngDe)', parseInt(stdEndDate) >= parseInt(docmntSlngDe));

          if (!(parseInt(stdStartDate) <= parseInt(docmntSlngDe) && parseInt(stdEndDate) >= parseInt(docmntSlngDe))) {
            this.$alert('오늘이 7일 이전이면 고객 날인일은 전월 1일부터 말일 까지만 입력 가능 합니다.');
            return false;
          }
        } else { // 오늘이 7일 이후라면 이번달 까지만 입력 가능
          if (
              !(parseInt(currDate.substring(0,6) + "01") <= parseInt(docmntSlngDe) 
                && parseInt($_addDay($_addMonth(currDate.substring(0,6) + "01", 1), -1)) >= parseInt(docmntSlngDe)
                )
              ) {
                this.$alert('오늘이 7일 이후라면 고객 날인일은 이번달 안에서만 입력이 가능합니다.');
                return false;
          }
        }
        */
        //20210901 lgksja 날짜 조건 변경 ~S
      }
      return true;
    },
    async onSave(callback) {
      if (this.readonly) {
        this.$alert('읽기모드로 조회 중입니다.');
        return;
      }

      this.btnMode = 'save';
      if (this.cnfrmnMain.onlineYn == 'N') {
        if (this.cnfrmnMain.cmpnsDivNm == '유상') {
          //20211209 엑셀 90번
          let validation = await this.$validator.validate(this);
          if (!validation.isValid) {
            this.$alert('입력되지 않은 필수항목이 있습니다.<br/>다시 한 번 확인하시기 바랍니다.');
            return;
          }

          if (this.cnfrmnMain.docmntSlngDe <= 0) {
            this.$alert('고객 날인일을 입력해야 합니다.');
            return;
          }
        }

        if (this.fileUpDown_files.lclFlNm === undefined || this.fileUpDown_files.lclFlNm == '') {
          this.$alert('확인서 파일을 첨부해야 합니다.');
          return;
        }
      }

      if (this.cnfrmnMain.cmpnsDivNm == '유상') {
        //20211209 엑셀 90번
        //20211125 lgksja 엑셀93번 요청
        let validationDate = await this.validationDocmntSlngDe();
        if (!validationDate) {
          return;
        }
        if (validationDate == 'A') {
          await this.$alert(
            '세금계산서는 공급시기 (=수리공사/부품교체 완료일)에 지체없이 발급하여야 하며 늦어도 공급시기가 속하는 달의 다음달 10일까지 발급해야 합니다.<br>(부가가치세법 제15~17조 [재화 및 용역의 공급시기])<br><br>완료확인서를 지연수취 / 지연등록 할 경우 계산서 지연발행에 따른 가산세가 부과되므로, 반드시 기한 내 등록하시기 바랍니다.'
          );
        }
        /*
        if (!this.validationDocmntSlngDe()) {        
          return;
        }*/
        //20211125 lgksja 엑셀93번 요청
      }

      if (!(await this.$confirm('저장하시겠습니까?'))) return;

      try {
        this.listBoxLoading = true;
        this.callback = callback;
        if (this.fileUpDown_files.lclFlNm !== undefined && this.fileUpDown_files.lclFlNm != '') {
          setTimeout(() => {
            this.fileUpDown_save = true;
          }, 300);
          return;
        } else {
          let param = {
            ...this.cnfrmnMain,
            chngPgmId: this.$store.getters['mdi/currentUrls'].url,
          };

          let response = await axios.post('/confdoc/updateCnfrmnMain', param);
          console.log(response);
          this.listBoxLoading = false;
          callback(response);
        }
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    async onComplete(callback) {
      this.btnMode = 'complete';
      if (this.cnfrmnMain.onlineYn != 'N') {
        this.$alert('온/오프 구분이 오프인 경우에만 진행이 가능합니다.');
        return;
      }

      if(this.cnfrmnMain.cnfrmnDivCd == '10' && this.cnfrmnMain.cmpnsDivNm == '유상'){ //부품교체이고 ITSR64294 부품교체 확인서 업로드시  거래선코드 없을시 팝업 요청
        if(this.cnfrmnMain.tradeNo.trim() == "" || this.cnfrmnMain.tradeNo == null){
          this.$alert('거래선 번호가 등록된 경우에만 진행이 가능합니다.<br>해당청구의 거래선을 oksis에서 입력해주세요.');
          return;
        }
      }

      let _msg = '';
      if (this.cnfrmnMain.cmpnsDivNm == '유상') {
        //20211209 엑셀 90번
        _msg = '고객 날인일이 세금계산서 발행일이 됩니다.<br/>';
        let validation = await this.$validator.validate(this);
        if (!validation.isValid) {
          this.$alert('입력되지 않은 필수항목이 있습니다.<br/>다시 한 번 확인하시기 바랍니다.');
          return;
        }

        if (this.cnfrmnMain.docmntSlngDe <= 0) {
          this.$alert('고객 날인일을 입력해야 합니다.');
          return;
        }
        //20211125 lgksja 엑셀93번 요청
        let validationDate = await this.validationDocmntSlngDe();
        //console.log("validationDate",validationDate);
        if (!validationDate) {
          return;
        }
        if (validationDate == 'A') {
          await this.$alert(
            '세금계산서는 공급시기 (=수리공사/부품교체 완료일)에 지체없이 발급하여야 하며 늦어도 공급시기가 속하는 달의 다음달 10일까지 발급해야 합니다.<br>(부가가치세법 제15~17조 [재화 및 용역의 공급시기])<br><br>완료확인서를 지연수취 / 지연등록 할 경우 계산서 지연발행에 따른 가산세가 부과되므로, 반드시 기한 내 등록하시기 바랍니다.'
          );
        }
      }
      /*
      if (!this.validationDocmntSlngDe()) {
        // this.$alert('고객 날인일은 전월 1~31일 까지만 입력이 가능합니다.');
        return;
      }*/
      //20211125 lgksja 엑셀93번 요청

      if (this.fileUpDown_files.lclFlNm === undefined || this.fileUpDown_files.lclFlNm == '') {
        this.$alert('확인서 파일을 첨부해야 합니다.');
        return;
      }

      if (!(await this.$confirm(_msg + '오프라인 확인서를 완료 하시겠습니까?'))) return;

      try {
        this.listBoxLoading = true;
        this.callback = callback;
        console.log('this.fileUpDown_files', this.fileUpDown_files);
        if (
          /*this.cnfrmnMain.fileNo == 0 && 20211209 일단 빼보자 저장에도 없으니*/ this.fileUpDown_files.lclFlNm !==
            undefined &&
          this.fileUpDown_files.lclFlNm != ''
        ) {
          setTimeout(() => {
            this.fileUpDown_save = true;
          }, 300);
          return;
        } else {
          let param = {
            ...this.cnfrmnMain,
            pgmCd: this.userInfo.pgmCd,
            chngPgmId: this.$store.getters['mdi/currentUrls'].url,
          };

          let response = await axios.post('/confdoc/updateCompleteCnfrmnMain', param);
          console.log(response);
          this.listBoxLoading = false;
          callback(response);
        }
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    async file_save_complete() {
      console.log('파일 저장이 완료되었습니다.');

      if (this.fileUpDown_files.lclFlNm === undefined || this.fileUpDown_files.lclFlNm == '') {
        this.cnfrmnMain.fileNo = 0;
      } else {
        this.cnfrmnMain.fileNo = this.fileUpDown_files.flNo;
      }
      this.cnfrmnMain.isFileUpdate = false; // 이력 추가 ITSR62600

      let param = {
        ...this.cnfrmnMain,
        chngPgmId: this.$store.getters['mdi/currentUrls'].url,
      };

      try {
        let url = '';
        if (this.btnMode == 'save') {
          url = '/confdoc/updateCnfrmnMain';
        } else if (this.btnMode == 'complete') {
          url = '/confdoc/updateCompleteCnfrmnMain';
        }

        let response = await axios.post(url, param);
        console.log(response);
        this.listBoxLoading = false;
        this.callback(response);
      } catch (ex) {
        this.listBoxLoading = false;
      }
    },
    async onDeleteFile(callback) {
      this.callback = callback;
      this.btnMode = 'save';
      await this.file_save_complete();
    },
    file_save_error() {
      this.listBoxLoading = false;
      console.log('파일 저장을 실패했습니다.');
    },
    file_delete_complete() {
      this.listBoxLoading = false;
      console.log('파일 삭제가 완료되었습니다.');
      //20211208 파일삭제 백그라운드 리로드
      this.fileUpDown_files.lclFlNm = '';
      console.log('this.modalOnoffline.onofflineModalId', this.modalOnoffline);
      $('#fileDelete_' + this.modalOnoffline.onofflineModalId).trigger('click'); //onDeleteFile 호출
    },
    file_delete_error() {
      this.listBoxLoading = false;
      console.log('파일 삭제를 실패했습니다.');
    },
    file_delete_start() {
      this.listBoxLoading = true;
      console.log('파일 삭제 시작');
    },
  },
};
</script>

<style></style>
