<template>
	<pmis-page ref="page" :search-company-visible="false" :search-project-visible="false" @search-click="onSearch">
		<iui-container type="css-flex">
			<i-row ref="srchList">
				<i-col>
					<iui-container type="css-flex" theme="bullet">
						<i-row height="70px" ref="srchBox">
							<i-col>
								<iui-container type="table" theme="bullet">
									<i-row>
										<i-col-header style="width: 80px">구분</i-col-header>
										<i-col style="width: 450px">
											<iui-select-old
												id="searchCheckStatus"
												name="searchCheckStatus"
												:items="checkStatusArray"
												defaultCd="A"
												@change="setSearchValue('searchCheckStatus', $event)"
											></iui-select-old>
											<iui-checkbox
												v-model="checkBoxStatusArray"
												label="발송완료"
												value=""
												@change="changeCheckbox"
											/>
											<iui-checkbox
												v-model="checkBoxStatusArray"
												label="서명완료"
												value="01"
												@change="changeCheckbox"
											/>
											<iui-checkbox
												v-model="checkBoxStatusArray"
												label="등록중"
												value="02"
												@change="changeCheckbox"
											/>
											<iui-checkbox
												v-model="checkBoxStatusArray"
												label="출금가능"
												value="03"
												@change="changeCheckbox"
											/>
										</i-col>
										<i-col-header>건물명</i-col-header>
										<i-col>
											<iui-text name="searchBuldNm" :value.sync="searchInfo.searchBuldNm" @enter="onPreSearch" />
										</i-col>
										<i-col-header>영업담당자</i-col-header>
										<i-col>
											<iui-text name="searchContEmpNm" :value.sync="searchInfo.searchContEmpNm" @enter="onPreSearch" />
										</i-col>
										<i-col style="float: right">
											<iui-button @click="searchClear"> 검색조건 초기화 </iui-button>
										</i-col>
									</i-row>
									<i-row>
										<i-col-header>계약일</i-col-header>
										<i-col>
											<span style='white-space:nowrap'>
												<iui-datepicker-old name="checkContDateStart" suffix="~" @change="onSetValue($event, 'checkContDateStart')" :value.sync="searchInfo.checkContDateStart" />
												&nbsp;<iui-datepicker-old name="checkContDateEnd" @change="onSetValue($event, 'checkContDateEnd')" :value.sync="searchInfo.checkContDateEnd" />
											</span> 
											<!-- <iui-datepicker-old
												name="yyyyMm"
												type="month"
												:value="searchInfo.yyyyMm"
												:enable="true"
												@change="onSetValue($event, 'yyyyMm')"
												required
											/> -->
										</i-col>
										<i-col-header>계약번호</i-col-header>
										<i-col>
											<iui-text name="searchCtrtNo" :value.sync="searchInfo.searchCtrtNo" @enter="onPreSearch" />
										</i-col>
									</i-row>
								</iui-container>
							</i-col>
						</i-row>

						<!-- 계약별 이체방법 현황 -->
						<i-row >
							<i-col>
								<pmis-content-box>
									<template v-slot:title>
										계약별 이체방법 현황
									</template>
									<iui-container type="jqgrid" :id="sheetId" :loading="listBoxLoading" style="width:99.9%" />
								</pmis-content-box>
							</i-col>
						</i-row>
						<!-- CMS 신청 작성 -->
						<i-row height="330px">
							<i-col>
								<pmis-content-box>
									<template v-slot:title>
										CMS 신청 작성
									</template>

									<iui-container type="table">
										<colgroup>
											<col width="130px" />
											<col />
											<col width="130px" />
											<col />
										</colgroup>
										<i-row>
											<i-col-header>수납기관명</i-col-header>
											<i-col colspan="3">
												<i-row style="width: 99.9%; text-align:left;">
													오티스서비스엑스퍼트 유한회사
													<!-- {{this.requestInfo.bcncnm}} -->
												</i-row>
											</i-col>
										</i-row>

										<i-row>
											<i-col-header>대표자</i-col-header>
											<i-col>
												<i-row>
													대표이사 장두호
													<!-- {{this.requestInfo.rprsntv}} -->
												</i-row>
											</i-col>
											<i-col-header>사업자등록번호</i-col-header>
											<i-col>
												<i-row>
													835-87-01968
													<!-- {{this.requestInfo.bizrno}} -->
												</i-row>
											</i-col>
										</i-row>

										<i-row>
											<i-col-header>용도</i-col-header>
											<i-col>
												<i-row>
													유지관리료
													<!-- {{this.requestInfo.prposCn}} -->
												</i-row>
											</i-col>
											<i-col-header>주소</i-col-header>
											<i-col>
												<i-row style="font-size:11px;">
													서울 강동구 성내로6길 11, 2층(성내동, 삼원타워)
													<!-- {{this.requestInfo.adresCn}} -->
												</i-row>
											</i-col>
										</i-row>

										<i-row>
											<i-col-header>출금계좌 예금주명</i-col-header>
											<i-col>
												<iui-text
													:value.sync="requestInfo.dpstrNm"
													placeholder=" 출금계좌 예금주명"
													:readonly="isOtisPage"
												></iui-text>
											</i-col>
											<i-col-header>예금주생년월일<br />(사업자등록번호)</i-col-header>
											<i-col>
												<iui-text
													:value.sync="requestInfo.dpstrBizrno"
													placeholder=" 생년월일 또는 사업자번호"
													:readonly="isOtisPage"
												></iui-text>
											</i-col>
										</i-row>

										<i-row>
											<i-col-header>출금은행</i-col-header>
											<i-col>
												<iui-select-old
													id="bankCd"
													name="bankCd"
													:items="bankCdArray"
													defaultCd="S"
													:enable="!isOtisPage"
													@change="onSetRequestInfoValue('bankCd', $event)"
												></iui-select-old>
											</i-col>
											<i-col-header>출금계좌번호</i-col-header>
											<i-col>
												<iui-text
													:value.sync="requestInfo.acnutNo"
													placeholder=" 출금계좌번호"
													maxLength="20"
													:readonly="isOtisPage"
												></iui-text>
											</i-col>
										</i-row>

										<i-row>
											<!-- <i-col-header>예금주유선전화</i-col-header>
											<i-col>
												<iui-text
													:value.sync="requestInfo.dpstrTelno"
													placeholder=" 예금주유선전화"
													maxLength="20"
													:readonly="isOtisPage"
												></iui-text>
											</i-col> -->
											<i-col-header>예금주휴대전화</i-col-header>
											<i-col>
												<iui-text
													:value.sync="requestInfo.dpstrMoblphonno"
													placeholder=" 예) 01012345678"
													:readonly="isOtisPage"
												></iui-text>
											</i-col>
											<i-col-header>신청인명</i-col-header>
											<i-col>
												{{ this.requestInfo.applcntNm }}
												<!-- <iui-text :value.sync="requestInfo.applcntNm" placeholder=" 신청인명" readonly></iui-text> -->
											</i-col>
										</i-row>

										<i-row>
											
											<i-col-header>출금일자</i-col-header>
											<i-col>
												당월
												<iui-select-old
													id="dwdDe"
													name="dwdDe"
													:items="dwdDeArray"
													defaultCd="S"
													:enable="!isOtisPage"
													@change="onSetRequestInfoValue('dwdDe', $event)"
													style="margin-left : 10px"
												></iui-select-old>
											</i-col>
											<i-col-header>거래선코드</i-col-header>
											<i-col >
												<i-row>
													<!-- 7자리-2자리 -->
													{{ this.requestInfo.tradeNo }}-{{ this.requestInfo.tradeSfx }}
												</i-row>
											</i-col>
										</i-row>

										<i-row>
											
											<i-col-header>서명일시</i-col-header>
											<i-col>
												<span v-if="this.requestInfo.progrsSttusCd == '20'">{{ this.requestInfo.signDt }}</span>
												<span v-else>미서명</span>
												<!-- <iui-text :value.sync="requestInfo.applcntNm" placeholder=" 신청인명" readonly></iui-text> -->
											</i-col>
										
											<i-col-header>방법 / IP</i-col-header>
											<i-col>
												<iui-text
													:value.sync="requestInfo.signMthNm"
													placeholder="미서명"
													readonly="readonly"
												></iui-text>
												<iui-text
													:value.sync="requestInfo.accesIp"
													placeholder="미서명"
													readonly="readonly"
												></iui-text>
											</i-col>
										</i-row>
										<!-- 불능사유 있을때만 표시 -->
										<i-row v-if="requestInfo.failStatus">
											<i-col-header>등록 불능 사유</i-col-header>
											<i-col colspan="3">
												<i-row>
													{{ this.requestInfo.failStatus }}
												</i-row>
											</i-col>
										</i-row>
									</iui-container>
									<div style="font-size:12px;margin-top:3px;">
										※ 수리공사 자동이체는 영업사원에게 문의 해주세요.
									</div>
								</pmis-content-box>
							</i-col>
						</i-row>
						<!-- 저장 및 전자서명 -->
						<i-row v-if="isCustomerPage" height="40px">
							<i-col>
								<div style="display: flex;">
									<p style="margin: 0px auto; margin-top:10px">
										<!-- <span style="margin-left: 10px;" v-if="!requestInfo.failStatus && requestInfo.progrsSttusCd == '20'"><iui-button style="margin: 0px auto;" @click="onSave">변경 및 전자서명</iui-button></span> -->
										<!-- 신규작성 -->
										<!--span
											style="margin-left: 10px;"
											v-if="requestInfo.cmsStatusCd == '' && requestInfo.progrsSttusCd != '20'"
											><iui-button style="margin: 0px auto;" @click="onSave">저장 및 전자서명</iui-button></span
										-->
										<!-- 재작성 (해지) -->
										<!--span
											style="margin-left: 10px;"
											v-if="requestInfo.cmsStatusCd == '09' && requestInfo.progrsSttusCd == '20'"
											><iui-button style="margin: 0px auto;" @click="onSave">재작성 및 전자서명</iui-button></span
										-->
									</p>
								</div>
							</i-col>
						</i-row>
					</iui-container>
				</i-col>
			</i-row>
		</iui-container>
	</pmis-page>
</template>

<script>
export default {
	data() {
		return {
			cmmBtns: [
				//{name: '조회', class: 's', callback: this.onSearch},
				// {name: '신규', class: 'b', callback: this.onAdd},
				// {name: '저장', class: 'b', callback: this.onSave},
				// {name: '삭제', class: 'b', callback: this.onDelete},
				//{name: '출력', class: 'p', callback: this.onPrint},
			],
			// 검색 정보
			searchInfo: {
				searchBuldNm: '', // 건물명
				searchContEmpNm: '', // 영업담당자
				searchCheckStatus: '', // 구분 (신청서 상태 - 신청완료/작성중/미작성 )
				searchCct: '', // CC
				searchCtrtNo: '', // 계약 번호
				checkContDateStart: '', // 계약 시작일
				checkContDateEnd: '', // 계약 종료일
				applyNewYn: '',
				summaryYm: '',
				//yyyyMm: '', // 계약 달
				isOtisPage: true, // 담당자 페이지
				isCustomerPage: false, // 고객 페이지

				checkBoxStatusArray: '',//신청서 상태 배열로 검색 추가
			},
			// 구분 (신청서 상태)
			checkStatusArray: [
				//{text: '신청중', value: '01'}, // 서명 완료 상태 (PROGRS_STTUS_CD = '20')
				{text: '발송완료', value: '98'},
				{text: '서명완료', value: '01'},
				{text: '등록중', value: '02'},
				{text: '출금가능', value: '03'},
				{text: '등록불능', value: '04'},
				{text: '변경신청중', value: '05'},
				{text: '변경중', value: '06'},
				{text: '해지신청중', value: '07'},
				{text: '해지중', value: '08'},
				{text: '해지완료', value: '09'}, // 신규(변경) 신청 가능
				{text: '해지불가', value: '10'},
				
				//{text: '작성중', value: '98'}, // 서명 전 작성중 상태 (PROGRS_STTUS_CD = '10')
				//{text: "미작성",      value: "99"}, // 신청서 데이터 X , 신규 신청 가능 (21.04.27 담당자페이지는 주석처리함)
			],
			cctArray: [], //CCT 배열
			bankCdArray: [], // 출금은행 배열
			dwdDeArray: [], // 출금일자 배열

			checkBoxStatusArray:[],//신청서 상태 배열로 검색 추가
			// 신청정보
			requestInfo: {
				reqstdocNo: '', // 신청서 번호 (계약번호)
				reqstdocOdr: -1, // 신청서 차수
				ctrtNo: '', // 계약 번호
				contEmpId: '', // 영업 담당자 ID
				tradeNo: '', // 거래선번호
				tradeSfx: '', // 거래선SUFFIX
				tradeName: '', // 거래선명
				buldNm: '', // 계약명 (건물명)
				bcncnm: '', // 거래처명 (수납기관명)
				rprsntv: '', // 대표자
				bizrno: '', // 사업자번호
				prposCn: '', // 용도 내용
				adresCn: '', // 주소 내용
				dpstrNm: '', // 예금주 명
				dpstrBizrno: '', // 예금주 사업자번호
				dpstrTelno: '', // 예금주 전화번호
				dpstrMoblphonno: '', // 예금주 휴대전화번호
				bankCd: '', // 은행코드
				bankNm: '', // 은행이름
				acnutNo: '', // 계좌 번호
				dwdDe: '', // 출금일 일자
				progrsSttusCd: '', // 진행상태 (10 : 작성중 , 20 : 신청완료(서명완료))
				reqstDt: '', // 신청일
				infoUseAgreYn: 'X', // 정보 이용 동의 여부
				infoProvdAgreYn: 'X', // 정보 제공 동의 여부 (Y / N / X )- 동의 / 미동의 / 선택 X
				applcntNm: '', // 신청인명
				reqstdocDivCd: 'CMS', // 신청서 구분 코드
				checkContDate: '', // 계약일
				ctrtDivCd: '',
				repntName: '', // 거래선 대표자명 (신청인 명으로 들어감)
				isOtisPage: true, // 담당자 페이지
				isCustomerPage: false, // 고객 페이지
				notiType: 'REQALL', // 신청정보 알림
				cmsStatusCd: '', // CMS 상태
				cmsRegFailCd: '', // CMS 금결원 실패 코드
				failStatus: '', // CMS 금결원 실패 메세지

				signDt : '',//신청일자
				signMthNm : '',//서명방법
				accesIp :'' ,//서명IP
			},

			// 포커싱
			focusKeys: {
				reqstdocNo: '', // 신청서 번호  (계약 번호)
				reqstdocOdr: -1, // 신청서 차수
				ctrtNo: '', // 계약 번호
				ctrtOdr: '',
				ctrtDivCd: '', // 계약 종류
				contractType: '',
				signType: '',
				tradeNo: '',
				tradeSfx : '',
				rowKey : '',

			},

			sheetId: 'sheet' + this.$store.getters['mdi/currentUrls'].menuId, // jqGrid ID
			listBoxLoading: false,
			isProcessing: false, // 저장 무한클릭 방지

			isFirstSearch: true, // 페이지 첫 로드 OK 여부
			isFirstWrite: false, // CMS 신청서 첫작성 여부

			OZParam: {},
			isOtisPage: true, // 담당자 페이지
			isCustomerPage: false, // 고객 페이지
			goSign: false, // 저장 후 바로 전자서명 여부
		};
	},
	created() {
		this.addEvent({name: 'CmsList_Search', func: this.onPreSearch});
	},
	mounted() {

		this.searchInfo.checkContDateStart = $_addMonth($_getCurrentDate(), -1);
		this.searchInfo.checkContDateEnd = $_getCurrentDate();
		
		//console.log("aaaa",this.userInfo.empNo);
		
		this.onInitRequestInfo();
		// 그리드
		const OPT = {
			Cols: [
				//{name: "reqstdocInfo",        label: "신청서",          width: 80,    align: "center"},
				{name: 'status', label: '구분', width: 70, align: 'center'},
				{name: 'tradeNo', label: '거래선번호', width: 110, align: 'center'}, // 거래선번호
				{name: 'tradeSfx', label: 'SUFFIX', width: 65, align: 'center'}, // 거래선 SUFFIX
				//{name: "test1",               label: "월납입금",          width: 80,    align: "right", formatter: 'currency',formatoptions:{thousandsSeparator:",", decimalPlaces: 0}},
				{name: 'oldDwdDate', label: '출금일(매월)', width: 100, align: 'center'},
				{name: 'oldBankNm', label: '출금은행', width: 140, align: 'center'},
				{name: 'ctrtNo', label: '계약번호', width: 120, align: 'center'},
				{name: 'tradeName', label: '거래선명', width: 180},
				{name: 'contEmpNm', label: '영업담당자', width: 110, align: 'center'},
				{
					name: '계약서',
					label: '발송방법',
					width: 80,
					align: 'center',
					formatter: function(cellvalue, options, rowObject) {
						if (rowObject.notiFlag == 'K') {
							cellvalue = true;
						}
						return cellvalue == true ? '카톡' : '메일';
					},
					cellattr: $_rowMearge,
				},
				{
					name: 'reqstDt',
					label: '발송일시',
					width: 160,
					align: 'center',
					sorttype: 'date',
					//formatter: 'date',
					//formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
					cellattr: $_rowMearge,
				},
				{
					name: 'signDt',
					label: '서명일시',
					width: 160,
					align: 'center',
					sorttype: 'date',
					//formatter: 'date',
					//formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
					cellattr: $_rowMearge,
				},
				{
					name: 'checkContDate',
					label: '계약일',
					width: 90,
					align: 'center',
					sorttype: 'date',
					formatter: 'date',
					formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
					cellattr: $_rowMearge,
				},
				{
					name: 'checkContStartDate',
					label: '계약시작일',
					width: 90,
					align: 'center',
					sorttype: 'date',
					formatter: 'date',
					formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
					cellattr: $_rowMearge,
				},
				{
					name: 'checkContFinDate',
					label: '계약종료일',
					width: 90,
					align: 'center',
					sorttype: 'date',
					formatter: 'date',
					formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
					cellattr: $_rowMearge,
				},
				{name: 'docCat', label: '표준/비표준', width: 90, align: 'center'},
				{name: 'progrsSttusCd', label: '상태코드', width: 0, hidden: true},
				{name: 'reqstdocNo', label: '신청서번호', width: 0, hidden: true}, // 신청서번호
				{name: 'reqstdocOdr', label: '신청서차수', width: 0, hidden: true}, // 신청서차수
				{name: 'contEmpId', label: '영업담당자ID', width: 0, hidden: true}, // 영업담당자ID
				//{name: "docNm",               label: "계약종류",        width: 0,     hidden:true },
				{name: 'ctrtOdr', label: '계약차수', width: 0, hidden: true},
				{name: 'ctrtDivCd', label: '계약종류2', width: 0, hidden: true}, // OC/TC
				{name: 'buldNm', label: '계약명', width: 0, hidden: true}, // 계약명
				{name: 'repntName', label: '거래선대표자명', width: 0, hidden: true}, // 거래선 대표자명
				{name: 'signType', label: 'signType', width: 0, hidden: true}, // 서명 ON/ OFF
				{name: 'docCat1', label: 'docCat1', width: 0, hidden: true},
				{name: 'oldDpstrNm', label: '예금주명', width: 0, hidden: true},
				{name: 'oldDpstrBizrno', label: '예금주사업자번호', width: 0, hidden: true},
				{name: 'oldBankCd', label: '은행코드', width: 0, hidden: true},
				{name: 'oldAcnutNo', label: '출금계좌번호', width: 0, hidden: true},
				{name: 'oldDwdDe', label: '출금일자', width: 0, hidden: true},
				{name: 'cmsStatusCd', label: 'CMS상태', width: 0, hidden: true},
				{name: 'cmsRegFailCd', label: 'CMS금결원실패코드', width: 0, hidden: true},
				{name: 'failStatus', label: 'CMS금결원실패메세지', width: 0, hidden: true},

				{name: 'signDt', label: '신청일자', width: 0, hidden: true},
				{name: 'signMthNm', label: '서명방법', width: 0, hidden: true},
				{name: 'accesIp', label: '서명IP', width: 0, hidden: true},
			],
		};
		OPT.Cols.push(
			// eslint-disable-next-line
			{
				name: '계약서',
				label: '계약서',
				width: 60,
				align: 'center',
				formatter: function(cellvalue, options, rowObject) {
					cellvalue = true;
					return cellvalue == true ? '<button >보기</button>' : '';
				},
				cellattr: $_rowMearge,
			}
		);
		OPT.Cols.unshift(
			// eslint-disable-next-line
			{
				name: '신청서',
				label: '신청서',
				width: 60,
				align: 'center',
				formatter: function(cellvalue, options, rowObject) {
					if (rowObject.status != '미작성') {
						cellvalue = true;
					}
					// if (rowObject.status == '신청중' || rowObject.status == '등록중'){
					//     cellvalue = true;
					// }
					return cellvalue == true ? '<button >보기</button>' : '';
				},
				cellattr: $_rowMearge,
			}
		);
		const me = this;
		var $grid = $('#' + me.sheetId);
		$grid.jqGrid({
			height: 160,
			width: 1520,
			//width:1400,
			colModel: OPT.Cols,
			iconSet: 'fontAwesome',
			idPrefix: 'g1_',
			selectionMode: 'singlerow',
			rownumbers: true,

			// 셀 클릭 이벤트
			onCellSelect: function(rowId, iCol) {
				var rowData = $('#' + me.sheetId).jqGrid('getRowData', rowId);			
				console.log("aa",rowData);	
				if(rowData.reqstdocNo !=rowId ){
					me.onInitRequestInfo(); //requestInfo init()

					// focuskey set
					me.focusKeys.reqstdocNo = rowData.reqstdocNo;
					me.focusKeys.reqstdocOdr = rowData.reqstdocOdr;
					me.focusKeys.ctrtNo = rowData.ctrtNo;
					me.focusKeys.ctrtOdr = rowData.ctrtOdr;
					me.focusKeys.ctrtDivCd = rowData.ctrtDivCd;
					me.focusKeys.contractType = rowData.contractType;
					me.focusKeys.signType = rowData.signType;
					me.focusKeys.tradeSfx = rowData.tradeSfx;
					me.focusKeys.tradeNo = rowData.tradeNo;
					me.focusKeys.rowKey = rowId;
					console.log('focus key ', me.focusKeys);

					// 상세조회 START
					me.onSetRequestInfo(rowData); // row에 저장된 기본정보 bind
					me.onReqDetailSearch(); // 상세조회
				}

				// 계약서 보기
				if (iCol == $('#' + me.sheetId).jqGrid('getGridParam').iColByName['계약서']) {
					if (rowData.signType == 'ON') {
						me.onCtrtView(rowData);
					} else {
						me.$alert('선택된 계약은 오프라인으로 진행한 계약이라 계약서를 조회할 수 없습니다.');
					}
				}
				// 신청서 보기
				if (iCol == $('#' + me.sheetId).jqGrid('getGridParam').iColByName['신청서']) {
					// CMS 에 대한 상태값
					if (rowData.status != '미작성') {
						me.onReqView();
					}
					// if (rowData.status == "신청중" || rowData.status == "등록중"){
					//   me.onReqView();
					// }
					// progrsSttusCd 에 대한 상태값
					// if (rowData.status == "신청완료" || rowData.status == "작성중"){
					//   me.onReqView();
					// }
				}
			},
		});

		// 계약기간 검색 이번달로 기본 설정
		//this.searchInfo.yyyyMm = $_getCurrentDate().substring(0, 6);

		// 검색조건 - 구분 IE 로딩위해 추가
		setTimeout(() => {
			$('#searchCheckStatus_select').prop('selectedIndex', 0);
		}, 100);

		window.addEventListener('resize', this.onResize);
		this.onResize();
		this.onDefaultValSet();
		this.onPreSearch();
		this.setBankNm();
		this.setDwdDe();

		// 20210407 검색창 보이기/감추기
		$initSrchShowButton({
			srchBox: this.$refs.srchBox,
			srchList: this.$refs.srchList,
		});
	},

	methods: {
		changeCheckbox() {
			this.searchInfo['searchCheckStatus'] = '';
			//$("#searchCheckStatus").val('');
			$("select[name=searchCheckStatus]").val('');
			if(this.checkBoxStatusArray.length > 0){
				this.searchInfo.checkBoxStatusArray = "'"+this.checkBoxStatusArray.join("','")+"'";
			}else{
				this.searchInfo.checkBoxStatusArray = '';
			}
			
			console.log("checkBoxStatusArray",this.checkBoxStatusArray);
		},
		// 창 리사이즈
		// eslint-disable-next-line
		onResize(event) {
			// console.log('window has been resized', event);
			var $grid = $('#' + this.sheetId);
			var pobj = $grid
				.closest('.ui-jqgrid')
				.parent()
				.parent();
			var newWidth = pobj.width();
			$grid.jqGrid('setGridWidth', newWidth - 5, false);
			//$grid.jqGrid("setGridWidth", newWidth-1, true); // true 줄 시에 크기값 비율고정됨

			var bottomLeftObj = $grid
				//.closest('.bullet')
				.parent()
				.parent()
				.parent()
				.parent()
				.parent()
				.parent();
			/*20210907 lgksja ~S*/
			//$grid.jqGrid("setGridHeight", bottomLeftObj.height()-100, false);
			console.log('bottomLeftObj.height()-90', bottomLeftObj.height() - 100);
			$grid.jqGrid('setGridHeight', bottomLeftObj.height() - 40, false);
		},
		// 페이지 값/변수 초기화
		onDefaultValSet() {
			// default value 설정
			this.requestInfo.recptMthPostYn = 'N';
			this.requestInfo.smsYn = 'Y';
			this.requestInfo.nmSeCd = '10';
			this.requestInfo.progrsSttusCd = '10';
			this.requestInfo.reqstdocDivCd = 'CMS';
			this.requestInfo.notiType = 'REQALL';

			$('#bankCd_select').prop('selectedIndex', 0);
			$('#dwdDe_select').prop('selectedIndex', 0);
		},
		// RequestInfo 초기화
		onInitRequestInfo() {
			for (var key in this.requestInfo) {
				if (this.requestInfo.hasOwnProperty(key)) {
					this.requestInfo[key] = '';
				}
			}
			this.requestInfo.reqstdocDivCd = 'CMS';
			this.requestInfo.notiType = 'REQALL';
		},
		// 페이지 최초 검색
		onPreSearch() {
			var urls = this.$router.app.urls.filter(data => data.menuId == this.$route.name)[0];
			if (urls !== undefined) {
				var params = urls.params;
				console.log('params', params);
				if (params !== undefined) {
					this.searchInfo.applyNewYn = '';
					this.searchInfo.summaryYm = '';

					this.searchInfo.applyNewYn = params.applyNewYn;
					this.searchInfo.summaryYm = params.summaryYm;
				}
			}

			this.focusKeys.reqstdocNo = '';
			this.focusKeys.reqstdocOdr = 0;
			this.onSearch();
		},
		//상단 검색조건 설정 (건물명/계약종류/구분/CC)
		onSetValue(e, t) {
			let target = typeof e == 'string' ? t : e.target.name;
			let value = typeof e == 'string' ? e : e.target.value;
			this.searchInfo[target] = value;
		},
		// 서치 selectBox 설정
		setSearchValue(target, event) {
			this.searchInfo[target] = event.target.value;
			this.searchInfo.checkBoxStatusArray = '';
			this.checkBoxStatusArray = [];
		},
		// requestInfo 설정 (selectbox)
		onSetRequestInfoValue(target, event) {
			this.requestInfo[target] = event.target.value;
		},
		// 검색조건 초기화
		searchClear() {
			for (var key in this.searchInfo) {
				if (this.searchInfo.hasOwnProperty(key)) {
					this.searchInfo[key] = '';
				}
			}
			for (var key2 in this.focusKeys) {
				if (this.focusKeys.hasOwnProperty(key2)) {
					this.focusKeys[key2] = '';
				}
			}
			this.searchInfo.checkContDateStart = $_addMonth($_getCurrentDate(), -1);
			this.searchInfo.checkContDateEnd = $_getCurrentDate();
			//this.searchInfo.checkContDateStart = $_addDay($_getCurrentDate(), -365);
			this.searchInfo.isOtisPage = true;
			this.searchInfo.isCustomerPage = false;
			$('#searchCheckStatus_select').prop('selectedIndex', 0);
		},
		// DATA -> RequestInfo 값 셋팅
		onSetRequestInfo(Data) {
			// 검색되는 key insert
			for (var key in Data) {
				if (this.requestInfo.hasOwnProperty(key)) {
					this.requestInfo[key] = Data[key];
				}
			}
			// 값 셋팅시 기존 CMS 고객 정보가 없으면
			// if (Data.oldDpstrNm){
			//   if (Data.oldDpstrNm.replace(/ /gi,'') == ""){
			//     this.isFirstWrite = true;
			//   } else {
			//     this.isFirstWrite = false;
			//   }
			// }

			// 신청서 작성 전 계좌 기본값 들어올 시에 공백 정리
			this.requestInfo.bankCd = this.requestInfo.bankCd.replace(/ /gi, '');
			this.requestInfo.dpstrBizrno = this.requestInfo.dpstrBizrno.replace(/ /gi, '').replace(/-/gi, '');
			this.requestInfo.acnutNo = this.requestInfo.acnutNo.replace(/ /gi, '');

			// 출금은행 select indexing
			for (var i in this.bankCdArray) {
				if (this.bankCdArray[i].value == this.requestInfo.bankCd) {
					setTimeout(() => {
						$('#bankCd_select').prop('selectedIndex', parseInt(i) + 1);
					}, 100);
					break;
				}
				if (i == this.bankCdArray.length - 1) {
					// 선택(default) 제외한 모든 항목 일치 X
					setTimeout(() => {
						$('#bankCd_select').prop('selectedIndex', 0);
					}, 100);
				}
			}
			// 출금일자 indexing
			for (var j in this.dwdDeArray) {
				if (this.dwdDeArray[j].value == this.requestInfo.dwdDe) {
					setTimeout(() => {
						$('#dwdDe_select').prop('selectedIndex', parseInt(j) + 1);
					}, 100);
					break;
				}
				if (j == this.dwdDeArray.length - 1) {
					// 선택(default) 제외한 모든 항목 일치 X
					setTimeout(() => {
						$('#dwdDe_select').prop('selectedIndex', 0);
					}, 100);
				}
			}
			// 신청인명 없을시 대표자명으로 설정
			if (this.requestInfo.applcntNm === '' || this.requestInfo.applcntNm == null) {
				// 사업자일때는 거래선 명 이용
				if (Data.oldDpstrBizrno.replace(/ /gi, '').replace(/-/gi, '').length > 6) {
					this.requestInfo.applcntNm = Data.tradeName;
					// 사업자가 아닐때는 대표자명
				} else {
					this.requestInfo.applcntNm = Data.repntName;
				}
			}
		},
		// 출금은행 SET
		async setBankNm() {
			let param = {
				CMN_U_CD: 'CMS_BANK_CODE_STD',
			};
			const response = await axios.post('/cmsReq/selectBankNmList', param);
			if (response.data.length > 0) {
				for (var i = 0; i < response.data.length; i++) {
					this.bankCdArray.push({value: response.data[i].code, text: response.data[i].name});
				}
			}
			setTimeout(() => {
				$('#bankCd_select').prop('selectedIndex', 0);
			}, 100);
		},
		// 출금일 SET
		setDwdDe() {
			this.dwdDeArray.push(
				{value: '05', text: '5일'},
				{value: '15', text: '15일'},
				{value: '20', text: '20일'},
				{value: '25', text: '25일'},
				{value: '27', text: '27일'},
				{value: '31', text: '31일'}
			);

			setTimeout(() => {
				$('#dwdDe_select').prop('selectedIndex', 0);
			}, 100);
		},
		// 검색
		async onSearch() {
			let response = '';
			let param = this.searchInfo;

			// if (this.searchInfo.yyyyMm == '') {
			// 	this.$alert('계약 기간을 선택해주세요.');
			// 	return;
			// }

			if (this.searchInfo.searchCtrtNo === undefined || this.searchInfo.searchCtrtNo == '') {
				// 계약일 기간 비워져 있을시에 alert
				if(this.userInfo.empNo !='211418' && this.userInfo.empNo!='Z05330' && this.userInfo.empNo!='211924'){
					if (!this.searchInfo.checkContDateStart || !this.searchInfo.checkContDateEnd) {
						this.$alert('검색하실 계약일의 시작일과 종료일을 선택해주세요.');
						return;
					}

					if (parseInt(this.searchInfo.checkContDateStart) > parseInt(this.searchInfo.checkContDateEnd)) {
						this.$alert('계약일의 시작일은 종료일 보다 뒤일 수 없습니다.<br>시작일을 다시 선택해주세요.');
						return;
					}

					var sDate = new Date(
						this.searchInfo.checkContDateStart.substring(0, 4),
						this.searchInfo.checkContDateStart.substring(4, 6),
						this.searchInfo.checkContDateStart.substring(6, 8)
					);

					var eDate = new Date(
						this.searchInfo.checkContDateEnd.substring(0, 4),
						this.searchInfo.checkContDateEnd.substring(4, 6),
						this.searchInfo.checkContDateEnd.substring(6, 8)
					);

					var dif = eDate - sDate;
					var cDay = 24 * 60 * 60 * 1000; // 시 * 분 * 초 * 밀리세컨
					var cMonth = cDay * 30; // 월 만듬
					// var cYear = cMonth * 12; // 년 만듬

					// console.log('sDate', sDate);
					// console.log('eDate', eDate);
					// console.log('dif', dif);
					// console.log('parseInt(dif/cMonth)', parseInt(dif/cMonth));
					/*
					if (this.customerPage) {
						if (parseInt(dif / cMonth) > 3) {
						this.$alert('계약일 기간을 3개월 이내로 검색해주세요.');
						return;
						}
					} else if (this.otisPage) {
						if (parseInt(dif / cMonth) > 1) {
						this.$alert('계약일 기간을 1개월 이내로 검색해주세요.');
						return;
						}
					}
					*/
					if (parseInt(dif / cMonth) > 12) {
						this.$alert('계약일 기간을 12개월 이내로 검색해주세요.');
						return;
					}
				}
			} else {
				this.searchInfo.checkContDateStart = '';
				this.searchInfo.checkContDateEnd = '';
			}

			this.listBoxLoading = true;
			try {
				response = await axios.post('/cmsReq/selectCMSReqServiceList', param);
				this.listBoxLoading = false;
				$('#' + this.sheetId)
					.clearGridData(true)
					.setGridParam({data: response.data})
					.trigger('reloadGrid');

				var i = 0;
				var rowData;
				var ids = $('#' + this.sheetId).jqGrid('getDataIDs'); // 전체 row id 배열
				if (ids && ids.length > 0) {
					// 포커싱 해주는 부분
					if (this.focusKeys.ctrtNo && this.focusKeys.ctrtOdr) {
						for (i = 0; i <= ids.length - 1; i++) {
							rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[i]);

							// 포커싱 정보와 일치하는 행이 있을때
							if (rowData['ctrtNo'] == this.focusKeys.ctrtNo && rowData['ctrtOdr'] == this.focusKeys.ctrtOdr) {
								$('#' + this.sheetId).jqGrid('setSelection', ids[i], true);

								this.focusKeys.reqstdocNo = rowData.reqstdocNo;
								this.focusKeys.reqstdocOdr = rowData.reqstdocOdr;
								this.focusKeys.ctrtNo = rowData.ctrtNo;
								this.focusKeys.ctrtOdr = rowData.ctrtOdr;
								this.focusKeys.ctrtDivCd = rowData.ctrtDivCd;
								this.focusKeys.contractType = rowData.contractType;
								this.focusKeys.signType = rowData.signType;
								this.focusKeys.tradeSfx = rowData.tradeSfx;
								this.focusKeys.tradeNo = rowData.tradeNo;
								this.focusKeys.rowKey = ids[i];

								// 상세조회 START
								this.onSetRequestInfo(rowData); // row에 저장된 기본정보 bind
								this.onReqDetailSearch();

								// 바로 전자서명 할 시에 oz viewer 띄워줌
								if (this.goSign) {
									this.onReqView();
								}

								// 해당 행으로 focus 이동
								let rowHeight = 30; // jqgrid 행 기본 높이
								let index = jQuery('#' + this.sheetId).getInd(ids[i]);
								// 검색 행이 중간으로 갈 수 있도록 설정
								jQuery('#' + this.sheetId)
									.closest('.ui-jqgrid-bdiv')
									.scrollTop(rowHeight * index - 90);
								break;
							}
							// 포커싱 만 존재하고 일치하는 행이 없을때 -> 첫번째 행 선택
							if (
								i == ids.length - 1 &&
								!(rowData['ctrtNo'] == this.focusKeys.ctrtNo && rowData['ctrtOdr'] == this.focusKeys.ctrtOdr)
							) {
								$('#' + this.sheetId).jqGrid('setSelection', ids[0], true);
								rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[0]);

								// 첫번쨰 행 focuskey set
								this.focusKeys.reqstdocNo = rowData.reqstdocNo;
								this.focusKeys.reqstdocOdr = rowData.reqstdocOdr;
								this.focusKeys.ctrtNo = rowData.ctrtNo;
								this.focusKeys.ctrtOdr = rowData.ctrtOdr;
								this.focusKeys.ctrtDivCd = rowData.ctrtDivCd;
								this.focusKeys.contractType = rowData.contractType;
								this.focusKeys.signType = rowData.signType;
								this.focusKeys.tradeSfx = rowData.tradeSfx;
								this.focusKeys.tradeNo = rowData.tradeNo;
								this.focusKeys.rowKey = ids[0];


								this.onSetRequestInfo(rowData); // row에 저장된 기본정보 bind
								this.onReqDetailSearch();
							}
						}
						// 포커싱 정보가 없을때 - 첫번째 행 선택
					} else {
						$('#' + this.sheetId).jqGrid('setSelection', ids[0], true);
						rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[0]);

						// 첫번쨰 행 focuskey set
						this.focusKeys.reqstdocNo = rowData.reqstdocNo;
						this.focusKeys.reqstdocOdr = rowData.reqstdocOdr;
						this.focusKeys.ctrtNo = rowData.ctrtNo;
						this.focusKeys.ctrtOdr = rowData.ctrtOdr;
						this.focusKeys.ctrtDivCd = rowData.ctrtDivCd;
						this.focusKeys.contractType = rowData.contractType;
						this.focusKeys.signType = rowData.signType;
						this.focusKeys.tradeSfx = rowData.tradeSfx;
						this.focusKeys.tradeNo = rowData.tradeNo;
						this.focusKeys.rowKey = ids[0];


						this.onSetRequestInfo(rowData); // row에 저장된 기본정보 bind
						this.onReqDetailSearch();
					}
				} else {
					// 검색된 데이터 존재 X
					this.onInitRequestInfo();
					this.onDefaultValSet();
				}
			} catch (ex) {
				console.log(ex);
				this.listBoxLoading = false;
			}
		},
		// CMS Service 신청서 상세 검색
		async onReqDetailSearch() {
			let param = {
				tradeNo: this.requestInfo.tradeNo,
				tradeSfx: this.requestInfo.tradeSfx,
			};

			// CMS Service 상세 조회
			try {
				const response = await axios.post('/cmsReq/selectCMSReqInfo', param);
				if (response.data == '') {
					this.onDefaultValSet(); //검색 결과 없을시에 default Set
				} else {
					this.onSetRequestInfo(response.data); // 검색값 setting
				}
			} catch (ex) {
				console.log(ex);
				this.listBoxLoading = false;
			}
		},
		// 저장 및 전자서명
		async onSave() {
			// 조회 계약 없을때 저장 하려고 할 시 alert
			var ids = $('#' + this.sheetId).jqGrid('getDataIDs'); // 전체 row id 배열
			if (ids.length < 1) {
				this.$alert('신청서를 저장하기 위한 정보가 존재하지 않습니다.<br>목록을 다시 조회해주세요.');
				return;
			}

			// 신규작성(미작성) 또는 해지상태일때만 저장 및 전자서명 가능하도록
			if (!(this.requestInfo.cmsStatusCd == '09' || this.requestInfo.cmsStatusCd == '')) {
				this.$alert('신청서가 미작성이거나 해지상태일 경우에만 신청을 하실 수 있습니다.');
				return;
			}

			// 등록중일때 저장 및 전자서명 못하도록
			// if (this.requestInfo.cmsStatusCd == "02"){
			//   this.$alert("신청서가 등록중 상태일 때는 해당 신청서를 수정 또는 변경하실 수 없습니다.");
			//   return ;
			// }

			// 변경시에 알림
			// if (this.requestInfo.reqstdocOdr >= 0 && this.requestInfo.progrsSttusCd == '20' && !this.requestInfo.cmsRegFailCd){
			//   if (!(await this.$confirm('변경시 작성된 정보로 새로운 신청서가 작성됩니다.<br>변경신청 하시겠습니까?'))) return;
			// }

			// 재작성 ( 실패사유 없을시 )
			if (
				this.requestInfo.reqstdocOdr >= 0 &&
				this.requestInfo.progrsSttusCd == '20' &&
				!this.requestInfo.cmsRegFailCd
			) {
				if (
					!(await this.$confirm('재작성시 작성된 정보로 새로운 신청서가 작성됩니다.<br>신청서를 재작성 하시겠습니까?'))
				)
					return;
			}

			// 재작성 ( 실패사유 있을시 )
			if (
				this.requestInfo.reqstdocOdr >= 0 &&
				this.requestInfo.progrsSttusCd == '20' &&
				this.requestInfo.cmsRegFailCd
			) {
				if (
					!(await this.$confirm(
						'작성하신 신청서가 [ ' +
							this.requestInfo.failStatus +
							' ] 사유로 신청을 실패 또는 해지되었습니다.<br>신청서를 재작성 하시겠습니까?'
					))
				)
					return;
			}

			/*  ===============================
			 *  onSave() validation CHECK START
			 *  =============================== */

			/* ===== 1. 예금주명 CHECK ===== */
			if (this.requestInfo.dpstrNm == '' || this.requestInfo.dpstrNm == null) {
				this.$alert('출금계좌의 예금주 명을 입력해주세요.');
				return;
			}

			/* ===== 2. 예금주생년월일(사업자등록번호) CHECK ==== */
			if (this.requestInfo.dpstrBizrno == '' || this.requestInfo.dpstrBizrno == null) {
				this.$alert('개인 또는 법인사업자 경우에는 사업자등록번호를, 개인일 경우에는 생년월일을 입력해주세요.');
				return;
			}
			var BizrnoPattern = /^[0-9]*$/;
			//this.requestInfo.dpstrBizrno = this.requestInfo.dpstrBizrno.replace(/ /gi,'').replace(/-/gi,''); // 공백, '-' 제거
			if (!BizrnoPattern.test(this.requestInfo.dpstrBizrno)) {
				this.$alert('생년월일(6자리) 또는 사업자번호(10자리)는 숫자만 입력 가능합니다.');
				return;
			}
			if (!(this.requestInfo.dpstrBizrno.length == 6 || this.requestInfo.dpstrBizrno.length == 10)) {
				this.$alert('생년월일(6자리) 또는 사업자번호(10자리)를 다시 확인해주세요.');
				return;
			}

			/* ===== 3. 출금은행 CHECK ==== */
			if (this.requestInfo.bankCd == '' || this.requestInfo.bankCd == null) {
				this.$alert('출금 은행을 선택해주세요.');
				return;
			}
			// 서명 후 알림을 위해 선택한 은행이름 저장
			this.requestInfo.bankNm = $('#bankCd_select option:checked')
				.text()
				.replace(/ /gi, '');

			/* ===== 4. 출금계좌번호 CHECK ===== */
			var AcnutNoPattern = /^[0-9]*$/;
			//var AcnutPattern1 = /^[A-Za-z]*$/;
			//var AcnutPattern2 = /[{}[\]/?.,;:|)*~`!^\-_+┼<>@#$%&'"\\(=]/gi;
			if (this.requestInfo.acnutNo == '' || this.requestInfo.acnutNo == null) {
				this.$alert('출금계좌번호를 입력해주세요.');
				return;
			}
			if (!AcnutNoPattern.test(this.requestInfo.acnutNo)) {
				this.$alert('출금계좌번호는 숫자만 입력 가능합니다. ( - 없이 입력해 주세요 )');
				return;
			}

			/* ===== 5. 예금주유선전화 CHECK ===== */
			if (this.requestInfo.dpstrTelno == '' || this.requestInfo.dpstrTelno == null) {
				this.$alert('예금주 유선전화번호를 입력해주세요.');
				return;
			}

			/* ===== 6. 예금주휴대전화 CHECK ===== */
			let telNoPattern = /^(01+[(0-9)]*$)/; // 휴대폰번호 정규식 (01x 번호만 검색)
			//this.requestInfo.dpstrMoblphonno = this.requestInfo.dpstrMoblphonno.replace(/ /gi,'').replace(/-/gi,'').replace(/\(/gi,'').replace(/\)/gi,'');
			let chkTelNo = this.requestInfo.dpstrMoblphonno;
			if (!telNoPattern.test(chkTelNo)) {
				this.$alert('휴대폰 번호는 숫자만 입력 가능합니다. ( - 없이 입력해 주세요 )');
				return;
			}

			if (!(chkTelNo.length == 10 || chkTelNo.length == 11)) {
				this.$alert('SMS를 수신 가능한 휴대폰 번호를 입력해주세요. (10자 ~ 11자)');
				return;
			}

			/* ===== 7. 출금일자 CHECK ===== */
			if (this.requestInfo.dwdDe == '' || this.requestInfo.dwdDe == null) {
				this.$alert('출금일자를 선택해주세요.');
				return;
			}

			/*  ===============================
			 *  onSave() validation CHECK END
			 *  =============================== */

			if (this.requestInfo.bcncnm == '') {
				// 수납기관명
				this.requestInfo.bcncnm = '오티스서비스엑스퍼트 유한회사';
			}
			if (this.requestInfo.bizrno == '') {
				// 사업자번호
				this.requestInfo.bizrno = '8358701968';
			}
			if (this.requestInfo.adresCn == '') {
				// 주소
				this.requestInfo.adresCn = '서울 강동구 성내로6길 11, 2층(성내동, 삼원타워)';
			}
			if (this.requestInfo.prposCn == '') {
				// 용도/내용
				this.requestInfo.prposCn = '유지관리료';
			}
			if (this.requestInfo.rprsntv == '') {
				// 대표자
				this.requestInfo.rprsntv = '대표이사 장두호';
			}

			/* DB insert / update 시작 */
			let response = '';
			let param = this.requestInfo;

			if (this.isProcessing) {
				alert('신청서 정보를 저장 또는 서명처리 중입니다. 잠시만 기다려 주세요.');
				return;
			}

			try {
				this.listBoxLoading = true;
				this.isProcessing = true;

				// 신규(변경)신청
				// 1. 계약서 차수 X , 작성중(10) --> 신규 신청
				// 2. 계약서 차수 O , 작성중(10) --> 수정 신청
				// 3. 계약서 차수 O , 신청완료(20) --> 변경 신청

				// 신규 신청
				if (this.requestInfo.reqstdocOdr === '' && this.requestInfo.progrsSttusCd == '10') {
					response = await axios.post('/cmsReq/insertCMSReq', param); // insert (변경 / 신규 신청)
					// 변경 신청
				} else if (this.requestInfo.reqstdocOdr >= 0 && this.requestInfo.progrsSttusCd == '20') {
					response = await axios.post('/cmsReq/insertCMSReq', param); // insert (변경 / 신규 신청)
					// CMS 재작성 (추후 필요시)
					// } else if (this.requestInfo.progrsSttusCd == '20' && this.requestInfo.cmsRegFailCd){
					//   // 현재 차수를 - 로 만들고 현재 신청서의 차수로 cms 신청서를 다시 만든다.
					//   response = await axios.post('/cmsReq/renewalCMSReq',param); // 새로 insert
					// 수정 신청
				} else {
					if (this.requestInfo.reqstdocOdr >= 0 && this.requestInfo.progrsSttusCd == '10') {
						response = await axios.post('/cmsReq/updateCMSReq', param); // update (작성중)
					} else {
						console.log('on save() not in case');
					}
				}

				if (response.data == 'success') {
					this.listBoxLoading = false;
					this.isProcessing = false;
					if (
						!(await this.$confirm(
							'CMS 작성 정보가 성공적으로 저장되었습니다. <br>전자서명을 완료해야 CMS 신청이 완료됩니다.<br><br>전자서명을 바로 진행하시겠습니까?<br>( 취소하여도 작성 정보는 저장되어 있습니다. )'
						))
					) {
						// 취소 클릭시
						this.onSearch();
					} else {
						// 확인 클릭시 상세조회 완료하면서 oz viewer 시작
						this.goSign = true;
						this.onSearch();
					}
				}
				console.log(response);
			} catch (ex) {
				this.listBoxLoading = false;
				this.isProcessing = false;
				this.$alert('저장에 실패하였습니다.');
				console.log(ex);
			}
		},
		// CMS 신청 정보 조회
		async onReqView() {
			this.goSign = false;
			// eslint-disable-next-line
			var opt = {};
			const btns = [];
			//let response2 = "";

			// 신청서 조회자 서명 정보 조회
			// let signInfoParam = {
			//   ctrtNo: this.focusKeys.ctrtNo,
			//   ctrtOdr: this.focusKeys.ctrtOdr,
			//   ctrtDivCd: this.focusKeys.ctrtDivCd,
			// };
			// T계약일때 , O계약일때 구분
			// if (signInfoParam.ctrtDivCd == "TC"){
			//   response2 = await axios.post('/tcontract/selectSignerInfo', signInfoParam);
			// } else {
			//   response2 = await axios.post('/ocontract/selectSignerInfo', signInfoParam);
			// }

			// 최초 작성 후 바로 전자서명 하려고 할 시에 번호/차수 넣어줌
			this.focusKeys.reqstdocNo = this.focusKeys.reqstdocNo === '' ? this.focusKeys.ctrtNo : this.focusKeys.reqstdocNo;
			this.focusKeys.reqstdocOdr = this.focusKeys.reqstdocOdr === '' ? 0 : this.focusKeys.reqstdocOdr;

			let param = {
				manageCd: 'R03', // 필수(*):공통 (R01: View , R02: Elite , R03: CMS)
				reqstdocNo: this.focusKeys.reqstdocNo, // 필수(*) : cms, view, elite 신청서일 경우
				reqstdocOdr: this.focusKeys.reqstdocOdr, // 필수(*) : cms, view, elite 신청서일 경우
			};

			// 작성중 상태 + 첫번째 고객일때만 전자서명 버튼 나올 수 있도록 설정
			// if (this.requestInfo.progrsSttusCd == '10' && (eval(response2.data.customer) == true && response2.data.tradeOrder == "A" )){
			//   btns.push(
			//     {
			//       name: '전자서명',
			//       callback: () =>{
			//         popupInterface[opt.name].vue.callEvent({
			//           name: 'RequestViewerPopup_Sign',
			//           param: data => {
			//             console.log('RequestViewerPopup_Sign Data :',data);
			//             opt.window.close();
			//             this.onSignReq(data);
			//           },
			//         });
			//       },
			//     },
			//   );
			// }

			axios
				.post('/ozrpt/selectOzEtcFormService', param)
				.then(response => {
					console.log('조회결과 response >>>', response);
					console.log('조회결과 response.data >>>', response.data);

					var signable = 'N'; // 필수(*):디지털 서명가능여부(Y/N)
					var signerSeCd = '10'; // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
					var tradeNo = this.requestInfo.tradeNo; // [사인할경우만 필수(signable='Y')]거래선코드(로그인한사용자)

					this.OZParam = Object.assign({}, response.data.jsonData);
					this.OZParam.reportname = response.data.reportPathName;
					this.OZParam.OZFormParam = {
						signable: signable, // 필수(*):디지털 서명가능여부(Y/N)
						signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
						tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선코드(로그인한사용자)
						signMthCd: '', // 서명방법 (21.03.17 추가)
					};

					// eslint-disable-next-line
					opt = $open({
						componentPath: '@/view/applicationForm/components/popup/RequestViewer.vue', // 컴포넌트 경로 (필수)
						param: {
							ctrtNo: this.focusKeys.ctrtNo,
							ctrtOdr: '00', // 계약은 0차수 (당초 만 조회)
							ctrtDivCd: this.focusKeys.ctrtDivCd,
							reqstdocNo: this.focusKeys.reqstdocNo,
							reqstdocOdr: this.focusKeys.reqstdocOdr,
							progrsSttusCd: this.requestInfo.progrsSttusCd, // 진행상태 (10 : 작성중 , 20 : 신청완료(서명완료))
							checkContDate: this.requestInfo.checkContDate, // 계약일
							reqstdocDivCd: this.requestInfo.reqstdocDivCd, // 신청서 구분 코드
							otisPage: this.isOtisPage, // 고객/ OSE 구분
							OZParam: this.OZParam,
						}, // 파라미터 (생략가능)
						btns: btns, // 팝업 버튼 (생략가능)
						name: 'RequestViewer', // 팝업 윈도우 이름 (생략가능)
						option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
						title: '신청서조회', // 팝업 제목 (생략가능)
					});
				})
				.catch(function(error) {
					console.log('========error====================');
					console.log(error);
				});
		},
		// 신청서 전자서명 후 알림발송 및 검색
		async onSignReq(data) {
			console.log(' CMS onSignReq data :', data);

			let param = this.requestInfo;
			const response = await axios.post('/cmsReq/notiCMSReq', param);
			if (response.data == 'success') {
				console.log('noti Complete');
			} else {
				console.log('noti Fail');
			}

			this.$alert('전자서명 및 신청이 완료되었습니다.');
			this.onSearch();

			// 추후 해당 신청서 전자서명정보 삭제 필요시 사용
			// this.requestInfo.infoUseAgreYn = (data.ozVal.infoUseAgreYn == '' || data.ozVal.infoUseAgreYn == null) ? 'Y': data.ozVal.infoUseAgreYn;
			// this.requestInfo.infoProvdAgreYn = (data.ozVal.infoProvdAgreYn == '' || data.ozVal.infoProvdAgreYn == null) ? 'Y' : data.ozVal.infoProvdAgreYn;
			// this.requestInfo.reqstdocNo  = this.focusKeys.reqstdocNo;
			// this.requestInfo.reqstdocOdr = this.focusKeys.reqstdocOdr;
			// let param = this.requestInfo;
			// const response = await axios.post('/cmsReq/deleteReqSignInfoByFail', param);
			// if (response.data == "success"){
			//   console.log("del Complete");
			// } else {
			//   console.log("del Fail");
			// }
		},
		// 계약서 조회
		async onCtrtView(data) {
			let param2 = {
				ctrtNo: data.ctrtNo,
				ctrtOdr: data.ctrtOdr,
				ctrtDivCd: data.ctrtDivCd,
			};
			let response2 = await axios.post('/ocontract/selectSignerInfo', param2);

			console.log(response2);

			var signable = 'N';
			var signerSeCd =
				eval(response2.data.buyagnt) == true ? '20' : eval(response2.data.customer) == true ? '10' : '30';
			var tradeNo =
				eval(response2.data.buyagnt) == false && eval(response2.data.canSign) == true ? response2.data.tradeNo : '';

			console.log('signable', signable);
			console.log('signerSeCd', signerSeCd);
			console.log('tradeNo', tradeNo);

			//계약서 조회 후 오즈뷰어 팝업시킴.
			let param = {
				checkContNo: data.ctrtNo,
				checkSeq: data.ctrtOdr,
				manageCd: data.docCat1,
			};

			//var opt = {};
			const btns = [];
			if (data.docCat1 == '000') {
				// eslint-disable-next-line
				opt = $open({
					componentPath: '@/view/contract/oContract/components/popup/ContractViewer.vue', // 컴포넌트 경로 (필수)
					param: {
						ctrtNo: this.focusKeys.ctrtNo,
						ctrtOdr: this.focusKeys.ctrtOdr,
						ctrtDivCd: this.focusKeys.ctrtDivCd,
						docCat1: data.docCat1,
						OZParam: {},
						otisPage: this.isOtisPage,
					}, // 파라미터 (생략가능)
					btns: btns, // 팝업 버튼 (생략가능)
					name: 'OContractViewer', // 팝업 윈도우 이름 (생략가능)
					option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
					title: '계약서조회', // 팝업 제목 (생략가능)
				});
			} else {
				axios
					.post('/ozrpt/selectOzOContract', param)
					.then(response => {
						// console.log('조회결과 response >>>', response);
						// console.log('조회결과 response.data >>>', response.data);

						this.OZParam = Object.assign({}, response.data.jsonData);

						this.OZParam.reportname = response.data.reportPathName;
						this.OZParam.OZFormParam = {
							signable: signable, // 필수(*):디지털 서명가능여부(Y/N)
							signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
							tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선코드(로그인한사용자)
							signMthCd: '', // 서명방법 (21.03.17 추가)
						};

						// eslint-disable-next-line
						opt = $open({
							componentPath: '@/view/contract/oContract/components/popup/ContractViewer.vue', // 컴포넌트 경로 (필수)
							param: {
								ctrtNo: this.focusKeys.ctrtNo,
								ctrtOdr: this.focusKeys.ctrtOdr,
								ctrtDivCd: this.focusKeys.ctrtDivCd,
								docCat1: data.docCat1,
								OZParam: this.OZParam,
								otisPage: this.isOtisPage,
							}, // 파라미터 (생략가능)
							btns: btns, // 팝업 버튼 (생략가능)
							name: 'OContractViewer', // 팝업 윈도우 이름 (생략가능)
							option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
							title: '계약서조회', // 팝업 제목 (생략가능)
						});
					})
					.catch(function(error) {
						console.log('========error====================');
						console.log(error);
					});
			}
		},
	},
};
</script>

<style></style>
